define("ui-library/components/p-one-way-amount-or-percentage/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "p5gFoiKi",
    "block": "{\"symbols\":[],\"statements\":[[4,\"p-input-decoration\",null,[[\"prepend\",\"append\",\"icon\",\"onClickIcon\"],[[24,[\"prepend\"]],[24,[\"append\"]],[28,\"if\",[[24,[\"isOpen\"]],\"fa-caret-up\",\"fa-caret-down\"],null],[28,\"action\",[[23,0,[]],\"toggleDropdown\"],null]]],{\"statements\":[[0,\"  \"],[1,[28,\"p-one-way-numeric\",null,[[\"number\",\"numberupdate\",\"dataType\",\"onfocus\",\"onblur\",\"data-test-number\"],[[24,[\"number\"]],[28,\"action\",[[23,0,[]],\"changeNumber\"],null],[24,[\"dataType\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"keyboardActivated\"]]],null],true],null],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"keyboardActivated\"]]],null],false],null],true]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"isOpen\"]]],null,{\"statements\":[[4,\"click-outside\",null,[[\"action\",\"data-test-menu\"],[[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"isOpen\"]]],null],false],null],true]],{\"statements\":[[0,\"    \"],[7,\"ul\",true],[8],[0,\"\\n      \"],[7,\"li\",true],[8],[0,\"\\n        \"],[7,\"a\",false],[12,\"href\",\"#\"],[3,\"action\",[[23,0,[]],\"changeType\",\"percentage\"]],[8],[0,\"%\"],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"li\",true],[8],[0,\"\\n\"],[0,\"        \"],[7,\"a\",false],[12,\"href\",\"#\"],[3,\"action\",[[23,0,[]],\"changeType\",\"amount\"]],[8],[1,[28,\"currency-symbol\",[[24,[\"currency\"]]],null],true],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-library/components/p-one-way-amount-or-percentage/template.hbs"
    }
  });

  _exports.default = _default;
});