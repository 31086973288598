define("ui-library/components/p-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "K7mM0i5c",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"header\",\"body\",\"footer\"],[[28,\"component\",[\"p-list-header\"],[[\"columnWidths\",\"displaySelectAllToggle\",\"selectedAll\",\"selectedAny\",\"onToggleSelectAll\",\"data-test-list-header\"],[[24,[\"safeColumnWidths\"]],[24,[\"allowBatchSelection\"]],[24,[\"selectedAll\"]],[24,[\"selectedAny\"]],[28,\"action\",[[23,0,[]],\"toggleSelectAll\"],null],true]]],[28,\"component\",[\"p-list-body\"],[[\"rows\",\"columnWidths\",\"selectedAny\",\"hasActiveFilters\",\"emptyStateComponentName\",\"onResetFilters\",\"data-test-list-body\"],[[24,[\"_rows\"]],[24,[\"safeColumnWidths\"]],[24,[\"selectedAny\"]],[24,[\"hasActiveFilters\"]],[24,[\"emptyStateComponentName\"]],[28,\"action\",[[23,0,[]],\"bubbleClearFilters\"],null],true]]],[28,\"component\",[\"p-list-footer\"],[[\"data-test-list-footer\"],[true]]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-library/components/p-list/template.hbs"
    }
  });

  _exports.default = _default;
});