define('ui-library/components/p-widget-header/component', ['exports', 'ui-library/components/p-widget-header/template', 'ember-decorators/object'], function (exports, _template, _object) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _desc, _value, _obj;

  var SIZES = ['regular', 'small'];

  exports.default = Ember.Component.extend((_dec = (0, _object.computed)('size'), _dec2 = (0, _object.computed)('icon'), (_obj = {
    layout: _template.default,
    size: 'regular',
    icon: '',
    classNameBindings: ['sizeClass'],

    sizeClass: function sizeClass(size) {
      (false && !(SIZES.includes(size)) && Ember.assert('invalid value for size', SIZES.includes(size)));

      return 'size-' + size;
    },
    iconClass: function iconClass(icon) {
      return 'fa ' + icon;
    }
  }, (_applyDecoratedDescriptor(_obj, 'sizeClass', [_dec], Object.getOwnPropertyDescriptor(_obj, 'sizeClass'), _obj), _applyDecoratedDescriptor(_obj, 'iconClass', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'iconClass'), _obj)), _obj)));
});