define("ember-crumbly/templates/components/bread-crumb", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GL1pSaxv",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"route\",\"linkable\"]]],null,{\"statements\":[[4,\"link-to\",null,[[\"class\",\"route\"],[[24,[\"linkClass\"]],[24,[\"route\",\"path\"]]]],{\"statements\":[[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"      \"],[14,1,[[23,0,[]],[24,[\"route\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[24,[\"route\",\"title\"]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"    \"],[14,1,[[23,0,[]],[24,[\"route\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[24,[\"route\",\"title\"]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-crumbly/templates/components/bread-crumb.hbs"
    }
  });

  _exports.default = _default;
});