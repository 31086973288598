define('ui-library/components/p-modal/component', ['exports', 'ui-library/components/p-modal/template', 'ember-decorators/object/evented', 'ember-keyboard', 'ember-prop-types'], function (exports, _template, _evented, _emberKeyboard, _emberPropTypes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _desc, _value, _obj;

  exports.default = Ember.Component.extend(_emberKeyboard.EKMixin, (_dec = (0, _evented.on)((0, _emberKeyboard.keyDown)('Escape')), (_obj = {
    layout: _template.default,
    'data-test-modal': true,

    propTypes: {
      // TODO: UPGRADING NOTE
      // This two action PropType usages below, are may cause problems in the future.
      // Right now they can't be called with "<prop_name> = (action <action_name>)"
      // syntax because of ember-cli version.
      // Right now we're using this actions like "<prop_name> = '<action_name>'".
      // So we must pass this action prop types as any.
      //
      // But after ugrading ember-cli as >=2.18,
      // this must be fixed with "PropTypes.func" & "<prop_name> = (action <action_name)" usages.
      //
      // And must be fixed all the places where they're called like
      // "<prop_name> = '<action_name>'" as "<prop_name> = (action <action_name>)".
      onClose: _emberPropTypes.PropTypes.any,
      onCancel: _emberPropTypes.PropTypes.any,

      isOpen: _emberPropTypes.PropTypes.bool,
      autoCancel: _emberPropTypes.PropTypes.bool,
      dataTestModalName: _emberPropTypes.PropTypes.string,
      containerClassNames: _emberPropTypes.PropTypes.string,
      overlayClassNames: _emberPropTypes.PropTypes.string,
      wrapperClassNames: _emberPropTypes.PropTypes.string
    },
    getDefaultProps: function getDefaultProps() {
      return {
        onClose: null,
        onCancel: null,
        isOpen: false,
        autoCancel: false,
        dataTestModalName: null,
        containerClassNames: null,
        overlayClassNames: null,
        wrapperClassNames: null
      };
    },


    //
    // State
    //

    keyboardActivated: Ember.computed.alias('isOpen'),
    attributeBindings: ['data-test-modal'],

    cancel: function cancel() {
      if (this.get('onCancel') !== null) {
        this.sendAction('onCancel');
      }

      if (this.get('autoCancel')) {
        this.set('isOpen', false);
      }
    },


    //
    // Actions
    //

    actions: {
      clickOverlay: function clickOverlay() {
        this.cancel();
      },
      clickClose: function clickClose() {
        this.cancel();
      }
    }
  }, (_applyDecoratedDescriptor(_obj, 'cancel', [_dec], Object.getOwnPropertyDescriptor(_obj, 'cancel'), _obj)), _obj)));
});