define("ui-library/components/p-float-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sSXvuClE",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"displayValue\"]]],null,{\"statements\":[[1,[24,[\"formattedParts\",\"left\"]],false],[7,\"span\",true],[10,\"data-test-decimal\",\"\"],[8],[1,[24,[\"formattedParts\",\"decimalSeperator\"]],false],[1,[24,[\"formattedParts\",\"right\"]],false],[9]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-library/components/p-float-display/template.hbs"
    }
  });

  _exports.default = _default;
});