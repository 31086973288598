define('ui-library/components/tag-input/component', ['exports', 'ui-library/components/tag-input/template', 'ember-tag-input/components/tag-input'], function (exports, _template, _tagInput) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _tagInput.default.extend({
    layout: _template.default,
    disableNewTagInput: false
  });
});