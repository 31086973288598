define("ember-data-change-tracker/transforms/json", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Transform = _emberData.default.Transform;
  /**
   * This transform does not serializes to string,
   * with JSON.stringify, but leaves the object as is.
   *
   * The data often does not need to be stringified
   * so it's a valid case
   */

  var _default = Transform.extend({
    serialize: function serialize(value) {
      return value;
    },
    deserialize: function deserialize(json) {
      if (typeof json === "string") {
        json = JSON.parse(json);
      }

      return json;
    }
  });

  _exports.default = _default;
});