define('ui-library/components/p-card-footer/component', ['exports', 'ui-library/components/p-card-footer/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    classNameBindings: ['sticky:sticky'],
    attributeBindings: ['data-test-card-footer'],
    'data-test-card-footer': true
  });
});