define("ember-cli-google-tag-manager/services/gtm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // https://gist.github.com/pwfisher/5fd09ec2ccab253008f9
  // Helpers for Google Tag Manager
  var _default = Ember.Service.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.defaultEvent = this.defaultEvent || {
        event: 'trackEvent',
        eventCategory: '',
        eventAction: '',
        eventLabel: '',
        eventValue: ''
      };
      this.defaultPageView = this.defaultPageView || {
        event: 'vpv',
        virtualPagePath: ''
      };
      this.commonFields = this.commonFields || {};
    },
    addCommonField: function addCommonField(key, value) {
      this.commonFields[key] = value;
    },
    removeCommonField: function removeCommonField(key) {
      delete this.commonFields[key];
    },
    createPayload: function createPayload(type, o) {
      var _this = this;

      var data = o || {};
      var defaultPayload = this['default' + type];
      var payload = {};
      Object.keys(defaultPayload).forEach(function (key) {
        payload[key] = data.hasOwnProperty(key) ? data[key] : defaultPayload[key];
      });
      Object.keys(this.commonFields).forEach(function (key) {
        payload[key] = _this.commonFields[key];
      });
      return payload;
    },
    trackEvent: function trackEvent(o) {
      window.dataLayer.push(this.createPayload('Event', o));
    },
    trackPageView: function trackPageView(path) {
      window.dataLayer.push(this.createPayload('PageView', {
        virtualPagePath: path
      }));
    }
  });

  _exports.default = _default;
});