define('ui-library/components/p-multiple-select-with-checkboxes-item/component', ['exports', 'ui-library/components/p-multiple-select-with-checkboxes-item/template', 'ember-decorators/object', 'ember-prop-types'], function (exports, _template, _object, _emberPropTypes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _desc, _value, _obj;

  exports.default = Ember.Component.extend((_dec = (0, _object.computed)('selectedValues.[]', 'value'), (_obj = {
    'data-test-multiple-select-with-checkboxes-item': true,

    propTypes: {
      value: _emberPropTypes.PropTypes.oneOfType([_emberPropTypes.PropTypes.string, _emberPropTypes.PropTypes.integer]).isRequired,
      selectedValues: _emberPropTypes.PropTypes.array,
      onToggleSelection: _emberPropTypes.PropTypes.func
    },
    getDefaultProps: function getDefaultProps() {
      return {
        selectedValues: Ember.A(),
        onToggleSelection: null
      };
    },


    layout: _template.default,
    tagName: 'li',

    get isSelected() {
      var selectedValues = this.get('selectedValues');
      return selectedValues ? selectedValues.includes(this.get('value')) : false;
    },

    toggleSelection: function toggleSelection() {
      if (this.get('onToggleSelection')) {
        this.get('onToggleSelection')(this.get('value'));
      }
    }
  }, (_applyDecoratedDescriptor(_obj, 'isSelected', [_dec], Object.getOwnPropertyDescriptor(_obj, 'isSelected'), _obj), _applyDecoratedDescriptor(_obj, 'toggleSelection', [_object.action], Object.getOwnPropertyDescriptor(_obj, 'toggleSelection'), _obj)), _obj)));
});