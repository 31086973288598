define('ui-library/components/p-month-year-selector/component', ['exports', 'ui-library/components/p-month-year-selector/template', 'ember-decorators/object', 'ember-decorators/object/evented', 'ember-prop-types', 'moment'], function (exports, _template, _object, _evented, _emberPropTypes, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _dec3, _dec4, _desc, _value, _obj;

  exports.default = Ember.Component.extend(_emberPropTypes.default, (_dec = (0, _object.computed)(), _dec2 = (0, _evented.on)('init'), _dec3 = (0, _object.computed)('value', 'monthOptions'), _dec4 = (0, _object.computed)('value', 'yearOptions'), (_obj = {
    layout: _template.default,
    i18n: Ember.inject.service(),

    propTypes: {
      value: _emberPropTypes.PropTypes.any,
      onChange: _emberPropTypes.PropTypes.any
    },
    getDefaultProps: function getDefaultProps() {
      return {
        value: null,
        onChange: null
      };
    },


    'data-test-month-year-selector': true,

    monthOptions: function monthOptions() {
      var months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(function (index) {
        return { label: _moment.default.months(index - 1), value: index };
      });

      return Ember.A(months);
    },


    yearOptions: Ember.A(),

    setYearOptions: function setYearOptions() {
      var year = this.get('value').getFullYear();
      var years = [year + 3, year + 2, year + 1, year, year - 1, year - 2, year - 3, year - 4];

      var yearOptions = Ember.A(years.map(function (year) {
        return { label: year.toString(), value: year };
      }));
      this.set('yearOptions', yearOptions);
    },
    selectedMonth: function selectedMonth(value, monthOptions) {
      var month = value.getMonth() + 1;
      return monthOptions.findBy('value', month);
    },
    selectedYear: function selectedYear(value, yearOptions) {
      var year = value.getFullYear();
      return yearOptions.findBy('value', year);
    },
    _sendDate: function _sendDate(year, month) {
      this.get('onChange')(new Date(year, month - 1, 1));
    },
    onChangeMonth: function onChangeMonth(month) {
      this._sendDate(this.get('selectedYear.value'), month.value);
    },
    onChangeYear: function onChangeYear(year) {
      this._sendDate(year.value, this.get('selectedMonth.value'));
    }
  }, (_applyDecoratedDescriptor(_obj, 'monthOptions', [_dec], Object.getOwnPropertyDescriptor(_obj, 'monthOptions'), _obj), _applyDecoratedDescriptor(_obj, 'setYearOptions', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'setYearOptions'), _obj), _applyDecoratedDescriptor(_obj, 'selectedMonth', [_dec3], Object.getOwnPropertyDescriptor(_obj, 'selectedMonth'), _obj), _applyDecoratedDescriptor(_obj, 'selectedYear', [_dec4], Object.getOwnPropertyDescriptor(_obj, 'selectedYear'), _obj), _applyDecoratedDescriptor(_obj, 'onChangeMonth', [_object.action], Object.getOwnPropertyDescriptor(_obj, 'onChangeMonth'), _obj), _applyDecoratedDescriptor(_obj, 'onChangeYear', [_object.action], Object.getOwnPropertyDescriptor(_obj, 'onChangeYear'), _obj)), _obj)));
});