define('ui-library/components/p-page-numbers/component', ['exports', 'ui-library/components/p-page-numbers/template', 'ember-decorators/object', 'ember-keyboard'], function (exports, _template, _object, _emberKeyboard) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _desc, _value, _obj, _init, _init2;

  var ButtonCount = 7;
  var SliderWidth = ButtonCount - 4; // first/last page + slide left/right

  function makeQueryParams(paramName, pageNumber) {
    // background info: we used to have an embedded-page-numbers component when we wanted to
    // paginate an in-page list and bubble actions through that component to change page numbers
    // for these lists
    //
    // this is a hack to enable dynamic query parameter names
    // query-params helper in Ember basically returns an object similar to the one below
    // when this object us passed to link-to, a href is generated with the given query parameter
    // values. the implementation can be found at
    // github.com/emberjs/ember.js/blob/v2.5.0/packages/ember-routing/lib/system/query_params.js
    //
    // this way we do not need a separate component when page numbers are not tied to the 'page' param
    // e.g. transaction histories work with the 'txHistoryPage' param
    // also this allows us to always render links rather than bubbling actions to change page
    // number in these types of situations
    var values = {};
    values[paramName] = pageNumber;

    return Ember.Object.create({
      isQueryParams: true,
      values: values
    });
  }

  function makeLink(paramName, pageNumber, text, enabled) {
    return Ember.Object.create({
      queryParams: makeQueryParams(paramName, pageNumber),
      text: text,
      enabled: enabled
    });
  }

  function setStart(component, from, pageCount) {
    // start is always the 3rd item from left. 1st item is for the 1st page,
    // 2nd is for either slider or page 2. (we'll add page 2 below if necessary)
    // end is vice versa
    var start = Math.max(3, from);
    var end = start + SliderWidth - 1;
    if (end > pageCount - 2) {
      end = pageCount - 2;
      if (end - start < SliderWidth) {
        // if the current page is towards the end,
        // make sure we display all buttons
        start = Math.max(3, end - SliderWidth + 1);
      }
    }
    component.setProperties({ start: start, end: end });
  }

  var TYPES = ['solid', 'outline'];

  exports.default = Ember.Component.extend(_emberKeyboard.EKMixin, (_dec = (0, _object.computed)('type'), _dec2 = (0, _object.computed)('_pageCount'), _dec3 = (0, _object.computed)('_currentPage'), _dec4 = (0, _object.computed)('paramName', 'start', 'end', 'canSlideLeft', 'canSlideRight'), _dec5 = (0, _object.computed)('start', 'needsSliders'), _dec6 = (0, _object.computed)('end', 'needsSliders', 'pageCount'), _dec7 = (0, _object.computed)('paramName'), _dec8 = (0, _object.computed)('paramName', 'currentPage'), _dec9 = (0, _object.computed)('paramName', 'currentPage', 'pageCount'), _dec10 = (0, _object.computed)('paramName', 'pageCount'), (_obj = {
    layout: _template.default,
    tagName: 'ul',
    attributeBindings: ['data-tid'],
    classNameBindings: ['typeClass'],
    keyboardActivated: true,
    'data-test-page-numbers': true,

    type: 'solid',
    paramName: 'page',
    _currentPage: null,
    _pageCount: null,
    isVisible: Ember.computed.gt('pageCount', 1),
    needsSliders: Ember.computed.gt('pageCount', ButtonCount),

    typeClass: function typeClass(type) {
      (false && !(TYPES.includes(type)) && Ember.assert('invalid value for type', TYPES.includes(type)));

      return 'type-' + type;
    },

    pageCount: {
      get: function get(_pageCount) {
        return _pageCount;
      },
      set: function set(value) {
        this.set('_pageCount', value);
        setStart(this, this.get('currentPage') - Math.floor(SliderWidth / 2), value);
        return value;
      }
    },

    currentPage: {
      get: function get(_currentPage) {
        return _currentPage;
      },
      set: function set(value) {
        this.set('_currentPage', value);
        setStart(this, value - Math.floor(SliderWidth / 2), this.get('pageCount'));
        return value;
      }
    },

    items: function items(paramName, start, end, canSlideLeft, canSlideRight) {
      var from = start;
      var to = end;
      // if we won't add a left slider then add page #2 to items
      if (!canSlideLeft) {
        from -= 1;
      }

      // if we wont' add a right slider then add page #(count - 1) to items
      if (!canSlideRight) {
        to += 1;
      }

      var items = [];
      for (var page = from; page <= to; page++) {
        items.push(makeLink(paramName, page, page, true));
      }

      return items;
    },
    canSlideLeft: function canSlideLeft(start, needsSliders) {
      return needsSliders && start > 3;
    },
    canSlideRight: function canSlideRight(end, needsSliders, pageCount) {
      return needsSliders && end < pageCount - 2;
    },
    firstPageLink: function firstPageLink(paramName) {
      return makeLink(paramName, 1, '1', true);
    },
    prevPageLink: function prevPageLink(paramName, currentPage) {
      return makeLink(paramName, currentPage - 1, null, currentPage !== 1);
    },
    nextPageLink: function nextPageLink(paramName, currentPage, pageCount) {
      return makeLink(paramName, currentPage + 1, null, currentPage !== pageCount);
    },
    lastPageLink: function lastPageLink(paramName, pageCount) {
      return makeLink(paramName, pageCount, pageCount, true);
    },


    onRightArrow: Ember.on((0, _emberKeyboard.keyUp)('ArrowRight'), function (event) {
      this.$('li:last a').click();
      event.preventDefault();
    }),

    onLeftArrow: Ember.on((0, _emberKeyboard.keyUp)('ArrowLeft'), function (event) {
      this.$('li:first a').click();
      event.preventDefault();
    }),

    actions: {
      slideLeft: function slideLeft() {
        setStart(this, this.get('start') - SliderWidth, this.get('pageCount'));
      },
      slideRight: function slideRight() {
        setStart(this, this.get('start') + SliderWidth, this.get('pageCount'));
      }
    }
  }, (_applyDecoratedDescriptor(_obj, 'typeClass', [_dec], Object.getOwnPropertyDescriptor(_obj, 'typeClass'), _obj), _applyDecoratedDescriptor(_obj, 'pageCount', [_dec2], (_init = Object.getOwnPropertyDescriptor(_obj, 'pageCount'), _init = _init ? _init.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, 'currentPage', [_dec3], (_init2 = Object.getOwnPropertyDescriptor(_obj, 'currentPage'), _init2 = _init2 ? _init2.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init2;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, 'items', [_dec4], Object.getOwnPropertyDescriptor(_obj, 'items'), _obj), _applyDecoratedDescriptor(_obj, 'canSlideLeft', [_dec5], Object.getOwnPropertyDescriptor(_obj, 'canSlideLeft'), _obj), _applyDecoratedDescriptor(_obj, 'canSlideRight', [_dec6], Object.getOwnPropertyDescriptor(_obj, 'canSlideRight'), _obj), _applyDecoratedDescriptor(_obj, 'firstPageLink', [_dec7], Object.getOwnPropertyDescriptor(_obj, 'firstPageLink'), _obj), _applyDecoratedDescriptor(_obj, 'prevPageLink', [_dec8], Object.getOwnPropertyDescriptor(_obj, 'prevPageLink'), _obj), _applyDecoratedDescriptor(_obj, 'nextPageLink', [_dec9], Object.getOwnPropertyDescriptor(_obj, 'nextPageLink'), _obj), _applyDecoratedDescriptor(_obj, 'lastPageLink', [_dec10], Object.getOwnPropertyDescriptor(_obj, 'lastPageLink'), _obj)), _obj)));
});