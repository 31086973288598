define('ui-library/utils/string-util', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var TR_CHAR_REGEX = /[ÇçŞşĞğİıÜüÖö]/g;
  var TR_CHAR_MAP = {
    Ç: 'C',
    ç: 'c',
    Ş: 'S',
    ş: 's',
    Ğ: 'G',
    ğ: 'g',
    İ: 'I',
    ı: 'i',
    Ü: 'U',
    ü: 'u',
    Ö: 'O',
    ö: 'o'
  };
  var REGEXP_CHARS = /([.?*+^$[\]\\(){}|-])/g;

  function lowerTr(text) {
    return text.replace(/I/g, 'ı').replace(/İ/g, 'i').toLocaleLowerCase();
  }

  function upperTr(text) {
    return text.replace(/i/g, 'İ').replace(/ı/g, 'I').toLocaleUpperCase();
  }

  function titleWord(word) {
    if (word.length === 0) {
      return null;
    }
    var wordLower = lowerTr(word);
    if (wordLower === 've' || wordLower === 'veya') {
      return wordLower;
    }

    return upperTr(word.substr(0, 1)) + wordLower.substr(1);
  }

  function convert(input, block) {
    if (typeof input === 'undefined' || input === null) {
      return input;
    }

    return block(input);
  }

  exports.default = {
    toUpper: function toUpper(input) {
      return convert(input, upperTr);
    },
    toLower: function toLower(input) {
      return convert(input, lowerTr);
    },
    toSentence: function toSentence(input) {
      return convert(input, function () {
        return upperTr(input.substr(0, 1)) + lowerTr(input.substr(1));
      });
    },
    toTitle: function toTitle(input) {
      return convert(input, function () {
        var titleWords = input.match(/\S+/g);
        if (!titleWords || titleWords.length === 0) {
          return input;
        }
        var arr = titleWords.map(function (word) {
          return titleWord(word);
        });

        return Ember.A(arr).compact().join(' ');
      });
    },
    truncate: function truncate(input, count) {
      if (typeof input !== 'string' || typeof count !== 'number' || count >= input.length) {
        return input;
      }

      return input.substring(0, count) + '\u2026';
    },
    replaceTrChars: function replaceTrChars(input) {
      if (typeof input !== 'string') {
        return input;
      }
      return input.replace(TR_CHAR_REGEX, function (match) {
        return TR_CHAR_MAP[match];
      });
    },


    // https://stackoverflow.com/questions/16168484/javascript-syntax-error-invalid-regular-expression/16168723#16168723
    escapeRegExpChars: function escapeRegExpChars(input) {
      if (typeof input !== 'string') {
        return input;
      }
      return input.replace(REGEXP_CHARS, '\\$1');
    },
    join: function join(base, text, separator) {
      if (base) {
        if (text) {
          return base + separator + text;
        } else {
          return base;
        }
      } else if (text) {
        return text;
      } else {
        return '';
      }
    }
  };
});