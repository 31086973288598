define('ui-library/components/p-tabbed-content-tab-list/component', ['exports', 'ui-library/components/p-tabbed-content-tab-list/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    tagName: 'ul',
    attributeBindings: ['data-tid'],
    'data-test-tab-list': true
  });
});