define('ui-library/components/p-one-way-exchange-rate/component', ['exports', 'ui-library/components/p-one-way-exchange-rate/template', 'ember-decorators/object', 'ember-decorators/object/evented', 'ui-library/lib/constants', 'ui-library/helpers/currency-symbol', 'ember-prop-types'], function (exports, _template, _object, _evented, _constants, _currencySymbol, _emberPropTypes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _dec3, _dec4, _desc, _value, _obj;

  var CURRENCIES = ['TRL', 'USD', 'EUR', 'GBP'];

  exports.default = Ember.Component.extend((_dec = (0, _evented.on)('init'), _dec2 = (0, _object.observes)('rate', 'buyingRate', 'sellingRate'), _dec3 = (0, _object.computed)('fromCurrency', 'toCurrency'), _dec4 = (0, _object.computed)('buyingRate', 'sellingRate', 'rate'), (_obj = {
    'data-test-one-way-exchange-rate': true,

    layout: _template.default,
    i18n: Ember.inject.service(),

    propTypes: {
      rate: _emberPropTypes.PropTypes.number.isRequired,
      buyingRate: _emberPropTypes.PropTypes.number.isRequired,
      sellingRate: _emberPropTypes.PropTypes.number.isRequired,
      fromCurrency: _emberPropTypes.PropTypes.string.isRequired,
      toCurrency: _emberPropTypes.PropTypes.string.isRequired,
      onRateChange: _emberPropTypes.PropTypes.func.isRequired,
      onExchangeRateTypeChange: _emberPropTypes.PropTypes.func
    },
    getDefaultProps: function getDefaultProps() {
      return {
        onExchangeRateTypeChange: null
      };
    },


    _exchangeRateType: null,
    isOpen: false,

    initExchangeRateType: function initExchangeRateType() {
      this._setExchangeRateType(this.get('rate'), this.get('buyingRate'), this.get('sellingRate'));
    },
    exchangeRateChanged: function exchangeRateChanged() {
      this._setExchangeRateType(this.get('rate'), this.get('buyingRate'), this.get('sellingRate'));
    },
    append: function append(fromCurrency, toCurrency) {
      (false && !(CURRENCIES.includes(fromCurrency)) && Ember.assert('invalid value for fromCurrency', CURRENCIES.includes(fromCurrency)));
      (false && !(CURRENCIES.includes(toCurrency)) && Ember.assert('invalid value for toCurrency', CURRENCIES.includes(toCurrency)));


      var append = void 0;

      if (fromCurrency === _constants.default.DefaultCurrency) {
        append = (0, _currencySymbol.currencySymbol)([toCurrency]) + ' / ' + (0, _currencySymbol.currencySymbol)([fromCurrency]);
      } else {
        append = (0, _currencySymbol.currencySymbol)([fromCurrency]) + ' / ' + (0, _currencySymbol.currencySymbol)([toCurrency]);
      }
      return Ember.String.htmlSafe(append);
    },
    exchangeRateTypeText: function exchangeRateTypeText(buyingRate, sellingRate, rate) {
      if (buyingRate === rate) {
        return this.get('i18n').t('pOneWayExchangeRate.buying');
      } else if (sellingRate === rate) {
        return this.get('i18n').t('pOneWayExchangeRate.selling');
      } else {
        return this.get('i18n').t('pOneWayExchangeRate.other');
      }
    },
    changeRate: function changeRate(newRate) {
      this._setRate(newRate);
    },
    changeExchangeRateType: function changeExchangeRateType(newExchangeRateType, dropdown) {
      dropdown.actions.close();

      if (newExchangeRateType === this.get('_exchangeRateType')) {
        return;
      }

      this.set('_exchangeRateType', newExchangeRateType);
      if (newExchangeRateType === 'buying') {
        this._setRate(this.get('buyingRate'));
      } else if (newExchangeRateType === 'selling') {
        this._setRate(this.get('sellingRate'));
      }

      if (this.get('onExchangeRateTypeChange')) {
        this.get('onExchangeRateTypeChange')(newExchangeRateType);
      }
    },
    _setRate: function _setRate(rate) {
      this.set('rate', rate);
      if (this.get('onRateChange')) {
        this.get('onRateChange')(rate);
      }
    },
    _setExchangeRateType: function _setExchangeRateType(rate, buyingRate, sellingRate) {
      if (buyingRate === rate) {
        this.set('_exchangeRateType', 'buying');
      } else if (sellingRate === rate) {
        this.set('_exchangeRateType', 'selling');
      } else {
        this.set('_exchangeRateType', 'other');
      }
    }
  }, (_applyDecoratedDescriptor(_obj, 'initExchangeRateType', [_dec], Object.getOwnPropertyDescriptor(_obj, 'initExchangeRateType'), _obj), _applyDecoratedDescriptor(_obj, 'exchangeRateChanged', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'exchangeRateChanged'), _obj), _applyDecoratedDescriptor(_obj, 'append', [_dec3], Object.getOwnPropertyDescriptor(_obj, 'append'), _obj), _applyDecoratedDescriptor(_obj, 'exchangeRateTypeText', [_dec4], Object.getOwnPropertyDescriptor(_obj, 'exchangeRateTypeText'), _obj), _applyDecoratedDescriptor(_obj, 'changeRate', [_object.action], Object.getOwnPropertyDescriptor(_obj, 'changeRate'), _obj), _applyDecoratedDescriptor(_obj, 'changeExchangeRateType', [_object.action], Object.getOwnPropertyDescriptor(_obj, 'changeExchangeRateType'), _obj)), _obj)));
});