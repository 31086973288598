define('ui-library/components/p-app-content/component', ['exports', 'ui-library/components/p-app-content/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    tagName: 'main',
    expandLeft: false,
    expandTop: true,
    twoWarningBarVisible: false,
    classNameBindings: ['expandLeft', 'expandTop', 'twoWarningBarVisible']
  });
});