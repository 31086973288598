define("ui-library/components/p-list-select-toggle/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9i4+lflj",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"i\",true],[10,\"data-test-toggle\",\"state\"],[10,\"class\",\"toggle\"],[8],[9],[0,\"\\n\"],[7,\"span\",true],[10,\"data-test-content\",\"\"],[10,\"class\",\"content\"],[8],[0,\"\\n  \"],[14,1],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-library/components/p-list-select-toggle/template.hbs"
    }
  });

  _exports.default = _default;
});