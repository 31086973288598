define('ui-library/components/p-badge-icon/component', ['exports', 'ui-library/components/p-badge-icon/template', 'ember-decorators/object', 'feather'], function (exports, _template, _object, _feather) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _dec3, _dec4, _desc, _value, _obj;

  var SIZES = ['big', 'regular', 'small', 'tiny'],
      BACKGROUND_COLORS = ['gray', 'white'],
      ICON_COLORS = ['light', 'collection', 'payment', 'overdue'],
      SHAPES = ['rounded', 'circle'];

  exports.default = Ember.Component.extend((_dec = (0, _object.computed)('shape'), _dec2 = (0, _object.computed)('size'), _dec3 = (0, _object.computed)('backgroundColor'), _dec4 = (0, _object.computed)('iconColor'), (_obj = {
    layout: _template.default,

    shape: 'rounded',
    size: 'regular',
    iconPrefix: 'fa',
    icon: null,
    overlayIcon: null,
    backgroundColor: 'gray',
    iconColor: 'light',

    classNameBindings: ['shapeClass', 'sizeClass', 'busy', 'backgroundColorClass', 'iconColorClass'],

    'data-test-badge-icon': true,

    didInsertElement: function didInsertElement() {
      _feather.default.replace();
    },
    shapeClass: function shapeClass(shape) {
      (false && !(SHAPES.includes(shape)) && Ember.assert('invalid value for shape', SHAPES.includes(shape)));

      return 'shape-' + shape;
    },
    sizeClass: function sizeClass(size) {
      (false && !(SIZES.includes(size)) && Ember.assert('invalid value for size', SIZES.includes(size)));

      return 'size-' + size;
    },
    backgroundColorClass: function backgroundColorClass(backgroundColor) {
      (false && !(BACKGROUND_COLORS.includes(backgroundColor)) && Ember.assert('invalid value for backgroundColor', BACKGROUND_COLORS.includes(backgroundColor)));

      return 'backgroundColor-' + backgroundColor;
    },
    iconColorClass: function iconColorClass(iconColor) {
      (false && !(ICON_COLORS.includes(iconColor)) && Ember.assert('invalid value for iconColor', ICON_COLORS.includes(iconColor)));

      return 'iconColor-' + iconColor;
    }
  }, (_applyDecoratedDescriptor(_obj, 'shapeClass', [_dec], Object.getOwnPropertyDescriptor(_obj, 'shapeClass'), _obj), _applyDecoratedDescriptor(_obj, 'sizeClass', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'sizeClass'), _obj), _applyDecoratedDescriptor(_obj, 'backgroundColorClass', [_dec3], Object.getOwnPropertyDescriptor(_obj, 'backgroundColorClass'), _obj), _applyDecoratedDescriptor(_obj, 'iconColorClass', [_dec4], Object.getOwnPropertyDescriptor(_obj, 'iconColorClass'), _obj)), _obj)));
});