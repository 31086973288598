define('ui-library/utils/batch-actions/batch-action', ['exports', 'ember-decorators/object'], function (exports, _object) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = undefined;

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _desc, _value, _obj;

  var Status = Ember.Object.extend((_dec = (0, _object.computed)('successfulJobs.length', 'failedJobs.length'), _dec2 = (0, _object.computed)('jobCount', 'completedCount'), (_obj = {
    jobCount: 0,
    currentJob: null,
    successfulJobs: null,
    failedJobs: null,
    isRunning: Ember.computed.notEmpty('currentJob'),

    completedCount: function completedCount(successfulCount, failedCount) {
      return successfulCount + failedCount;
    },
    isCompleted: function isCompleted(jobCount, completedCount) {
      return jobCount > 0 && completedCount === jobCount;
    }
  }, (_applyDecoratedDescriptor(_obj, 'completedCount', [_dec], Object.getOwnPropertyDescriptor(_obj, 'completedCount'), _obj), _applyDecoratedDescriptor(_obj, 'isCompleted', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'isCompleted'), _obj)), _obj)));

  var BatchAction = function () {
    function BatchAction(jobs) {
      _classCallCheck(this, BatchAction);

      this.jobs = jobs;
      this.status = Status.create({
        jobCount: jobs.length,
        successfulJobs: Ember.A(),
        failedJobs: Ember.A()
      });
    }

    _createClass(BatchAction, [{
      key: 'run',
      value: function run() {
        var self = this;
        return this._runSequentially().finally(function () {
          self.status.set('currentJob', null);
        });
      }
    }, {
      key: '_runSequentially',
      value: function _runSequentially() {
        var jobRunners = this._makeJobRunners();
        var lastJobPromise = jobRunners[0]();
        for (var i = 1; i < this.jobs.length; i++) {
          lastJobPromise = lastJobPromise.finally(jobRunners[i]);
        }

        return lastJobPromise;
      }
    }, {
      key: '_makeJobRunners',
      value: function _makeJobRunners() {
        var self = this;
        return this.jobs.map(function (job) {
          return function () {
            self.status.set('currentJob', job);
            return job._runInternal().finally(function () {
              if (job.hasFailed()) {
                self.status.get('failedJobs').pushObject(job);
              } else {
                self.status.get('successfulJobs').pushObject(job);
              }
            });
          };
        });
      }
    }]);

    return BatchAction;
  }();

  exports.default = BatchAction;
});