define("ui-library/components/p-add-area/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zx5v7n6W",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"data-test-area\",\"\"],[11,\"disabled\",[22,\"disabled\"]],[8],[0,\"\\n  \"],[7,\"i\",true],[10,\"class\",\"custom-icon plus\"],[8],[9],[0,\"\\n  \"],[7,\"p\",true],[8],[1,[22,\"text\"],false],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-library/components/p-add-area/template.hbs"
    }
  });

  _exports.default = _default;
});