define('ui-library/helpers/iban-to-logo-url', ['exports', 'iban'], function (exports, _iban) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.ibanToLogoUrl = ibanToLogoUrl;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var EFT_CODE_TO_LOGO_SUFFIX = Ember.Object.create({
    '00001': 'tc-merkez-bankasi',
    '00004': 'iller-bankasi',
    '00010': 'ziraat-bankasi',
    '00012': 'halkbank',
    '00014': 'sinai-kalkinma-bankasi',
    '00015': 'vakiflar-bankasi',
    '00016': 'eximbank',
    '00017': 'kalkinma-bankasi',
    '00029': 'birlesik-fon-bankasi',
    '00032': 'turk-ekonomi-bankasi',
    '00046': 'akbank',
    '00059': 'sekerbank',
    '00062': 'garanti-bankasi',
    '00064': 'is-bankasi',
    '00067': 'yapi-ve-kredi-bankasi',
    '00071': 'fortis',
    '00088': 'royal-bank-of-scotland',
    '00091': 'arap-turk-bankasi',
    '00092': 'citibank',
    '00096': 'turkish-bank',
    '00098': 'jpmorgan-chase-bank',
    '00099': 'ing-bank',
    '00100': 'adabank',
    '00103': 'fibabanka',
    '00106': 'portigon-ag',
    '00108': 'turkland-bank',
    '00109': 'tekstil-bankasi',
    '00111': 'finansbank',
    '00115': 'deutsche-bank',
    '00116': 'pasha-bank',
    '00121': 'standard-chartered',
    '00122': 'societe-generale',
    '00123': 'hsbc',
    '00124': 'alternatif-bank',
    '00125': 'burgan-bank',
    '00129': 'merrill-lynch',
    '00132': 'takasbank',
    '00134': 'denizbank',
    '00135': 'anadolu-bank',
    '00138': 'dilerbank',
    '00139': 'gsd-bank',
    '00141': 'nurol-yatirim-bankasi',
    '00142': 'bankpozitif',
    '00143': 'aktif-yatirim-bankasi',
    '00146': 'odea-bank',
    '00203': 'albaraka-turk',
    '00205': 'kuveyt-turk',
    '00206': 'turkiye-finans',
    '00208': 'bank-asya',
    '00209': 'ziraat-katilim-bankasi',
    '00210': 'vakif-katilim-bankasi',
    '00806': 'merkezi-kayit-kurulusu'
  });

  var BASE_URL = 'https://parasut-trinity.s3.amazonaws.com/static-assets/iban-to-bank-logos';
  var HASH = '83289f';

  function ibanToLogoUrl(_ref) {
    var _ref2 = _slicedToArray(_ref, 1),
        iban = _ref2[0];

    iban = _iban.default.electronicFormat(iban);

    var countryCode = iban.substring(0, 2);
    var eftCode = iban.substring(4, 9);
    var suffix = EFT_CODE_TO_LOGO_SUFFIX.get(eftCode);

    if (countryCode != 'TR' || suffix == null) {
      return null;
    } else {
      return BASE_URL + '-' + HASH + '/' + suffix + '.png';
    }
  }

  exports.default = Ember.Helper.helper(ibanToLogoUrl);
});