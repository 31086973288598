define('ui-library/components/p-search-box/component', ['exports', 'ui-library/components/p-search-box/template', 'ember-decorators/object'], function (exports, _template, _object) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _desc, _value, _obj, _init;

  exports.default = Ember.Component.extend((_dec = (0, _object.computed)('pageQuery', 'inputValue'), _dec2 = (0, _object.computed)('hasActiveFilters', 'customPlaceholder'), (_obj = {
    layout: _template.default,
    keyboardActivated: true,

    i18n: Ember.inject.service(),

    pageQuery: null,
    inputValue: null,
    hasActiveFilters: false,
    customPlaceholder: null,
    instantSearchMode: false,
    onSearchQueryChange: null,
    attributeBindings: ['data-test-search-box', 'data-tid'],
    'data-test-search-box': true,

    currentQuery: {
      get: function get() {
        return this.get('pageQuery');
      },
      set: function set(value) {
        this.set('pageQuery', value);
        this.set('inputValue', value);
        return value;
      }
    },

    showClearSearchButton: function showClearSearchButton(pageQuery, inputValue) {
      if (this.get('instantSearchMode')) {
        return !Ember.isBlank(pageQuery);
      }
      return !Ember.isBlank(pageQuery) && pageQuery === inputValue;
    },
    placeholderKey: function placeholderKey(hasActiveFilters, customPlaceholder) {
      if (hasActiveFilters) {
        return this.get('i18n').t('pSearchBox.activePlaceholder');
      }
      return customPlaceholder || this.get('i18n').t('pSearchBox.blankPlaceholder');
    },
    _sendOnSearchQueryChange: function _sendOnSearchQueryChange(value) {
      if (this.get('onSearchQueryChange')) {
        this.get('onSearchQueryChange')(value);
      }
    },


    actions: {
      onButtonClick: function onButtonClick() {
        if (this.get('showClearSearchButton')) {
          this._sendOnSearchQueryChange('');
        } else {
          this.send('trigger');
        }
      },
      trigger: function trigger() {
        if (!this.get('instantSearchMode')) {
          this.$(':parent').focus();
        }
        this._sendOnSearchQueryChange(this.get('inputValue'));
      },
      onEscape: function onEscape() {
        var inputValue = this.get('inputValue');
        var pageQuery = this.get('pageQuery');
        if (inputValue === pageQuery) {
          this.$(':parent').focus();
        } else {
          this.set('inputValue', this.get('pageQuery'));
        }
      }
    }
  }, (_applyDecoratedDescriptor(_obj, 'currentQuery', [_object.computed], (_init = Object.getOwnPropertyDescriptor(_obj, 'currentQuery'), _init = _init ? _init.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, 'showClearSearchButton', [_dec], Object.getOwnPropertyDescriptor(_obj, 'showClearSearchButton'), _obj), _applyDecoratedDescriptor(_obj, 'placeholderKey', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'placeholderKey'), _obj)), _obj)));
});