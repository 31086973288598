define("ui-library/components/p-list-tally/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pgQUzrQZ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\",true],[10,\"data-test-label\",\"\"],[8],[1,[22,\"name\"],false],[9],[0,\"\\n\"],[7,\"span\",true],[10,\"data-test-number\",\"\"],[8],[0,\"\\n  \"],[4,\"if\",[[24,[\"positiveSignVisible\"]]],null,{\"statements\":[[0,\"+\"]],\"parameters\":[]},null],[1,[28,\"p-amount-display\",null,[[\"size\",\"color\",\"value\",\"currency\"],[\"small\",\"light\",[24,[\"decimal2Amount\"]],[24,[\"currency\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-library/components/p-list-tally/template.hbs"
    }
  });

  _exports.default = _default;
});