define('ui-library/utils/number-util', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var DECIMAL_SEPARATOR = exports.DECIMAL_SEPARATOR = ',',
      GROUP_SEPARATOR = exports.GROUP_SEPARATOR = '.';

  var GROUPING_REG_EXP = new RegExp('\\' + GROUP_SEPARATOR, 'g');
  var DECIMAL_REG_EXP = new RegExp('\\' + DECIMAL_SEPARATOR, 'g');
  var APPLY_GROUP_REGEXP = /\B(?=(\d{3})+(?!\d))/g;

  exports.default = {
    Decimal2Multiplier: 100.0,
    Decimal4Multiplier: 10000.0,

    parseFloat: function (_parseFloat) {
      function parseFloat(_x2) {
        return _parseFloat.apply(this, arguments);
      }

      parseFloat.toString = function () {
        return _parseFloat.toString();
      };

      return parseFloat;
    }(function (value) {
      var precision = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

      var floatVal = parseFloat(value);

      if (isNaN(floatVal)) {
        return 0.0;
      }

      if (precision != null) {
        floatVal = parseFloat(floatVal.toFixed(precision));
      }
      return floatVal;
    }),
    fixedToDecimal2: function fixedToDecimal2(value) {
      return Ember.isBlank(value) ? 0 : Math.round(this.parseFloat(value) * this.Decimal2Multiplier);
    },
    fixedToDecimal4: function fixedToDecimal4(value) {
      return Ember.isBlank(value) ? 0 : Math.round(this.parseFloat(value) * this.Decimal4Multiplier);
    },
    decimal2ToFixed: function decimal2ToFixed(value) {
      return Ember.isBlank(value) ? 0 : (value / this.Decimal2Multiplier).toFixed(2);
    },
    decimal4ToFixed: function decimal4ToFixed(value) {
      return Ember.isBlank(value) ? 0 : (value / this.Decimal4Multiplier).toFixed(4);
    },
    applyGroupFormatting: function applyGroupFormatting(numberString) {
      //found on http://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
      return numberString.replace(APPLY_GROUP_REGEXP, GROUP_SEPARATOR);
    },
    _splitAndFormatDecimal: function _splitAndFormatDecimal(value, exponent) {
      var multiplier = Math.pow(10, exponent);
      if (typeof value !== 'number') {
        throw new Error('given value is not a number');
      }

      var left = void 0;
      if (value >= 0) {
        left = this.applyGroupFormatting(Math.floor(value / multiplier).toString());
      } else if (value > -multiplier) {
        left = '-0';
      } else {
        left = this.applyGroupFormatting(Math.ceil(value / multiplier).toString());
      }
      var right = Math.abs(value % multiplier).toString();
      if (right.length < exponent) {
        var zeroPad = Array(exponent - right.length + 1).join('0');
        right = '' + zeroPad + right;
      }

      return {
        left: left,
        right: right,
        decimalSeperator: DECIMAL_SEPARATOR
      };
    },
    splitAndFormatDecimal2: function splitAndFormatDecimal2(value) {
      return this._splitAndFormatDecimal(value, 2);
    },
    splitAndFormatDecimal4: function splitAndFormatDecimal4(value) {
      return this._splitAndFormatDecimal(value, 4);
    },
    splitAndFormatFloat: function splitAndFormatFloat(value, precision) {
      var formatted = this.formatFloat(value, precision);
      var parts = formatted.split(DECIMAL_SEPARATOR);

      return {
        left: parts[0],
        right: parts[1],
        decimalSeperator: DECIMAL_SEPARATOR
      };
    },


    // this function trims at most two zeros
    splitAndFormatDecimal4AndTrimZeros: function splitAndFormatDecimal4AndTrimZeros(value) {
      if (typeof value !== 'number') {
        throw new Error('given value is not a number');
      }

      if (value % 100 === 0) {
        return this._splitAndFormatDecimal(value / 100, 2);
      } else if (value % 10 === 0) {
        return this._splitAndFormatDecimal(value / 10, 3);
      }

      return this._splitAndFormatDecimal(value, 4);
    },
    formatDecimal2: function formatDecimal2(value) {
      if (value == null) {
        return '';
      }

      var parts = this.splitAndFormatDecimal2(value);
      return '' + parts.left + DECIMAL_SEPARATOR + parts.right;
    },
    formatDecimal4: function formatDecimal4(value) {
      if (value == null) {
        return '';
      }

      var parts = this.splitAndFormatDecimal4(value);
      return '' + parts.left + DECIMAL_SEPARATOR + parts.right;
    },
    variablePrecisionForFloat: function variablePrecisionForFloat(number) {
      if (typeof number !== 'number') {
        throw new Error('given value is not a number');
      }
      var intVal = Math.round(number * 10000);
      if (intVal % 100 === 0) {
        return 2;
      } else if (intVal % 10 === 0) {
        return 3;
      }
      return 4;
    },
    formatFloat: function formatFloat(number, precision) {
      if (typeof number === 'undefined' || number === null) {
        return '';
      }
      if (typeof number === 'string') {
        number = this.parseFloat(number);
      }
      if (typeof number === 'number') {
        number = number.toFixed(precision || 2);
      }

      var parts = number.toString().split('.');
      parts[0] = this.applyGroupFormatting(parts[0]);
      return parts.join(DECIMAL_SEPARATOR);
    },


    // in case of formatInt, grouping will be applied directly.
    // i.e. if you pass a float.toString() result, you will get wierd results
    // if the value passed to formatInt is a number it will be rounded (via toFixed)
    formatInt: function formatInt(number) {
      if (typeof number === 'undefined' || number === null || number === '') {
        return '';
      }
      if (typeof number === 'string') {
        number = parseInt(number, 10).toString();
      }
      if (typeof number === 'number') {
        number = number.toFixed(0);
      }
      return this.applyGroupFormatting(number);
    },
    parseFormattedFloat: function parseFormattedFloat(number, precision) {
      if (number == null || number.trim().length === 0) {
        return 0.0;
      }
      return this.parseFloat(this._sanitizeFormattedNumber(number), precision);
    },
    parseFormattedInt: function parseFormattedInt(number, canBeEmpty) {
      if (number == null || number.trim().length === 0) {
        if (canBeEmpty) {
          return '';
        }
        return 0;
      }
      return Math.round(parseFloat(this._sanitizeFormattedNumber(number), 10));
    },

    parseFormattedDecimal2: function parseFormattedDecimal2(number) {
      if (number == null || number.trim().length === 0) {
        return 0;
      }
      return this.fixedToDecimal2(this._sanitizeFormattedNumber(number));
    },
    parseFormattedDecimal4: function parseFormattedDecimal4(number) {
      if (number == null || number.trim().length === 0) {
        return 0;
      }
      return this.fixedToDecimal4(this._sanitizeFormattedNumber(number));
    },

    _sanitizeFormattedNumber: function _sanitizeFormattedNumber(number) {
      return number.replace(GROUPING_REG_EXP, '').replace(DECIMAL_REG_EXP, '.');
    }
  };
});