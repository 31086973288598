define("ui-library/components/p-input-decoration/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mXdHB1V2",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"hasPrepend\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"data-test-p-input-decoration-prepend\",\"\"],[10,\"class\",\"prepend\"],[8],[1,[22,\"prepend\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[14,1],[0,\"\\n\"],[4,\"if\",[[24,[\"hasAppend\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"data-test-p-input-decoration-append\",\"\"],[10,\"class\",\"append\"],[8],[1,[22,\"append\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"displayIcon\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"iconClickable\"]]],null,{\"statements\":[[0,\"    \"],[7,\"button\",false],[12,\"tabindex\",\"-1\"],[12,\"data-test-icon-button\",\"\"],[3,\"action\",[[23,0,[]],\"clickIcon\"]],[8],[0,\"\\n      \"],[7,\"i\",true],[11,\"class\",[29,[\"fa \",[22,\"icon\"]]]],[10,\"data-test-icon\",\"\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"i\",true],[11,\"class\",[29,[\"fa \",[22,\"icon\"]]]],[10,\"data-test-icon\",\"\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[4,\"if\",[[24,[\"busy\"]]],null,{\"statements\":[[0,\"  \"],[7,\"i\",true],[10,\"class\",\"fa fa-spinner fa-pulse\"],[10,\"data-test-busy\",\"\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-library/components/p-input-decoration/template.hbs"
    }
  });

  _exports.default = _default;
});