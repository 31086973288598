define('ui-library/components/p-one-way-iban/component', ['exports', 'ui-library/components/p-one-way-iban/template', 'ember-decorators/object', 'ember-prop-types', 'ui-library/helpers/iban-to-logo-url', 'iban'], function (exports, _template, _object, _emberPropTypes, _ibanToLogoUrl, _iban2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _dec3, _dec4, _desc, _value, _obj;

  // from https://github.com/ahmetemrekilinc/ember-mask-input/blob/master/addon/ember-mask-input/masks.js#L3
  var MASK_INCLUDING_PREFIX = 'AAAA AAAA AAAA AAAA AAAA AAAA AAAA AAAA AA';
  var MASK_EXCLUDING_PREFIX = 'AA AAAA AAAA AAAA AAAA AAAA AAAA AAAA AA'; // prettier-ignore

  exports.default = Ember.Component.extend((_dec = (0, _object.computed)('hasPrefix'), _dec2 = (0, _object.computed)('value'), _dec3 = (0, _object.computed)('hideIcon', 'isValid', 'showLogo'), _dec4 = (0, _object.computed)('showLogo', 'isValid', 'value'), (_obj = {
    'data-test-p-one-way-iban': true,

    layout: _template.default,

    propTypes: {
      value: _emberPropTypes.PropTypes.oneOfType([_emberPropTypes.PropTypes.string, _emberPropTypes.PropTypes.null]),
      prefix: _emberPropTypes.PropTypes.string,
      showLogo: _emberPropTypes.PropTypes.bool,
      update: _emberPropTypes.PropTypes.func.isRequired
    },
    getDefaultProps: function getDefaultProps() {
      return {
        value: '',
        prefix: '',
        showLogo: true
      };
    },


    hasPrefix: Ember.computed.notEmpty('prefix'),
    hideIcon: Ember.computed.empty('inputValue'),

    inputValue: '',

    didReceiveAttrs: function didReceiveAttrs() {
      if (this.get('hasPrefix')) {
        var iban = this.get('value');
        var withoutPrefix = this.removePrefix(iban);
        this.set('inputValue', withoutPrefix);
      } else {
        var _iban = this.get('value');
        this.set('inputValue', _iban);
      }
    },
    mask: function mask(hasPrefix) {
      return hasPrefix ? MASK_EXCLUDING_PREFIX : MASK_INCLUDING_PREFIX;
    },
    isValid: function isValid(iban) {
      return _iban2.default.isValid(iban);
    },
    icon: function icon(hideIcon, isValid, showLogo) {
      if (hideIcon || isValid && showLogo) {
        return '';
      } else if (isValid) {
        return 'fa fa-check';
      } else {
        return 'fa fa-exclamation-circle';
      }
    },
    logoSrc: function logoSrc(showLogo, isValid, iban) {
      if (showLogo && isValid) {
        return (0, _ibanToLogoUrl.ibanToLogoUrl)([iban]);
      } else {
        return null;
      }
    },
    removePrefix: function removePrefix(value) {
      return value.replace(this.get('prefix'), '');
    },
    raiseUpdateAction: function raiseUpdateAction(value) {
      this.get('update')(value);
    },
    changeValue: function changeValue(value) {
      // ember-mask-input raises null when input becomes empty
      if (value == null) {
        this.raiseUpdateAction('');
      } else if (this.get('hasPrefix')) {
        var withoutPrefix = this.removePrefix(value);

        if (Ember.isEmpty(withoutPrefix)) {
          this.raiseUpdateAction('');
        } else {
          var prefixed = '' + this.get('prefix') + withoutPrefix;
          this.raiseUpdateAction(prefixed);
        }
      } else {
        this.raiseUpdateAction(value);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, 'mask', [_dec], Object.getOwnPropertyDescriptor(_obj, 'mask'), _obj), _applyDecoratedDescriptor(_obj, 'isValid', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'isValid'), _obj), _applyDecoratedDescriptor(_obj, 'icon', [_dec3], Object.getOwnPropertyDescriptor(_obj, 'icon'), _obj), _applyDecoratedDescriptor(_obj, 'logoSrc', [_dec4], Object.getOwnPropertyDescriptor(_obj, 'logoSrc'), _obj), _applyDecoratedDescriptor(_obj, 'changeValue', [_object.action], Object.getOwnPropertyDescriptor(_obj, 'changeValue'), _obj)), _obj)));
});