define('ui-library/utils/date-util', ['exports', 'moment', 'ui-library/utils/string-util'], function (exports, _moment, _stringUtil) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    monthName: function monthName(date, thisMonthTranslated) {
      if (!Ember.isEmpty(date)) {
        var result = _stringUtil.default.toUpper(date.format('MMMM'));

        if ((0, _moment.default)().get('year') === date.get('year')) {
          if ((0, _moment.default)().get('month') === date.get('month') && !Ember.isEmpty(thisMonthTranslated)) {
            result += ' (' + thisMonthTranslated + ')';
          }
        } else {
          result += ' ' + date.get('year');
        }

        return result;
      } else {
        return '';
      }
    }
  };
});