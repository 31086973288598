define("ui-library/components/p-table-header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XSaGcsad",
    "block": "{\"symbols\":[\"width\",\"&default\"],\"statements\":[[7,\"table\",true],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"columnWidths\",\"length\"]]],null,{\"statements\":[[0,\"    \"],[7,\"colgroup\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"columnWidths\"]]],null,{\"statements\":[[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"          \"],[7,\"col\",true],[11,\"style\",[23,1,[]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[7,\"col\",true],[8],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"thead\",true],[8],[0,\"\\n    \"],[7,\"tr\",true],[8],[0,\"\\n      \"],[14,2,[[28,\"hash\",null,[[\"column\"],[[28,\"component\",[\"p-table-column\"],null]]]]]],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-library/components/p-table-header/template.hbs"
    }
  });

  _exports.default = _default;
});