define("ui-library/components/p-chart-card-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Y9a1hWND",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"list\"],[[28,\"component\",[\"p-chart-category-list\"],[[\"name\",\"bgColor\",\"value\",\"size\",\"currency\",\"fullPath\",\"action\",\"active\",\"data-test-p-chart-category-list\"],[[24,[\"name\"]],[24,[\"bgColor\"]],[24,[\"value\"]],[24,[\"size\"]],[24,[\"currency\"]],[24,[\"fullPath\"]],[24,[\"action\"]],[24,[\"actvie\"]],true]]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-library/components/p-chart-card-table/template.hbs"
    }
  });

  _exports.default = _default;
});