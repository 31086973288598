define("ui-library/components/p-badge-icon/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sxSAQtmf",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"isFeather\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"class\",\"feather-icon\"],[8],[0,\"\\n    \"],[7,\"i\",true],[11,\"data-feather\",[29,[[22,\"icon\"]]]],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"i\",true],[11,\"class\",[29,[[22,\"iconPrefix\"],\" \",[22,\"icon\"]]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]}],[4,\"if\",[[24,[\"overlayIcon\"]]],null,{\"statements\":[[0,\"  \"],[7,\"i\",true],[11,\"class\",[29,[[22,\"iconPrefix\"],\" \",[22,\"overlayIcon\"],\" overlay-icon\"]]],[10,\"data-test-badge-icon-overlay\",\"\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-library/components/p-badge-icon/template.hbs"
    }
  });

  _exports.default = _default;
});