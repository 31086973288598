define('ui-library/components/p-page-content/component', ['exports', 'ui-library/components/p-page-content/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    expandRight: true,
    classNameBindings: ['expandRight'],
    attributeBindings: ['data-tns']
  });
});