define("ui-library/components/p-month-year-selector/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Jk3kmNU9",
    "block": "{\"symbols\":[\"year\",\"month\"],\"statements\":[[4,\"p-one-way-select\",null,[[\"onchange\",\"options\",\"selected\",\"data-test-month-select\"],[[28,\"action\",[[23,0,[]],\"onChangeMonth\"],null],[24,[\"monthOptions\"]],[24,[\"selectedMonth\"]],true]],{\"statements\":[[0,\"  \"],[1,[23,2,[\"label\"]],false],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"\\n\"],[4,\"p-one-way-select\",null,[[\"onchange\",\"options\",\"selected\",\"data-test-year-select\"],[[28,\"action\",[[23,0,[]],\"onChangeYear\"],null],[24,[\"yearOptions\"]],[24,[\"selectedYear\"]],true]],{\"statements\":[[0,\"  \"],[1,[23,1,[\"label\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-library/components/p-month-year-selector/template.hbs"
    }
  });

  _exports.default = _default;
});