define("ember-simple-auth-oidc/mixins/oidc-application-route-mixin", ["exports", "ember-simple-auth-oidc/config", "ember-simple-auth-oidc/utils/absoluteUrl", "ember-simple-auth/mixins/application-route-mixin"], function (_exports, _config, _absoluteUrl, _applicationRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var host = _config.default.host,
      endSessionEndpoint = _config.default.endSessionEndpoint,
      afterLogoutUri = _config.default.afterLogoutUri;

  var _default = Ember.Mixin.create(_applicationRouteMixin.default, {
    session: Ember.inject.service(),
    router: Ember.inject.service(),

    /**
     * This method is called after a successful authentication and continues an
     * intercepted transition if a URL is stored in `nextURL` in the
     * localstorage. Otherwise call the parent/super to invoke the normal
     * behavior of the `sessionAuthenticated` method.
     *
     * @method sessionAuthenticated
     * @public
     */
    sessionAuthenticated: function sessionAuthenticated() {
      var nextURL = this.session.data.nextURL;
      this.session.set("data.nextURL", undefined);
      var idToken = this.session.data.authenticated.id_token;
      this.session.set("data.id_token_prev", idToken);

      if (nextURL) {
        this.replaceWith(nextURL);
      } else {
        this._super();
      }
    },
    _redirectToUrl: function _redirectToUrl(url) {
      location.replace(url);
    },
    sessionInvalidated: function sessionInvalidated() {
      if (!endSessionEndpoint) {
        return;
      }

      var params = [];

      if (afterLogoutUri) {
        params.push("post_logout_redirect_uri=".concat((0, _absoluteUrl.default)(afterLogoutUri)));
      }

      var idToken = this.session.get("data.id_token_prev");

      if (idToken) {
        params.push("id_token_hint=".concat(idToken));
      }

      return this._redirectToUrl("".concat((0, _absoluteUrl.default)(endSessionEndpoint, host), "?").concat(params.join("&")));
    }
  });

  _exports.default = _default;
});