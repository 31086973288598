define('ui-library/components/p-one-way-time/component', ['exports', 'ember-one-way-controls/components/one-way-time', 'moment', 'ember-decorators/object'], function (exports, _oneWayTime, _moment, _object) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _desc, _value, _obj, _init;

  exports.default = _oneWayTime.default.extend((_dec = (0, _object.computed)('_date'), (_obj = {
    dateupdate: null,
    _date: null,

    didInsertElement: function didInsertElement() {
      var _this = this;

      this.set('update', function (value) {
        var currentDate = _this.get('_date') || (0, _moment.default)();
        var newDate = _this._parseDate(value, currentDate);
        if (!newDate.isSame(currentDate)) {
          if (_this.get('dateupdate')) {
            _this.get('dateupdate')(newDate.toDate());
          }
        }
        _this.set('value', value);
      });
    },

    date: {
      get: function get(date) {
        return date.toDate();
      },
      set: function set(value, currentDate) {
        var momentValue = value ? (0, _moment.default)(value) : (0, _moment.default)();
        this.set('_date', momentValue);

        if (!momentValue.isSame(currentDate)) {
          this.set('value', this._formatDate(momentValue));
        }

        return value;
      }
    },

    _parseDate: function _parseDate(value, currentDate) {
      var time = (0, _moment.default)(value, 'HH:mm');
      return currentDate.clone().hour(time.hour()).minute(time.minute()).second(0);
    },
    _formatDate: function _formatDate(date) {
      return date.format('HH:mm');
    }
  }, (_applyDecoratedDescriptor(_obj, 'date', [_dec], (_init = Object.getOwnPropertyDescriptor(_obj, 'date'), _init = _init ? _init.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init;
    }
  }), _obj)), _obj)));
});