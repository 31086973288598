define("ui-library/components/p-table-body/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ch33Ll80",
    "block": "{\"symbols\":[\"row\",\"width\",\"&default\"],\"statements\":[[7,\"table\",true],[10,\"data-test-rows-table\",\"\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"columnWidths\",\"length\"]]],null,{\"statements\":[[0,\"    \"],[7,\"colgroup\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"columnWidths\"]]],null,{\"statements\":[[4,\"if\",[[23,2,[]]],null,{\"statements\":[[0,\"          \"],[7,\"col\",true],[10,\"data-test-column-definition\",\"\"],[11,\"style\",[23,2,[]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[7,\"col\",true],[8],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[2]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"each\",[[24,[\"rows\"]]],null,{\"statements\":[[0,\"    \"],[14,3,[[23,1,[]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-library/components/p-table-body/template.hbs"
    }
  });

  _exports.default = _default;
});