define("ui-library/components/p-collapse-content/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lKVX68up",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"data-test-yielded-collapse-content\",\"\"],[11,\"data-open\",[22,\"isOpen\"]],[10,\"class\",\"collapse-content\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"collapsible\"],[10,\"data-test-collapsible\",\"true\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"p\",true],[10,\"class\",\"title\"],[10,\"data-test-title\",\"true\"],[8],[1,[22,\"title\"],false],[9],[0,\"\\n\\t\\t\\t\\t\"],[7,\"p\",true],[10,\"class\",\"subTitle\"],[10,\"data-test-subtitle\",\"true\"],[8],[1,[22,\"subTitle\"],false],[9],[0,\"\\n\"],[4,\"p-button\",null,[[\"action\",\"tabIndex\",\"disabled\",\"type\",\"data-test-open-button\"],[[28,\"action\",[[23,0,[]],\"_open\"],null],\"-1\",[24,[\"saveTask\",\"isRunning\"]],\"dashed\",true]],{\"statements\":[[0,\"\\t\\t\\t\\t\\t\\t\"],[1,[22,\"buttonText\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"content\"],[10,\"data-test-content\",\"true\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[14,1],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-library/components/p-collapse-content/template.hbs"
    }
  });

  _exports.default = _default;
});