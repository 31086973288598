define("ui-library/components/p-chart-card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cCNIynRZ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"chart-card\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"title\"],[10,\"data-test-chart-title\",\"\"],[8],[1,[22,\"title\"],false],[9],[0,\"\\n  \"],[14,1,[[28,\"hash\",null,[[\"chart\",\"table\"],[[28,\"component\",[\"p-chart-card-chart\"],[[\"data-test-p-chart-card-chart\"],[true]]],[28,\"component\",[\"p-chart-card-table\"],[[\"data-test-p-chart-card-table\"],[true]]]]]]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-library/components/p-chart-card/template.hbs"
    }
  });

  _exports.default = _default;
});