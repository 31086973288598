define("ui-library/components/p-multiple-select-with-checkboxes-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6AJlp36E",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"a\",false],[12,\"href\",\"#\"],[12,\"data-test-option\",\"\"],[3,\"action\",[[23,0,[]],\"toggleSelection\"]],[8],[0,\"\\n\"],[4,\"if\",[[28,\"or\",[[28,\"is-asist\",null,null],[28,\"is-phoenix\",null,null]],null]],null,{\"statements\":[[0,\"    \"],[7,\"i\",true],[11,\"class\",[29,[\"p-checkbox \",[28,\"if\",[[24,[\"isSelected\"]],\"checked\"],null]]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"i\",true],[11,\"class\",[29,[\"checkbox fa \",[28,\"if\",[[24,[\"isSelected\"]],\"fa-check-square\",\"fa-square-o\"],null]]]],[10,\"data-test-checkbox\",\"\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[14,1],[0,\"\\n\"],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-library/components/p-multiple-select-with-checkboxes-item/template.hbs"
    }
  });

  _exports.default = _default;
});