define('ui-library/components/p-tagerize-input/component', ['exports', 'ui-library/components/p-tagerize-input/template', 'ember-decorators/object'], function (exports, _template, _object) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _desc, _value, _obj;

  exports.default = Ember.Component.extend((_obj = {
    layout: _template.default,
    tags: Ember.A(),
    onAddTag: null,
    onRemoveTag: null,
    onKeyUp: null,
    placeholder: null,
    allowSpaces: false,
    disableNewTagInput: false,
    'data-test-tagerize-input': true,

    onKeyUpAction: function onKeyUpAction(value) {
      if (this.get('onKeyUp')) {
        this.get('onKeyUp')(value);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, 'onKeyUpAction', [_object.action], Object.getOwnPropertyDescriptor(_obj, 'onKeyUpAction'), _obj)), _obj));
});