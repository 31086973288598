define("ui-library/components/p-tagerize-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2OcBVX4K",
    "block": "{\"symbols\":[\"tag\",\"&default\"],\"statements\":[[4,\"tag-input\",null,[[\"tags\",\"addTag\",\"removeTagAtIndex\",\"onKeyUp\",\"placeholder\",\"disableNewTagInput\",\"allowSpacesInTags\"],[[24,[\"tags\"]],[28,\"action\",[[23,0,[]],[24,[\"onAddTag\"]]],null],[28,\"action\",[[23,0,[]],[24,[\"onRemoveTag\"]]],null],[28,\"action\",[[23,0,[]],[24,[\"onKeyUpAction\"]]],null],[24,[\"placeholder\"]],[24,[\"disableNewTagInput\"]],[24,[\"allowSpaces\"]]]],{\"statements\":[[0,\"  \"],[14,2,[[23,1,[]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-library/components/p-tagerize-input/template.hbs"
    }
  });

  _exports.default = _default;
});