define('ember-plupload/test-helper', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.addFiles = addFiles;


  function FakeFile(attrs) {
    this.id = Ember.generateGuid();
    attrs.plupload.total.size += attrs.size;
    Ember.merge(this, attrs);
  }

  FakeFile.prototype = {
    upload: function upload(settings) {
      this.settings = settings;
      this.percent = 0;
    },
    respondWith: function respondWith(status, headers, body) {
      var _this = this;

      var contentType = (headers['Content-Type'] || '').split(';');

      // Serialize if JSON
      if (contentType.indexOf('application/json') !== -1 || contentType.indexOf('text/javascript') !== -1 || contentType.indexOf('application/javascript') !== -1) {
        body = JSON.stringify(body);
      }
      var responseHeaders = Object.keys(headers).map(function (key) {
        return key + ': ' + headers[key];
      }).join('\n');

      Ember.run(function () {
        _this.queue.fileUploaded(_this.plupload, _this, {
          status: status,
          responseHeaders: responseHeaders,
          response: body
        });
      });
    },


    get progress() {
      return this.percent;
    },

    set progress(value) {
      var _this2 = this;

      this.percent = value;
      Ember.run(function () {
        _this2.plupload.total.loaded += _this2.size * (value / 100);
        _this2.queue.progressDidChange(_this2.plupload, _this2);
      });
    },

    getSource: function getSource() {
      return this;
    }
  };

  mOxie.FileReader = function () {};
  mOxie.FileReader.prototype = {
    read: function read(type, source) {
      var _this3 = this;

      Ember.assert('"' + source.name + '" doesn\'t have a ' + type + ' for the file to read\nWhen calling addFiles(), provide the following property:\n\naddFiles(this.container, "' + source.queueName + '", {\n  name: "' + source.name + '",\n  size: ' + source.size + ',\n  ' + type + ': Ember.RSVP.resolve("your source here")\n})', source[type]);
      source[type].then(function (result) {
        _this3.result = result;
        _this3.onloadend();
      }, function (error) {
        _this3.error = error;
        _this3.onerror();
      });
    },
    readAsArrayBuffer: function readAsArrayBuffer(source) {
      this.read('arrayBuffer', source);
    },
    readAsDataURL: function readAsDataURL(source) {
      this.read('dataURL', source);
    },
    readAsBinaryString: function readAsBinaryString(source) {
      this.read('binaryString', source);
    },
    readAsText: function readAsText(source) {
      this.read('text', source);
    }
  };

  function addFiles(owner, name) {
    for (var _len = arguments.length, files = Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
      files[_key - 2] = arguments[_key];
    }

    var uploader = owner.lookup('service:uploader');
    var queue = uploader.findOrCreate(name);

    Ember.assert('To add a file, you must have queue with the name=\'' + name + '\'', queue);

    var plupload = queue.get('queues.lastObject');
    files = files.map(function (file) {
      return new FakeFile(Ember.merge({ queue: queue, plupload: plupload, queueName: name }, file));
    });

    Ember.run(function () {
      queue.filesAdded(plupload, files);
    });

    return files;
  }
});