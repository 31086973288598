define("ui-library/components/p-filter-collection-with-checkboxes/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "i8peusjP",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"title\"]]],null,{\"statements\":[[0,\"  \"],[7,\"h4\",true],[10,\"data-test-filter-collection-with-checkboxes-title\",\"\"],[8],[1,[22,\"title\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"ul\",true],[8],[0,\"\\n  \"],[14,1,[[28,\"hash\",null,[[\"item\"],[[28,\"component\",[\"p-filter-collection-item\"],[[\"onToggleSelection\",\"onSelectItem\",\"currentValues\"],[[28,\"action\",[[23,0,[]],\"toggleSelection\"],null],[28,\"action\",[[23,0,[]],\"selectItem\"],null],[24,[\"filter\",\"currentValues\"]]]]]]]]]],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"showApplyButton\"]]],null,{\"statements\":[[0,\"  \"],[7,\"hr\",true],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"apply-buttons\"],[8],[0,\"\\n\"],[4,\"p-button\",null,[[\"type\",\"size\",\"click\",\"data-test-filter-collection-with-checkboxes-cancel\"],[\"outline\",\"small\",[28,\"action\",[[23,0,[]],\"cancel\"],null],1]],{\"statements\":[[0,\"      \"],[1,[28,\"t\",[\"pFilterCollectionWithCheckboxes.cancel\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"p-button\",null,[[\"color\",\"size\",\"click\",\"data-test-filter-collection-with-checkboxes-apply\"],[\"secondary\",\"small\",[28,\"action\",[[23,0,[]],\"apply\"],null],1]],{\"statements\":[[0,\"      \"],[1,[28,\"t\",[\"pFilterCollectionWithCheckboxes.apply\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-library/components/p-filter-collection-with-checkboxes/template.hbs"
    }
  });

  _exports.default = _default;
});