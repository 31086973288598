define("ui-library/components/p-calendar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pEI+xwGn",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"pikaday-inputless\",null,[[\"value\",\"format\",\"yearRange\",\"disabled\",\"readonly\",\"minDate\",\"maxDate\",\"onSelection\",\"onDraw\"],[[24,[\"_value\"]],[24,[\"format\"]],[24,[\"yearRange\"]],[24,[\"disabled\"]],[24,[\"readonly\"]],[24,[\"_minDate\"]],[24,[\"_maxDate\"]],[28,\"action\",[[23,0,[]],\"select\"],null],[28,\"action\",[[23,0,[]],\"draw\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-library/components/p-calendar/template.hbs"
    }
  });

  _exports.default = _default;
});