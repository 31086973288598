define('ember-plupload/computed/w', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (defaultValue) {
    defaultValue = defaultValue || [];
    return computed({
      get: function get() {
        return toArray(defaultValue);
      },
      set: function set(key, value) {
        return toArray(value);
      }
    });
  };

  var w = Ember.String.w;
  var computed = Ember.computed;

  var toArray = function toArray(value) {
    if (typeof value === 'string') {
      value = w(value);
    }
    return Ember.A(value);
  };
});