define("ui-library/components/p-multiple-select-with-checkboxes/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AFEzeMLS",
    "block": "{\"symbols\":[\"dropdown\",\"&default\"],\"statements\":[[4,\"basic-dropdown\",null,[[\"initiallyOpened\",\"registerAPI\",\"onOpen\",\"onClose\"],[[24,[\"isOpen\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"dropdownRemoteController\"]]],null]],null],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"isOpen\"]]],null],true],null],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"isOpen\"]]],null],false],null]]],{\"statements\":[[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"trigger\"]],\"expected `dropdown.trigger` to be a contextual component but found a string. Did you mean `(component dropdown.trigger)`? ('ui-library/components/p-multiple-select-with-checkboxes/template.hbs' @ L2:C5) \"],null]],null,{\"statements\":[[0,\"    \"],[1,[22,\"triggerText\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"content\"]],\"expected `dropdown.content` to be a contextual component but found a string. Did you mean `(component dropdown.content)`? ('ui-library/components/p-multiple-select-with-checkboxes/template.hbs' @ L5:C5) \"],null]],[[\"class\"],[[24,[\"dropdownContentClass\"]]]],{\"statements\":[[0,\"    \"],[7,\"ul\",true],[8],[0,\"\\n      \"],[14,2,[[28,\"hash\",null,[[\"item\"],[[28,\"component\",[\"p-multiple-select-with-checkboxes-item\"],[[\"onToggleSelection\",\"selectedValues\"],[[28,\"action\",[[23,0,[]],\"toggleSelection\"],null],[24,[\"selectedValues\"]]]]]]]]]],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-library/components/p-multiple-select-with-checkboxes/template.hbs"
    }
  });

  _exports.default = _default;
});