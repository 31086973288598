define('ui-library/components/p-pagination-info/component', ['exports', 'ui-library/components/p-pagination-info/template', 'ember-decorators/object'], function (exports, _template, _object) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _desc, _value, _obj;

  exports.default = Ember.Component.extend((_dec = (0, _object.computed)('currentPage', 'perPage'), _dec2 = (0, _object.computed)('currentPage', 'totalCount', 'perPage'), (_obj = {
    layout: _template.default,

    meta: null,

    totalPages: Ember.computed.alias('meta.totalPages'),
    totalCount: Ember.computed.alias('meta.totalCount'),
    currentPage: Ember.computed.alias('meta.currentPage'),
    perPage: Ember.computed.alias('meta.perPage'),

    rangeStart: function rangeStart(currentPage, perPage) {
      return (currentPage - 1) * perPage + 1;
    },
    rangeEnd: function rangeEnd(currentPage, totalCount, perPage) {
      var end = currentPage * perPage;

      return end > totalCount ? totalCount : end;
    }
  }, (_applyDecoratedDescriptor(_obj, 'rangeStart', [_dec], Object.getOwnPropertyDescriptor(_obj, 'rangeStart'), _obj), _applyDecoratedDescriptor(_obj, 'rangeEnd', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'rangeEnd'), _obj)), _obj)));
});