define('ui-library/utils/array-prev-next-navigator', ['exports', 'ember-decorators/object'], function (exports, _object) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _desc, _value, _obj, _init;

  var ArrayPrevNextNavigator = Ember.Object.extend((_obj = {
    _currentIndex: -1,
    currentItem: null,

    observable: null,
    bindingKey: null,
    observerKey: null,

    currentIndex: {
      get: function get() {
        return this.get('_currentIndex');
      },
      set: function set(value) {
        var items = this._readItems();
        var currentIndex = this.get('_currentIndex');
        var newIndex = value;
        if (value != null && value !== currentIndex && value >= 0 && value < items.length) {
          var _items = this._readItems();
          this.setProperties({
            _currentIndex: value,
            currentItem: value < 0 ? null : _items[value]
          });
        } else if (items.length == 0) {
          this.setProperties({
            _currentIndex: -1,
            currentItem: null
          });
        } else {
          newIndex = currentIndex;
        }
        return newIndex;
      }
    },

    bind: function bind(observable, bindingKey) {
      var observerKey = bindingKey + '.[]';
      this.setProperties({ observable: observable, bindingKey: bindingKey, observerKey: observerKey });
      this._adjustCurrentIndex();

      observable.addObserver(observerKey, this, this._adjustCurrentIndex);
    },
    unbind: function unbind() {
      var observable = this.get('observable');
      observable.removeObserver(this.get('observerKey'), this, this._adjustCurrentIndex);
    },
    prev: function prev() {
      var currentIndex = this.get('currentIndex');
      this.set('currentIndex', Math.max(0, currentIndex - 1));
    },
    next: function next() {
      var itemCount = this._readItems().length;
      var currentIndex = this.get('currentIndex');
      this.set('currentIndex', Math.min(itemCount - 1, currentIndex + 1));
    },
    _readItems: function _readItems() {
      var observable = this.get('observable');
      var bindingKey = this.get('bindingKey');
      return Ember.get(observable, bindingKey) || [];
    },
    _adjustCurrentIndex: function _adjustCurrentIndex() {
      var _this = this;

      Ember.run.once(function () {
        var items = _this._readItems();
        var currentIndex = _this.get('currentIndex');
        var indexOfCurrentItem = items.indexOf(_this.get('currentItem'));
        if (indexOfCurrentItem >= 0) {
          _this.set('currentIndex', indexOfCurrentItem);
        } else if (currentIndex >= items.length) {
          _this.set('currentIndex', items.length - 1);
        } else if (currentIndex < 0 && items.length > 0) {
          _this.set('currentIndex', 0);
        } else {
          _this.set('currentItem', items[currentIndex]);
        }
      });
    }
  }, (_applyDecoratedDescriptor(_obj, 'currentIndex', [_object.computed], (_init = Object.getOwnPropertyDescriptor(_obj, 'currentIndex'), _init = _init ? _init.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init;
    }
  }), _obj)), _obj));

  exports.default = ArrayPrevNextNavigator;
});