define("ui-library/components/p-list-aggregates/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wCK7sQqo",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"span\",true],[10,\"data-test-record-count\",\"\"],[8],[1,[28,\"t\",[\"pListAggregates.recordCount\"],[[\"count\"],[[24,[\"recordCount\"]]]]],false],[9],[0,\"\\n\"],[14,1,[[28,\"hash\",null,[[\"tally\"],[[28,\"component\",[\"p-list-tally\"],null]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-library/components/p-list-aggregates/template.hbs"
    }
  });

  _exports.default = _default;
});