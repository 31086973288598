define('ui-library/components/p-phoenix-card/component', ['exports', 'ui-library/components/p-phoenix-card/template', 'ember-decorators/object', 'ember-prop-types', 'ui-library/helpers/is-asist'], function (exports, _template, _object, _emberPropTypes, _isAsist) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _dec3, _dec4, _dec5, _desc, _value, _obj;

  var SHADOW = ['hidden', 'visible'],
      OVERFLOW = ['hidden', 'visible', 'scroll', 'auto', 'revert', 'unset'],
      RADIUS = ['top-left-x', 'top-left-2x', 'top-left-3x', 'top-left-4x', 'top-right-x', 'top-right-2x', 'top-right-3x', 'top-right-4x', 'bottom-left-x', 'bottom-left-2x', 'bottom-left-3x', 'bottom-left-4x', 'bottom-right-x', 'bottom-right-2x', 'bottom-right-3x', 'bottom-right-4x', 'left-x', 'left-2x', 'left-3x', 'left-4x', 'right-x', 'right-2x', 'right-3x', 'right-4x', 'bottom-x', 'bottom-2x', 'bottom-3x', 'bottom-4x', 'top-x', 'top-2x', 'top-3x', 'top-4x', 'full-x', 'full-2x', 'full-3x', 'full-4x'],
      BORDER_TYPES = ['none', 'hidden', 'dotted', 'dashed', 'solid', 'double', 'groove', 'ridge', 'inset', 'outset'],
      BACKGROUND_COLORS = ['white', 'orange-gradient', 'red-gradient', 'blue-gradient', 'grey', 'turquoise', 'mustard'];

  exports.default = Ember.Component.extend(_emberPropTypes.default, (_dec = (0, _object.computed)('backgroundColor'), _dec2 = (0, _object.computed)('borderType'), _dec3 = (0, _object.computed)('radius'), _dec4 = (0, _object.computed)('shadow'), _dec5 = (0, _object.computed)('overflow'), (_obj = {
    propTypes: {
      radius: _emberPropTypes.PropTypes.string,
      borderType: _emberPropTypes.PropTypes.string,
      backgroundColor: _emberPropTypes.PropTypes.string,
      shadow: _emberPropTypes.PropTypes.string,
      overflow: _emberPropTypes.PropTypes.string
    },

    classNameBindings: ['backgroundColorClass', 'radiusClass', 'borderTypeClass', 'radiusClass', 'shadowClass', 'overflowClass'],
    layout: _template.default,
    tagName: 'div',
    'data-test-p-phoenix-card': true,

    getDefaultProps: function getDefaultProps() {
      return {
        borderType: 'solid',
        backgroundColor: 'white',
        radius: 'full-x',
        shadow: 'hidden',
        overflow: 'hidden'
      };
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
    },
    backgroundColorClass: function backgroundColorClass(backgroundColor) {
      if ((0, _isAsist.isAsist)() && backgroundColor !== 'white') {
        return 'backgroundColor-asist';
      }
      (false && !(BACKGROUND_COLORS.includes(backgroundColor)) && Ember.assert('invalid value for backgroundColor', BACKGROUND_COLORS.includes(backgroundColor)));

      return 'backgroundColor-' + backgroundColor;
    },
    borderTypeClass: function borderTypeClass(borderType) {
      (false && !(BORDER_TYPES.includes(borderType)) && Ember.assert('invalid value for borderType', BORDER_TYPES.includes(borderType)));

      return 'borderType-' + borderType;
    },
    radiusClass: function radiusClass(radius) {
      (false && !(RADIUS.includes(radius)) && Ember.assert('invalid value for radius', RADIUS.includes(radius)));

      return 'radius-' + radius;
    },
    shadowClass: function shadowClass(shadow) {
      (false && !(SHADOW.includes(shadow)) && Ember.assert('invalid value for shadow', SHADOW.includes(shadow)));

      return 'shadow-' + shadow;
    },
    overflowClass: function overflowClass(overflow) {
      (false && !(OVERFLOW.includes(overflow)) && Ember.assert('invalid value for overflow', OVERFLOW.includes(overflow)));

      return 'overflow-' + overflow;
    }
  }, (_applyDecoratedDescriptor(_obj, 'backgroundColorClass', [_dec], Object.getOwnPropertyDescriptor(_obj, 'backgroundColorClass'), _obj), _applyDecoratedDescriptor(_obj, 'borderTypeClass', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'borderTypeClass'), _obj), _applyDecoratedDescriptor(_obj, 'radiusClass', [_dec3], Object.getOwnPropertyDescriptor(_obj, 'radiusClass'), _obj), _applyDecoratedDescriptor(_obj, 'shadowClass', [_dec4], Object.getOwnPropertyDescriptor(_obj, 'shadowClass'), _obj), _applyDecoratedDescriptor(_obj, 'overflowClass', [_dec5], Object.getOwnPropertyDescriptor(_obj, 'overflowClass'), _obj)), _obj)));
});