define("ui-library/components/p-detail-card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "M2nVOkuq",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"p-phoenix-card\",null,[[\"shadow\"],[\"hidden\"]],{\"statements\":[[0,\"  \"],[14,1,[[28,\"hash\",null,[[\"header\",\"body\"],[[28,\"component\",[\"p-card-header\"],null],[28,\"component\",[\"p-card-body\"],null]]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-library/components/p-detail-card/template.hbs"
    }
  });

  _exports.default = _default;
});