define('ui-library/components/p-filter-collection-with-date/component', ['exports', 'ui-library/components/p-filter-collection-with-date/template', 'ember-decorators/object', 'moment'], function (exports, _template, _object, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _dec3, _desc, _value, _obj;

  exports.default = Ember.Component.extend((_dec = (0, _object.computed)('filter.dateRange.from'), _dec2 = (0, _object.computed)('filter.dateRange.to'), _dec3 = (0, _object.computed)('showCustomDate', 'isCustomDate'), (_obj = {
    layout: _template.default,

    title: null,
    filter: null,
    picker: 'month', // month or range
    onApplyFilters: null,
    'data-test-filter-collection-with-date': true,

    fromDate: function fromDate(_fromDate) {
      return _fromDate || (0, _moment.default)().startOf('month');
    },
    toDate: function toDate(_toDate) {
      return _toDate || (0, _moment.default)().endOf('month');
    },
    isCustomDateOpen: function isCustomDateOpen(showCustomDate, isCustomDate) {
      return Ember.isEmpty(showCustomDate) ? isCustomDate : showCustomDate;
    },
    applyDate: function applyDate(fromDate, toDate) {
      if (this.get('filter')) {
        this.get('filter').selectValue([fromDate.format('YYYY-MM-DD'), toDate.format('YYYY-MM-DD')]);
      }
      if (this.get('onApplyFilters')) {
        this.get('onApplyFilters')();
      }
    }
  }, (_applyDecoratedDescriptor(_obj, 'fromDate', [_dec], Object.getOwnPropertyDescriptor(_obj, 'fromDate'), _obj), _applyDecoratedDescriptor(_obj, 'toDate', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'toDate'), _obj), _applyDecoratedDescriptor(_obj, 'isCustomDateOpen', [_dec3], Object.getOwnPropertyDescriptor(_obj, 'isCustomDateOpen'), _obj), _applyDecoratedDescriptor(_obj, 'applyDate', [_object.action], Object.getOwnPropertyDescriptor(_obj, 'applyDate'), _obj)), _obj)));
});