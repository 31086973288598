define('ui-library/components/p-date-range-picker/component', ['exports', 'ui-library/components/p-date-range-picker/template', 'moment', 'ember-decorators/object', 'ui-library/utils/date-range-util', 'ui-library/helpers/is-phoenix', 'ui-library/utils/string-util'], function (exports, _template, _moment, _object, _dateRangeUtil, _isPhoenix, _stringUtil) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _desc, _value, _obj;

  var BACKGROUND_COLORS = ['gray', 'white'];

  exports.default = Ember.Component.extend((_dec = (0, _object.computed)('backgroundColor'), _dec2 = (0, _object.computed)('usingPhoenix'), _dec3 = (0, _object.computed)('usingPhoenix'), _dec4 = (0, _object.computed)('usingPhoenix'), _dec5 = (0, _object.computed)('usingPhoenix'), _dec6 = (0, _object.computed)('usingPhoenix'), _dec7 = (0, _object.computed)('usingPhoenix'), _dec8 = (0, _object.computed)('usingPhoenix'), _dec9 = (0, _object.computed)('usingPhoenix'), (_obj = {
    layout: _template.default,
    i18n: Ember.inject.service(),

    onApply: null,
    onCancel: null,
    fromDate: null,
    _fromDate: null,
    toDate: null,
    _toDate: null,
    'data-test-date-range-picker': true,
    backgroundColor: 'white',

    usingPhoenix: (0, _isPhoenix.isPhoenix)(),

    classNameBindings: ['backgroundColorClass'],

    _getLowerCase: function _getLowerCase(locale, isPhoenix) {
      if (isPhoenix) {
        return _stringUtil.default.toLower(this.get('i18n').t(locale).string).split(' ').map(function (i) {
          return i.capitalize(i);
        }).join(' ');
      }
      return this.get('i18n').t(locale);
    },
    backgroundColorClass: function backgroundColorClass(backgroundColor) {
      (false && !(BACKGROUND_COLORS.includes(backgroundColor)) && Ember.assert('invalid value for backgroundColor', BACKGROUND_COLORS.includes(backgroundColor)));

      return 'backgroundColor-' + backgroundColor;
    },
    textThisMonth: function textThisMonth(isPhoenix) {
      return this._getLowerCase('pDateRangePicker.thisMonth', isPhoenix);
    },
    textPreviousMonth: function textPreviousMonth(isPhoenix) {
      return this._getLowerCase('pDateRangePicker.previousMonth', isPhoenix);
    },
    textThisQuarter: function textThisQuarter(isPhoenix) {
      return this._getLowerCase('pDateRangePicker.thisQuarter', isPhoenix);
    },
    textPreviousQuarter: function textPreviousQuarter(isPhoenix) {
      return this._getLowerCase('pDateRangePicker.previousQuarter', isPhoenix);
    },
    textThisYear: function textThisYear(isPhoenix) {
      return this._getLowerCase('pDateRangePicker.thisYear', isPhoenix);
    },
    textPreviousYear: function textPreviousYear(isPhoenix) {
      return this._getLowerCase('pDateRangePicker.previousYear', isPhoenix);
    },
    textApply: function textApply(isPhoenix) {
      return this._getLowerCase('pDateRangePicker.apply', isPhoenix);
    },
    textCancel: function textCancel(isPhoenix) {
      return this._getLowerCase('pDateRangePicker.cancel', isPhoenix);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this.setInternalDates();
    },
    setInternalDates: function setInternalDates() {
      this.setProperties({
        _fromDate: this.get('fromDate').clone(),
        _toDate: this.get('toDate').clone()
      });
    },
    setDateRangeTo: function setDateRangeTo(range) {
      var rangeResult = _dateRangeUtil.default.fromPreset(range);

      this.setProperties({
        _fromDate: rangeResult.from,
        _toDate: rangeResult.to
      });
    },
    selectFromDate: function selectFromDate(selectedDate) {
      var from = (0, _moment.default)(selectedDate);
      this.set('_fromDate', from);
      if (this.get('_fromDate').isAfter(this.get('_toDate'))) {
        this.set('_toDate', this.get('_fromDate'));
      }
    },
    selectToDate: function selectToDate(selectedDate) {
      var to = (0, _moment.default)(selectedDate);
      this.set('_toDate', to);
      if (this.get('_fromDate').isAfter(this.get('_toDate'))) {
        this.set('_fromDate', this.get('_toDate'));
      }
    },
    apply: function apply() {
      if (this.get('onApply')) {
        this.get('onApply')(this.get('_fromDate'), this.get('_toDate'));
      }
    },
    cancel: function cancel() {
      if (this.get('onCancel')) {
        this.get('onCancel')();
        this.setInternalDates();
      }
    }
  }, (_applyDecoratedDescriptor(_obj, 'backgroundColorClass', [_dec], Object.getOwnPropertyDescriptor(_obj, 'backgroundColorClass'), _obj), _applyDecoratedDescriptor(_obj, 'textThisMonth', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'textThisMonth'), _obj), _applyDecoratedDescriptor(_obj, 'textPreviousMonth', [_dec3], Object.getOwnPropertyDescriptor(_obj, 'textPreviousMonth'), _obj), _applyDecoratedDescriptor(_obj, 'textThisQuarter', [_dec4], Object.getOwnPropertyDescriptor(_obj, 'textThisQuarter'), _obj), _applyDecoratedDescriptor(_obj, 'textPreviousQuarter', [_dec5], Object.getOwnPropertyDescriptor(_obj, 'textPreviousQuarter'), _obj), _applyDecoratedDescriptor(_obj, 'textThisYear', [_dec6], Object.getOwnPropertyDescriptor(_obj, 'textThisYear'), _obj), _applyDecoratedDescriptor(_obj, 'textPreviousYear', [_dec7], Object.getOwnPropertyDescriptor(_obj, 'textPreviousYear'), _obj), _applyDecoratedDescriptor(_obj, 'textApply', [_dec8], Object.getOwnPropertyDescriptor(_obj, 'textApply'), _obj), _applyDecoratedDescriptor(_obj, 'textCancel', [_dec9], Object.getOwnPropertyDescriptor(_obj, 'textCancel'), _obj), _applyDecoratedDescriptor(_obj, 'setDateRangeTo', [_object.action], Object.getOwnPropertyDescriptor(_obj, 'setDateRangeTo'), _obj), _applyDecoratedDescriptor(_obj, 'selectFromDate', [_object.action], Object.getOwnPropertyDescriptor(_obj, 'selectFromDate'), _obj), _applyDecoratedDescriptor(_obj, 'selectToDate', [_object.action], Object.getOwnPropertyDescriptor(_obj, 'selectToDate'), _obj), _applyDecoratedDescriptor(_obj, 'apply', [_object.action], Object.getOwnPropertyDescriptor(_obj, 'apply'), _obj), _applyDecoratedDescriptor(_obj, 'cancel', [_object.action], Object.getOwnPropertyDescriptor(_obj, 'cancel'), _obj)), _obj)));
});