define("ui-library/components/p-list-header-column/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zjgpM/1U",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"sortable\"]]],null,{\"statements\":[[4,\"link-to\",null,[[\"class\",\"query\",\"route\"],[[28,\"if\",[[24,[\"isListSortedByThisColumn\"]],\"isSorted\"],null],[28,\"hash\",null,[[\"siralama\",\"yon\",\"sayfa\"],[[24,[\"column\"]],[24,[\"newSortDir\"]],1]]],[24,[\"route\"]]]],{\"statements\":[[0,\"    \"],[14,1],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"isListSortedByThisColumn\"]]],null,{\"statements\":[[0,\"      \"],[7,\"i\",true],[11,\"class\",[22,\"iconClass\"]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-library/components/p-list-header-column/template.hbs"
    }
  });

  _exports.default = _default;
});