define("@ember/legacy-built-in-components/components/_has-dom", ["exports", "@babel/runtime/helpers/esm/typeof"], function (_exports, _typeof2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // check if window exists and actually is the global
  var _default = (typeof self === "undefined" ? "undefined" : (0, _typeof2.default)(self)) === 'object' && self !== null && self.Object === Object && typeof Window !== 'undefined' && self.constructor === Window && (typeof document === "undefined" ? "undefined" : (0, _typeof2.default)(document)) === 'object' && document !== null && self.document === document && (typeof location === "undefined" ? "undefined" : (0, _typeof2.default)(location)) === 'object' && location !== null && self.location === location && (typeof history === "undefined" ? "undefined" : (0, _typeof2.default)(history)) === 'object' && history !== null && self.history === history && (typeof navigator === "undefined" ? "undefined" : (0, _typeof2.default)(navigator)) === 'object' && navigator !== null && self.navigator === navigator && typeof navigator.userAgent === 'string';

  _exports.default = _default;
});