define('ui-library/components/p-table-body/component', ['exports', 'ui-library/components/p-table-body/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    'data-test-table-body': true,
    rows: Ember.A(),
    columnWidths: Ember.A()
  });
});