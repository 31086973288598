define('ui-library/components/p-one-way-numeric-with-vat/component', ['exports', 'ui-library/components/p-one-way-numeric-with-vat/template', 'ember-decorators/object', 'ember-prop-types'], function (exports, _template, _object, _emberPropTypes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _desc, _value, _obj;

  var VAT_OPTIONS = [18, 8, 1, 0];

  exports.default = Ember.Component.extend((_dec = (0, _object.computed)('vat'), (_obj = {
    layout: _template.default,

    propTypes: {
      number: _emberPropTypes.PropTypes.number.isRequired,
      vat: _emberPropTypes.PropTypes.number.isRequired,
      numberupdate: _emberPropTypes.PropTypes.func.isRequired,
      vatupdate: _emberPropTypes.PropTypes.func.isRequired
    },

    'data-test-one-way-numeric-with-vat': true,

    vatOptions: VAT_OPTIONS,
    isOpen: false,

    append: function append(vat) {
      if (VAT_OPTIONS.includes(vat)) {
        return '%' + vat;
      }
    },
    toggleDropdown: function toggleDropdown() {
      this.toggleProperty('isOpen');
    },
    changeVat: function changeVat(vat) {
      this.set('isOpen', false);
      this.get('vatupdate')(vat);
    },
    changeNumber: function changeNumber(number) {
      this.get('numberupdate')(number);
    }
  }, (_applyDecoratedDescriptor(_obj, 'append', [_dec], Object.getOwnPropertyDescriptor(_obj, 'append'), _obj), _applyDecoratedDescriptor(_obj, 'toggleDropdown', [_object.action], Object.getOwnPropertyDescriptor(_obj, 'toggleDropdown'), _obj), _applyDecoratedDescriptor(_obj, 'changeVat', [_object.action], Object.getOwnPropertyDescriptor(_obj, 'changeVat'), _obj), _applyDecoratedDescriptor(_obj, 'changeNumber', [_object.action], Object.getOwnPropertyDescriptor(_obj, 'changeNumber'), _obj)), _obj)));
});