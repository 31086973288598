define('ui-library/components/p-date-picker-with-presets/component', ['exports', 'ui-library/components/p-date-picker-with-presets/template', 'ember-decorators/object', 'moment'], function (exports, _template, _object, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _desc, _value, _obj;

  exports.default = Ember.Component.extend((_dec = (0, _object.computed)('baseDate'), _dec2 = (0, _object.computed)('baseDate', 'value'), (_obj = {
    layout: _template.default,
    baseDate: null,
    value: null,
    update: null,
    allowUnknown: false,
    displayShortcuts: false,
    'data-test-p-date-picker-with-presets': true,

    _presetsDisabled: function _presetsDisabled(baseDate) {
      return baseDate == null;
    },
    _dayDiff: function _dayDiff(baseDate, value) {
      if (baseDate == null || value == null) {
        return null;
      }

      var baseDateBeginning = (0, _moment.default)(baseDate).startOf('day');
      var valueBeginning = (0, _moment.default)(value).startOf('day');
      return valueBeginning.diff(baseDateBeginning, 'days');
    },
    _add: function _add(days) {
      var newDate = (0, _moment.default)(this.get('baseDate')).add(days, 'days').toDate();
      this._sendUpdate(newDate);
    },
    _sendUpdate: function _sendUpdate(newValue) {
      if (this.get('update') != null) {
        this.get('update')(newValue);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, '_presetsDisabled', [_dec], Object.getOwnPropertyDescriptor(_obj, '_presetsDisabled'), _obj), _applyDecoratedDescriptor(_obj, '_dayDiff', [_dec2], Object.getOwnPropertyDescriptor(_obj, '_dayDiff'), _obj), _applyDecoratedDescriptor(_obj, '_add', [_object.action], Object.getOwnPropertyDescriptor(_obj, '_add'), _obj), _applyDecoratedDescriptor(_obj, '_sendUpdate', [_object.action], Object.getOwnPropertyDescriptor(_obj, '_sendUpdate'), _obj)), _obj)));
});