define("ui-library/components/p-month-picker/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FI7FLr1h",
    "block": "{\"symbols\":[\"optionItem\"],\"statements\":[[7,\"li\",true],[8],[0,\"\\n  \"],[7,\"a\",false],[12,\"href\",\"#\"],[12,\"data-test-month-picker-load-previous\",\"\"],[3,\"action\",[[23,0,[]],\"loadPrevious\"]],[8],[0,\"\\n    \"],[7,\"span\",true],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"pMonthPicker.loadPrevious\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"each\",[[24,[\"monthOptions\"]]],null,{\"statements\":[[0,\"  \"],[7,\"li\",true],[8],[0,\"\\n    \"],[7,\"a\",false],[12,\"href\",\"#\"],[12,\"data-test-month-picker-month-options\",\"\"],[12,\"data-tid\",\"month\"],[3,\"action\",[[23,0,[]],\"selectItem\",[23,1,[]]]],[8],[0,\"\\n      \"],[1,[23,1,[\"displayName\"]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"],[7,\"li\",true],[8],[0,\"\\n  \"],[7,\"a\",false],[12,\"href\",\"#\"],[12,\"data-test-month-picker-load-next\",\"\"],[3,\"action\",[[23,0,[]],\"loadNext\"]],[8],[0,\"\\n    \"],[7,\"span\",true],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"pMonthPicker.loadNext\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-library/components/p-month-picker/template.hbs"
    }
  });

  _exports.default = _default;
});