define('ui-library/components/p-list-header-column/component', ['exports', 'ember-decorators/object', 'ember-prop-types', 'ui-library/components/p-list-header-column/template'], function (exports, _object, _emberPropTypes, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _desc, _value, _obj;

  exports.default = Ember.Component.extend(_emberPropTypes.default, (_dec = (0, _object.computed)('currentSortBy', 'column'), _dec2 = (0, _object.computed)('isDefaultSortColumn', 'currentSortBy'), _dec3 = (0, _object.computed)('isSortedByUser', 'isSortedByDefault'), _dec4 = (0, _object.computed)('isSortedByUser', 'isSortedByDefault', 'currentSort.sortDir', 'defaultSortDir'), _dec5 = (0, _object.computed)('currentSortDir', 'defaultSortDir'), _dec6 = (0, _object.computed)('type'), _dec7 = (0, _object.computed)('align'), _dec8 = (0, _object.computed)('currentSortDir', 'type', 'isListSortedByThisColumn'), (_obj = {
    layout: _template.default,
    tagName: 'th',

    propTypes: {
      column: _emberPropTypes.PropTypes.string,
      type: _emberPropTypes.PropTypes.oneOf(['amount', 'numeric', 'alpha']),
      // TODO: We could remove null from align if we had a proper default
      align: _emberPropTypes.PropTypes.oneOf([null, 'left', 'right']),
      sortable: _emberPropTypes.PropTypes.bool,
      defaultSortDir: _emberPropTypes.PropTypes.oneOf(['asc', 'desc']),
      isDefaultSortColumn: _emberPropTypes.PropTypes.bool,

      route: _emberPropTypes.PropTypes.string,
      currentSort: _emberPropTypes.PropTypes.EmberObject
    },
    getDefaultProps: function getDefaultProps() {
      return {
        type: 'amount',
        // TODO: null is default to preserve current column behaviour. How about center? justify?
        align: null,
        defaultSortDir: 'asc',
        isDefaultSortColumn: false,
        sortable: true
      };
    },


    currentSortBy: Ember.computed.alias('currentSort.sortBy'),
    classNameBindings: ['typeClass', 'alignClass'],

    isSortedByUser: function isSortedByUser(currentSortBy, column) {
      return column && currentSortBy === column;
    },
    isSortedByDefault: function isSortedByDefault(isDefaultSortColumn, currentSortBy) {
      return !currentSortBy && isDefaultSortColumn;
    },
    isListSortedByThisColumn: function isListSortedByThisColumn(isSortedByUser, isSortedByDefault) {
      return isSortedByUser || isSortedByDefault;
    },
    currentSortDir: function currentSortDir(isSortedByUser, isSortedByDefault, _currentSortDir, defaultSortDir) {
      if (isSortedByUser) {
        return _currentSortDir;
      } else if (isSortedByDefault) {
        return defaultSortDir;
      }

      return null;
    },
    newSortDir: function newSortDir(currentSortDir, defaultSortDir) {
      if (!currentSortDir) {
        return defaultSortDir;
      }

      return currentSortDir === 'desc' ? 'asc' : 'desc';
    },
    typeClass: function typeClass(type) {
      return 'type-' + type;
    },
    alignClass: function alignClass(align) {
      if (!align) {
        return '';
      }

      return 'align-' + align;
    },
    iconClass: function iconClass(currentSortDir, type, isListSortedByThisColumn) {
      if (!isListSortedByThisColumn) {
        return '';
      }

      // Computed icon classes:
      // fa fa-sort-amount-asc
      // fa fa-sort-amount-desc
      // fa fa-sort-alpha-asc
      // fa fa-sort-alpha-desc
      // fa fa-sort-numeric-asc
      // fa fa-sort-numeric-desc
      return 'fa fa-sort-' + type + '-' + currentSortDir;
    }
  }, (_applyDecoratedDescriptor(_obj, 'isSortedByUser', [_dec], Object.getOwnPropertyDescriptor(_obj, 'isSortedByUser'), _obj), _applyDecoratedDescriptor(_obj, 'isSortedByDefault', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'isSortedByDefault'), _obj), _applyDecoratedDescriptor(_obj, 'isListSortedByThisColumn', [_dec3], Object.getOwnPropertyDescriptor(_obj, 'isListSortedByThisColumn'), _obj), _applyDecoratedDescriptor(_obj, 'currentSortDir', [_dec4], Object.getOwnPropertyDescriptor(_obj, 'currentSortDir'), _obj), _applyDecoratedDescriptor(_obj, 'newSortDir', [_dec5], Object.getOwnPropertyDescriptor(_obj, 'newSortDir'), _obj), _applyDecoratedDescriptor(_obj, 'typeClass', [_dec6], Object.getOwnPropertyDescriptor(_obj, 'typeClass'), _obj), _applyDecoratedDescriptor(_obj, 'alignClass', [_dec7], Object.getOwnPropertyDescriptor(_obj, 'alignClass'), _obj), _applyDecoratedDescriptor(_obj, 'iconClass', [_dec8], Object.getOwnPropertyDescriptor(_obj, 'iconClass'), _obj)), _obj)));
});