define('ui-library/components/p-one-way-numeric/component', ['exports', 'ui-library/utils/number-util', 'ember-one-way-controls/components/one-way-input', 'ember-decorators/object'], function (exports, _numberUtil, _oneWayInput, _object) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _desc, _value, _obj, _init;

  var DATA_TYPES = ['int', 'float', 'decimal2', 'decimal4', 'raw'];

  var ZERO = 48;
  var NINE = 57;
  var COMMA = 188;
  // ascii codes for numeric keypads
  var NUMERIC_KEYPAD_ZERO = 96;
  var NUMERIC_KEYPAD_NINE = 105;
  var NUMERIC_KEYPAD_COMMA = 110;

  var BACKSPACE = 8;
  var TAB = 9;
  var ENTER = 13;
  var ESC = 27;
  var LEFT_ARROW = 37;
  var UP_ARROW = 38;
  var RIGHT_ARROW = 39;
  var DOWN_ARROW = 40;
  var DELETE = 46;
  var SPECIAL_CHARS = [0, BACKSPACE, DELETE, ENTER, ESC, LEFT_ARROW, UP_ARROW, RIGHT_ARROW, DOWN_ARROW, TAB];

  var SUBTRACT = 109;
  var DASH = 189;

  exports.default = _oneWayInput.default.extend((_dec = (0, _object.computed)('dataType'), _dec2 = (0, _object.computed)('_number'), (_obj = {
    dataType: 'decimal2',
    floatPrecision: 2, // number or 'variable'
    allowNegative: false,
    variablePrecision: Ember.computed.equal('floatPrecision', 'variable'),
    numberupdate: null,
    _number: null,
    canBeEmpty: false, allowDecimals: function allowDecimals(dataType) {
      return dataType !== 'int' && dataType !== 'raw';
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this.set('update', function (value) {
        var currentNumber = _this.get('_number');
        var newNumber = _this._parseNumber(value);
        // send the event only when the parsed number changes
        // if the user enters and invalid number, the parsed number does not change
        // and there is not reason to notify the parent
        // the input value will be properly formatted when the user focuses out of the input
        if (newNumber != currentNumber) {
          if (_this.get('numberupdate')) {
            _this.get('numberupdate')(newNumber);
          }
        }

        // set the input value to whatever the user entered,
        // otherwise the cursor loses it's position
        _this.set('value', value);
      });
    },

    number: {
      get: function get(currentNumber) {
        return currentNumber;
      },
      set: function set(newNumber, currentNumber) {
        if (typeof newNumber === 'number') {
          var currentInputNumber = this._parseNumber(this.get('value'));

          // update the input value only if the newNumber is different from the parsed number
          //
          // if newNumber is set from the parent
          // (ie user is not typing or sets a different number other than the numberupdate parameter),
          // this will set input value to the formatted number
          //
          // if the user is typing and the parsed number changes, parent is notified.
          // At this point if the parent does not change the number and assigns it back to this
          // component, we do not change the value (since parsed number is same).
          // Without this check, the value would be reassigned and
          // the cursor would loose it's position.

          if (newNumber !== currentInputNumber || currentNumber == null) {
            this.set('value', this._formatNumber(newNumber));
          }
        } else {
          this.set('value', newNumber);
        }
        this.set('_number', newNumber);
        return newNumber;
      }
    },

    keyDown: function keyDown(e) {
      // allow digits
      if (e.which >= ZERO && e.which <= NINE || e.which >= NUMERIC_KEYPAD_ZERO && e.which <= NUMERIC_KEYPAD_NINE) {
        return true;
      }

      // allow decimals sign when it's allowed
      if (this.get('allowDecimals') && (e.which === COMMA || e.which === NUMERIC_KEYPAD_COMMA)) {
        return true;
      }

      // allow negative sign when it's allowed
      if (this.get('allowNegative') && (e.which === SUBTRACT || e.which === DASH)) {
        return true;
      }

      // allow special characters
      if (SPECIAL_CHARS.indexOf(e.which) !== -1) {
        return true;
      }

      // if ctrl or cmd key is pressed, allow it since it won't change the input value
      if (e.ctrlKey || e.metaKey) {
        return true;
      }

      return false; // prevent any other chars
    },
    focusOut: function focusOut() {
      var value = this.get('value');
      var number = this._parseNumber(value);
      var formatted = this._formatNumber(number);
      if (formatted !== value) {
        this.set('value', formatted);
      }
    },
    _parseNumber: function _parseNumber(str) {
      var canBeEmpty = this.get('canBeEmpty');
      switch (this._findDataType()) {
        case 'int':
        case 'raw':
          return _numberUtil.default.parseFormattedInt(str, canBeEmpty);
        case 'float':
          return _numberUtil.default.parseFormattedFloat(str, this.get('variablePrecision') ? 4 : this.get('floatPrecision'));
        case 'decimal2':
          return _numberUtil.default.parseFormattedDecimal2(str);
        case 'decimal4':
          return _numberUtil.default.parseFormattedDecimal4(str);
      }
    },
    _formatNumber: function _formatNumber(number) {
      switch (this._findDataType()) {
        case 'int':
          return _numberUtil.default.formatInt(number);
        case 'float':
          return _numberUtil.default.formatFloat(number, this._findFloatPrecision(number));
        case 'decimal2':
          return _numberUtil.default.formatDecimal2(number);
        case 'decimal4':
          return _numberUtil.default.formatDecimal4(number);
        case 'raw':
          return number.toString();
      }
    },
    _findDataType: function _findDataType() {
      var dataType = this.get('dataType');
      (false && !(DATA_TYPES.indexOf(dataType) >= 0) && Ember.assert('invalid data type', DATA_TYPES.indexOf(dataType) >= 0));

      return dataType;
    },
    _findFloatPrecision: function _findFloatPrecision(number) {
      var precision = this.get('floatPrecision');
      if (this.get('variablePrecision')) {
        return _numberUtil.default.variablePrecisionForFloat(number);
      }
      (false && !(typeof precision === 'number') && Ember.assert('invalid floatPrecision value', typeof precision === 'number'));

      return precision;
    }
  }, (_applyDecoratedDescriptor(_obj, 'allowDecimals', [_dec], Object.getOwnPropertyDescriptor(_obj, 'allowDecimals'), _obj), _applyDecoratedDescriptor(_obj, 'number', [_dec2], (_init = Object.getOwnPropertyDescriptor(_obj, 'number'), _init = _init ? _init.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init;
    }
  }), _obj)), _obj)));
});