define("ui-library/components/p-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KhVohdtl",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"isOpen\"]]],null,{\"statements\":[[4,\"modal-dialog\",null,[[\"translucentOverlay\",\"onClose\",\"onClickOverlay\",\"containerClassNames\",\"overlayClassNames\",\"wrapperClassNames\"],[true,[24,[\"onClose\"]],[28,\"action\",[[23,0,[]],\"clickOverlay\"],null],[24,[\"containerClassNames\"]],[24,[\"overlayClassNames\"]],[24,[\"wrapperClassNames\"]]]],{\"statements\":[[0,\"    \"],[7,\"div\",true],[11,\"data-test-modal-name\",[22,\"dataTestModalName\"]],[8],[0,\"\\n\"],[4,\"if\",[[28,\"or\",[[28,\"is-asist\",null,null],[28,\"is-phoenix\",null,null]],null]],null,{\"statements\":[[4,\"p-button\",null,[[\"class\",\"action\",\"data-test-close-button-with-x-icon\"],[\"close\",\"clickClose\",true]],{\"statements\":[[0,\"          \"],[7,\"i\",true],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"      \"],[14,1,[[28,\"hash\",null,[[\"header\",\"well\",\"error\",\"body\",\"implications\",\"footer\"],[[28,\"component\",[\"p-modal-header\"],null],[28,\"component\",[\"p-modal-well\"],null],[28,\"component\",[\"p-modal-error\"],null],[28,\"component\",[\"p-modal-body\"],null],[28,\"component\",[\"p-modal-implications\"],null],[28,\"component\",[\"p-modal-footer\"],null]]]]]],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-library/components/p-modal/template.hbs"
    }
  });

  _exports.default = _default;
});