define("ui-library/components/p-pagination-info/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9L0Iq7AO",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"t\",[\"pPaginationInfo.text\"],[[\"totalCount\",\"rangeStart\",\"rangeEnd\"],[[24,[\"totalCount\"]],[24,[\"rangeStart\"]],[24,[\"rangeEnd\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-library/components/p-pagination-info/template.hbs"
    }
  });

  _exports.default = _default;
});