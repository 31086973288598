define("ui-library/components/p-widget-header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "62Z3kqBN",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"icon\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[8],[7,\"i\",true],[11,\"class\",[22,\"iconClass\"]],[8],[9],[9],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"h2\",true],[8],[14,1],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-library/components/p-widget-header/template.hbs"
    }
  });

  _exports.default = _default;
});