define("ember-mask-input/ember-mask-input/component", ["exports", "ember-mask-input/ember-mask-input/masks"], function (_exports, _masks) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'input',
    attributeBindings: ['accept', 'autocomplete', 'autosave', 'dir', 'formaction', 'formenctype', 'formmethod', 'formnovalidate', 'formtarget', 'height', 'inputmode', 'lang', 'list', 'max', 'min', 'multiple', 'name', 'pattern', 'size', 'step', 'type', 'value', 'width', 'autocapitalize', 'autocorrect', 'autofocus', 'disabled', 'form', 'maxlength', 'placeholder', 'readonly', 'required', 'selectionDirection', 'spellcheck', 'tabindex', 'title'],
    selectionStartPosition: 0,
    selectionEndPosition: 0,
    _mask: Ember.computed('maskType', 'mask', function () {
      var mask = null;
      var type = this.get('maskType');

      if (!Ember.isBlank(type)) {
        mask = _masks.MASK_MAPPING[this.get('maskType')];
      }

      if (Ember.isBlank(mask)) {
        return this.get('mask');
      } else {
        return mask;
      }
    }),
    _clearIfNotMatch: Ember.computed('clearIfNotMatch', function () {
      if (Ember.isPresent(this.get('clearIfNotMatch'))) {
        return this.get('clearIfNotMatch');
      }

      return false;
    }),
    _selectOnFocus: Ember.computed('selectOnFocus', function () {
      if (Ember.isPresent(this.get('selectOnFocus'))) {
        return this.get('selectOnFocus');
      }

      return false;
    }),
    valueChangedObserver: Ember.observer('value', function () {
      this.fillUnmaskedValueAsMasked();
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (Ember.isPresent(this.get('_mask'))) {
        this.$().mask(this.get('_mask'), {
          clearIfNotMatch: this.get('_clearIfNotMatch'),
          selectOnFocus: this.get('_selectOnFocus')
        });
      }
    },
    change: function change() {
      this._domValueChanged();
    },
    input: function input() {
      this._domValueChanged();
    },
    fillUnmaskedValueAsMasked: function fillUnmaskedValueAsMasked() {
      if (Ember.isPresent(this.get('_mask'))) {
        Ember.run.scheduleOnce('afterRender', this, function () {
          var unmaskedValue = Ember.isPresent(this.get('_mask')) ? this.$().cleanVal() : this.get('value');

          if (this.get('maskType') === 'iban') {
            unmaskedValue = String(unmaskedValue).toUpperCase();
          }

          var maskedValue = this.$().masked(unmaskedValue);
          this.$().val(maskedValue);
          this.$().change(); // restore from variables...

          var selectionStartPosition = this.get('selectionStartPosition');
          var selectionEndPosition = this.get('selectionEndPosition');
          this.$()[0].setSelectionRange(selectionStartPosition, selectionEndPosition);
        });
      }
    },
    _domValueChanged: function _domValueChanged() {
      var unmaskedValue = Ember.isPresent(this.get('_mask')) ? this.$().cleanVal() : this.get('value');
      var maskedValue = Ember.isPresent(this.get('_mask')) ? this.$().val() : this.get('value');
      var valueToBeSent = this.get('bindMasked') === true ? maskedValue : unmaskedValue; // store current positions in variables

      var selectionStartPosition = this.$()[0].selectionStart;
      var selectionEndPosition = this.$()[0].selectionEnd;

      if (this.get('maskType') === 'iban') {
        valueToBeSent = String(valueToBeSent).toUpperCase();
      }

      var oldValue = this.get('value');

      if (valueToBeSent !== oldValue && !(Ember.isEmpty(valueToBeSent) && Ember.isEmpty(oldValue))) {
        if (Ember.isEmpty(valueToBeSent)) {
          valueToBeSent = null;
        }

        this.set('value', valueToBeSent);
        this.set('selectionStartPosition', selectionStartPosition);
        this.set('selectionEndPosition', selectionEndPosition);
        var onUpdate = this.get('onUpdate');

        if (onUpdate) {
          onUpdate(valueToBeSent);
        }
      }
    }
  });

  _exports.default = _default;
});