define("ui-library/components/p-category-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "99ytfGTZ",
    "block": "{\"symbols\":[\"name\"],\"statements\":[[7,\"span\",true],[10,\"data-test-leaf\",\"\"],[10,\"class\",\"tag-leaf\"],[8],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"tag-separator tag-separator-chevron\"],[8],[9],[0,\"\\n  \"],[1,[28,\"to-upper\",[[24,[\"name\"]]],null],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"span\",true],[10,\"data-test-stem\",\"\"],[10,\"class\",\"tag-stem\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"grandParents\"]]],null,{\"statements\":[[0,\"    \"],[1,[23,1,[]],false],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"tag-separator tag-separator-chevron\"],[8],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[1,[22,\"lastParentName\"],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-library/components/p-category-display/template.hbs"
    }
  });

  _exports.default = _default;
});