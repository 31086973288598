define("ui-library/components/p-list-toggle/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QYv28eH8",
    "block": "{\"symbols\":[\"dd\",\"&default\"],\"statements\":[[4,\"basic-dropdown\",null,[[\"verticalPosition\"],[\"above\"]],{\"statements\":[[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"trigger\"]],\"expected `dd.trigger` to be a contextual component but found a string. Did you mean `(component dd.trigger)`? ('ui-library/components/p-list-toggle/template.hbs' @ L2:C5) \"],null]],null,{\"statements\":[[4,\"p-button\",null,[[\"size\",\"color\"],[\"small\",\"quaternary\"]],{\"statements\":[[0,\"      \"],[1,[22,\"selected\"],false],[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"fa fa-caret-down\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"content\"]],\"expected `dd.content` to be a contextual component but found a string. Did you mean `(component dd.content)`? ('ui-library/components/p-list-toggle/template.hbs' @ L9:C5) \"],null]],null,{\"statements\":[[0,\"    \"],[14,2],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-library/components/p-list-toggle/template.hbs"
    }
  });

  _exports.default = _default;
});