define('ui-library/components/p-one-way-input-with-currency/component', ['exports', 'ui-library/components/p-one-way-input-with-currency/template', 'ember-decorators/object', 'ember-decorators/object/evented', 'ui-library/helpers/currency-symbol', 'ember-keyboard'], function (exports, _template, _object, _evented, _currencySymbol, _emberKeyboard) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _dec3, _desc, _value, _obj;

  var CURRENCIES = ['TRL', 'USD', 'EUR', 'GBP'];

  exports.default = Ember.Component.extend(_emberKeyboard.EKMixin, (_dec = (0, _object.computed)('currency'), _dec2 = (0, _evented.on)((0, _emberKeyboard.keyDown)('ArrowDown')), _dec3 = (0, _evented.on)((0, _emberKeyboard.keyDown)('ArrowUp')), (_obj = {
    layout: _template.default,
    prepend: null,
    currencies: CURRENCIES,
    currency: 'TRL',
    number: 0,
    onNumberUpdate: null,
    onCurrencyUpdate: null,
    dataType: 'decimal2',
    floatPrecision: 2, // number or 'variable'
    'data-test-one-way-input-with-currency': true,

    keyboardActivated: false,
    keyboardFirstResponder: true,

    isOpen: false,

    append: function append(currency) {
      return Ember.String.htmlSafe((0, _currencySymbol.currencySymbol)([currency]));
    },
    toggleDropdown: function toggleDropdown() {
      this.toggleProperty('isOpen');
    },
    changeNumber: function changeNumber(number) {
      if (this.get('onNumberUpdate') != null) {
        this.get('onNumberUpdate')(number);
      }
    },
    changeCurrency: function changeCurrency(currency) {
      this.set('isOpen', false);
      if (this.get('onCurrencyUpdate') != null) {
        this.get('onCurrencyUpdate')(currency);
      }
    },
    nextCurrency: function nextCurrency(e) {
      e.preventDefault();
      var currentIndex = CURRENCIES.indexOf(this.get('currency'));
      var nextIndex = currentIndex + 1;
      if (nextIndex < CURRENCIES.length) {
        this.changeCurrency(CURRENCIES[nextIndex]);
      }
    },
    prevCurrency: function prevCurrency(e) {
      e.preventDefault();
      var currentIndex = CURRENCIES.indexOf(this.get('currency'));
      var nextIndex = currentIndex - 1;
      if (nextIndex > -1) {
        this.changeCurrency(CURRENCIES[nextIndex]);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, 'append', [_dec], Object.getOwnPropertyDescriptor(_obj, 'append'), _obj), _applyDecoratedDescriptor(_obj, 'toggleDropdown', [_object.action], Object.getOwnPropertyDescriptor(_obj, 'toggleDropdown'), _obj), _applyDecoratedDescriptor(_obj, 'changeNumber', [_object.action], Object.getOwnPropertyDescriptor(_obj, 'changeNumber'), _obj), _applyDecoratedDescriptor(_obj, 'changeCurrency', [_object.action], Object.getOwnPropertyDescriptor(_obj, 'changeCurrency'), _obj), _applyDecoratedDescriptor(_obj, 'nextCurrency', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'nextCurrency'), _obj), _applyDecoratedDescriptor(_obj, 'prevCurrency', [_dec3], Object.getOwnPropertyDescriptor(_obj, 'prevCurrency'), _obj)), _obj)));
});