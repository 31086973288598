define("ember-crumbly/templates/components/bread-crumbs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UR6UJzDJ",
    "block": "{\"symbols\":[\"route\",\"&default\"],\"statements\":[[4,\"each\",[[24,[\"routeHierarchy\"]]],null,{\"statements\":[[4,\"if\",[[25,2]],null,{\"statements\":[[0,\"    \"],[14,2,[[23,0,[]],[23,1,[]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[28,\"bread-crumb\",null,[[\"route\",\"breadCrumbs\"],[[23,1,[]],[23,0,[]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-crumbly/templates/components/bread-crumbs.hbs"
    }
  });

  _exports.default = _default;
});