define('ember-plupload/system/sum-by', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (target, key) {
    return Ember.A(target).reduce(function (E, obj) {
      return E + get(obj, key);
    }, 0);
  };

  var get = Ember.get;
});