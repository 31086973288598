define('ui-library/components/p-filter-item/component', ['exports', 'ui-library/components/p-filter-item/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    triggerText: null,
    isOpen: false,
    filter: null,
    onCloseFilter: null,

    actions: {
      closeFilter: function closeFilter() {
        if (this.get('filter')) {
          this.get('filter').reset();
        }

        if (this.get('onCloseFilter')) {
          this.get('onCloseFilter')();
        }
      }
    }
  });
});