define('ui-library/components/p-menu-item/component', ['exports', 'ui-library/components/p-menu-item/template', 'ember-decorators/object'], function (exports, _template, _object) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _desc, _value, _obj;

  var COLORS = ['regular', 'danger'];

  exports.default = Ember.Component.extend((_dec = (0, _object.computed)('color'), _dec2 = (0, _object.computed)('hoverColor'), (_obj = {
    layout: _template.default,
    tagName: 'li',
    attributeBindings: ['data-tid'],
    classNameBindings: ['colorClass', 'hoverColorClass'],
    href: null,
    color: 'regular',
    hoverColor: 'regular',
    dropdown: null,
    'data-test-menu-item': true,

    isLink: Ember.computed.notEmpty('href'),

    colorClass: function colorClass(color) {
      (false && !(COLORS.includes(color)) && Ember.assert('invalid value for menu item color', COLORS.includes(color)));

      return 'color-' + color;
    },
    hoverColorClass: function hoverColorClass(color) {
      (false && !(COLORS.includes(color)) && Ember.assert('invalid value for menu item hover color', COLORS.includes(color)));

      return 'hover-color-' + color;
    },
    clickItem: function clickItem() {
      var dropdown = this.get('dropdown');
      if (dropdown != null && dropdown.actions != null && typeof dropdown.actions.close === 'function') {
        dropdown.actions.close();
      }
      this.sendAction();
    }
  }, (_applyDecoratedDescriptor(_obj, 'colorClass', [_dec], Object.getOwnPropertyDescriptor(_obj, 'colorClass'), _obj), _applyDecoratedDescriptor(_obj, 'hoverColorClass', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'hoverColorClass'), _obj), _applyDecoratedDescriptor(_obj, 'clickItem', [_object.action], Object.getOwnPropertyDescriptor(_obj, 'clickItem'), _obj)), _obj)));
});