define('ui-library/components/p-one-way-checkbox/component', ['exports', 'ember-one-way-controls/components/one-way-checkbox', 'ember-decorators/object', 'ember-prop-types', 'ui-library/components/p-one-way-checkbox/template'], function (exports, _oneWayCheckbox, _object, _emberPropTypes, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _desc, _value, _obj;

  var SIZE = ['18', '20', '24', '28', '32', '36', '40'];
  exports.default = _oneWayCheckbox.default.extend((_dec = (0, _object.computed)('size'), (_obj = {
    layout: _template.default,
    'data-test-one-way-checkbox': true,
    propTypes: {
      size: _emberPropTypes.PropTypes.string
    },

    classNameBindings: ['sizeClass'],

    getDefaultProps: function getDefaultProps() {
      return {
        size: '18'
      };
    },
    sizeClass: function sizeClass(size) {
      (false && !(SIZE.includes(size)) && Ember.assert('invalid value for size', SIZE.includes(size)));

      return 'size-' + size;
    }
  }, (_applyDecoratedDescriptor(_obj, 'sizeClass', [_dec], Object.getOwnPropertyDescriptor(_obj, 'sizeClass'), _obj)), _obj)));
});