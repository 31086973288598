define('ui-library/helpers/currency-name', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Helper.extend({
    i18n: Ember.inject.service(),

    compute: function compute(_ref) {
      var _ref2 = _slicedToArray(_ref, 1),
          currency = _ref2[0];

      currency = currency && currency.length > 0 ? currency : 'TRL';
      switch (currency) {
        case 'TRL':
          return this.get('i18n').t('currencyName.trl');
        case 'USD':
          return this.get('i18n').t('currencyName.usd');
        case 'EUR':
          return this.get('i18n').t('currencyName.eur');
        case 'GBP':
          return this.get('i18n').t('currencyName.gbp');
        default:
          (false && !(false) && Ember.assert('Unknown currency \'' + currency + '\''));

      }
    }
  });
});