define("@ember/legacy-built-in-components/mixins/_target_action_support", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "@ember/legacy-built-in-components/components/_internals"], function (_exports, _toConsumableArray2, _internals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable ember/no-get */

  /* eslint-disable ember/no-new-mixins */

  /**
  @module ember
  */

  /**
  `Ember.TargetActionSupport` is a mixin that can be included in a class
  to add a `triggerAction` method with semantics similar to the Handlebars
  `{{action}}` helper. In normal Ember usage, the `{{action}}` helper is
  usually the best choice. This mixin is most often useful when you are
  doing more complex event handling in Components.
  
  @class TargetActionSupport
  @namespace Ember
  @extends Mixin
  @private
  */
  var _default = Ember.Mixin.create({
    target: null,
    action: null,
    actionContext: null,
    actionContextObject: Ember.computed('actionContext', function () {
      var actionContext = Ember.get(this, 'actionContext');

      if (typeof actionContext === 'string') {
        var value = Ember.get(this, actionContext);

        if (value === undefined) {
          value = Ember.get(_internals.context.lookup, actionContext);
        }

        return value;
      } else {
        return actionContext;
      }
    }),

    /**
    Send an `action` with an `actionContext` to a `target`. The action, actionContext
    and target will be retrieved from properties of the object. For example:
     ```javascript
    import { alias } from '@ember/object/computed';
     App.SaveButtonView = Ember.View.extend(Ember.TargetActionSupport, {
      target: alias('controller'),
      action: 'save',
      actionContext: alias('context'),
      click() {
        this.triggerAction(); // Sends the `save` action, along with the current context
                              // to the current controller
      }
    });
    ```
     The `target`, `action`, and `actionContext` can be provided as properties of
    an optional object argument to `triggerAction` as well.
     ```javascript
    App.SaveButtonView = Ember.View.extend(Ember.TargetActionSupport, {
      click() {
        this.triggerAction({
          action: 'save',
          target: this.get('controller'),
          actionContext: this.get('context')
        }); // Sends the `save` action, along with the current context
            // to the current controller
      }
    });
    ```
     The `actionContext` defaults to the object you are mixing `TargetActionSupport` into.
    But `target` and `action` must be specified either as properties or with the argument
    to `triggerAction`, or a combination:
     ```javascript
    import { alias } from '@ember/object/computed';
     App.SaveButtonView = Ember.View.extend(Ember.TargetActionSupport, {
      target: alias('controller'),
      click() {
        this.triggerAction({
          action: 'save'
        }); // Sends the `save` action, along with a reference to `this`,
            // to the current controller
      }
    });
    ```
     @method triggerAction
    @param opts {Object} (optional, with the optional keys action, target and/or actionContext)
    @return {Boolean} true if the action was sent successfully and did not return false
    @private
    */
    triggerAction: function triggerAction() {
      var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var action = opts.action,
          target = opts.target,
          actionContext = opts.actionContext;
      action = action || Ember.get(this, 'action');
      target = target || getTarget(this);

      if (actionContext === undefined) {
        actionContext = Ember.get(this, 'actionContextObject') || this;
      }

      if (target && action) {
        var ret;

        if (target.send) {
          var _target;

          ret = (_target = target).send.apply(_target, (0, _toConsumableArray2.default)([action].concat(actionContext)));
        } else {
          var _target2;

          (false && !(typeof target[action] === 'function') && Ember.assert("The action '".concat(action, "' did not exist on ").concat(target), typeof target[action] === 'function'));
          ret = (_target2 = target)[action].apply(_target2, (0, _toConsumableArray2.default)([].concat(actionContext)));
        }

        if (ret !== false) {
          return true;
        }
      }

      return false;
    }
  });

  _exports.default = _default;

  function getTarget(instance) {
    var target = Ember.get(instance, 'target');

    if (target) {
      if (typeof target === 'string') {
        var value = Ember.get(instance, target);

        if (value === undefined) {
          value = Ember.get(_internals.context.lookup, target);
        }

        return value;
      } else {
        return target;
      }
    }

    if (instance._target) {
      return instance._target;
    }

    return null;
  }
});