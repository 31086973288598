define('ui-library/components/p-month-picker/component', ['exports', 'ui-library/components/p-month-picker/template', 'ember-decorators/object', 'ui-library/utils/date-util', 'moment'], function (exports, _template, _object, _dateUtil, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _desc, _value, _obj;

  exports.default = Ember.Component.extend((_dec = (0, _object.computed)('_startOffset', '_endOffset'), (_obj = {
    layout: _template.default,
    i18n: Ember.inject.service(),
    tagName: 'ul',
    'data-test-month-picker': true,

    onMonthSelected: null,

    _startOffset: 3,
    _endOffset: 3,

    monthOptions: function monthOptions(startOffset, endOffset) {
      var months = [];
      var start = (0, _moment.default)().subtract(startOffset + 1, 'months');
      var limit = startOffset + endOffset + 1;

      for (var i = 0; i < limit; i++) {
        var tmp = start.add(1, 'months');

        months.push({
          displayName: _dateUtil.default.monthName(tmp, this.get('i18n').t('dateUtil.thisMonth')),
          from: tmp.clone().startOf('month'),
          to: tmp.clone().endOf('month')
        });
      }

      return months;
    },
    loadPrevious: function loadPrevious() {
      this.incrementProperty('_startOffset', 3);
    },
    loadNext: function loadNext() {
      this.incrementProperty('_endOffset', 3);
    },
    selectItem: function selectItem(item) {
      if (this.get('onMonthSelected') != null) {
        this.get('onMonthSelected')(item.from, item.to);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, 'monthOptions', [_dec], Object.getOwnPropertyDescriptor(_obj, 'monthOptions'), _obj), _applyDecoratedDescriptor(_obj, 'loadPrevious', [_object.action], Object.getOwnPropertyDescriptor(_obj, 'loadPrevious'), _obj), _applyDecoratedDescriptor(_obj, 'loadNext', [_object.action], Object.getOwnPropertyDescriptor(_obj, 'loadNext'), _obj), _applyDecoratedDescriptor(_obj, 'selectItem', [_object.action], Object.getOwnPropertyDescriptor(_obj, 'selectItem'), _obj)), _obj)));
});