define('ui-library/utils/filter-value-collection', ['exports', 'ember-decorators/object', 'moment'], function (exports, _object, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.FilterValue = undefined;
  exports.makeFiltersArray = makeFiltersArray;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _dec3, _desc, _value, _obj;

  var FilterValue = exports.FilterValue = Ember.Object.extend((_dec = (0, _object.computed)('initialValues.[]', 'currentValues.[]'), _dec2 = (0, _object.computed)('currentValues.@each'), _dec3 = (0, _object.computed)('isActive', 'hasChanged'), (_obj = {
    key: null,
    definition: null,
    initialValues: Ember.A(),
    currentValues: Ember.A(),
    isActive: Ember.computed.notEmpty('currentValues'),

    hasChanged: function hasChanged(initialValues, currentValues) {
      if (initialValues.length !== currentValues.length) {
        return true;
      }
      return initialValues.find(function (value) {
        return !currentValues.includes(value);
      }) != null;
    },
    toggleSelection: function toggleSelection(value) {
      if (this.get('currentValues').includes(value)) {
        this.get('currentValues').removeObject(value);
      } else {
        this.get('currentValues').pushObject(value);
      }
    },
    selectValue: function selectValue(value) {
      this.set('currentValues', Ember.makeArray(value));
    },
    clear: function clear() {
      this.set('currentValues', Ember.A());
    },
    reset: function reset() {
      this.set('currentValues', Ember.A(this.get('initialValues').slice(0)));
    },
    dateRange: function dateRange(currentValues) {
      if (Ember.isEmpty(currentValues) || currentValues.length != 2 || Ember.isEmpty(currentValues[0]) || Ember.isEmpty(currentValues[1])) {
        return { from: null, to: null };
      }
      return {
        from: (0, _moment.default)(currentValues[0]),
        to: (0, _moment.default)(currentValues[1])
      };
    },
    triggerClass: function triggerClass(isActive, hasChanged) {
      if (isActive && !hasChanged) {
        return 'active';
      } else if (hasChanged) {
        return 'changed';
      } else {
        return null;
      }
    }
  }, (_applyDecoratedDescriptor(_obj, 'hasChanged', [_dec], Object.getOwnPropertyDescriptor(_obj, 'hasChanged'), _obj), _applyDecoratedDescriptor(_obj, 'dateRange', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'dateRange'), _obj), _applyDecoratedDescriptor(_obj, 'triggerClass', [_dec3], Object.getOwnPropertyDescriptor(_obj, 'triggerClass'), _obj)), _obj)));

  function makeFiltersArray(filterDefinitions, readValue) {
    var array = [];
    if (!Ember.isEmpty(filterDefinitions)) {
      array = Object.keys(filterDefinitions).map(function (filterName) {
        var filterValue = readValue(filterName) || '';
        var initialValues = Ember.isEmpty(filterValue) ? Ember.A() : Ember.A(filterValue.split(','));
        return FilterValue.create({
          key: filterName,
          definition: filterDefinitions[filterName],
          initialValues: initialValues,
          currentValues: Ember.A(initialValues.slice(0))
        });
      });
    }
    return Ember.A(array);
  }

  var FilterValueCollection = Ember.Object.extend({
    filters: Ember.A(),

    activeFilters: Ember.computed.filter('filters', function (filter) {
      return filter.get('isActive');
    }),
    inactiveFilters: Ember.computed.filter('filters', function (filter) {
      return !filter.get('isActive');
    }),
    hasActiveFilters: Ember.computed.gt('activeFilters.length', 0),

    buildEmptyFiltersHash: function buildEmptyFiltersHash() {
      return this.get('filters').reduce(function (newFilters, filter) {
        newFilters[filter.get('key')] = null;
        return newFilters;
      }, {});
    },
    buildNewFiltersHash: function buildNewFiltersHash() {
      return this.get('filters').reduce(function (newFilters, filter) {
        if (filter.get('isActive')) {
          newFilters[filter.get('key')] = filter.get('currentValues').join(',');
        } else {
          newFilters[filter.get('key')] = null;
        }
        return newFilters;
      }, {});
    }
  });

  exports.default = FilterValueCollection;
});