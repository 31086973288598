define('ember-data-save-relationships/index', ['exports', 'ember-inflector'], function (exports, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    serializeRelationship: function serializeRelationship(snapshot, data, rel) {
      var relKind = rel.kind;
      var relKey = rel.key;

      if (data && this.get('attrs.' + relKey + '.serialize') === true) {

        data.relationships = data.relationships || {};
        var key = this.keyForRelationship(relKey, relKind, 'serialize');
        var relationship = data.relationships[key] = data.relationships[key] || {};

        if (relKind === "belongsTo") {
          relationship.data = this.serializeRecord(snapshot.belongsTo(relKey));
        } else if (relKind === "hasMany") {
          relationship.data = []; // provide a default empty value
          var hasMany = snapshot.hasMany(relKey);
          if (hasMany !== undefined) {
            relationship.data = hasMany.map(this.serializeRecord.bind(this));
          }
        }
      }
    },
    serialize: function serialize(snapshot, options) {
      if (!(options && options.__isSaveRelationshipsMixinCallback)) {
        this.set("_visitedRecordIds", {});
      }
      return this._super.apply(this, arguments);
    },


    _visitedRecordIds: {},

    serializeRecord: function serializeRecord(obj) {
      if (!obj) {
        return null;
      }

      var serialized = obj.serialize({ __isSaveRelationshipsMixinCallback: true });

      if (obj.id) {
        serialized.data.id = obj.id;
        this.get('_visitedRecordIds')[obj.id] = {};
      } else {
        if (!serialized.data.attributes) {
          serialized.data.attributes = {};
        }
        serialized.data.attributes.__id__ = obj.record.get('_internalModel')[Ember.GUID_KEY];
        this.get('_visitedRecordIds')[serialized.data.attributes.__id__] = {};
      }

      for (var relationshipId in serialized.data.relationships) {
        if (this.get('_visitedRecordIds')[relationshipId]) {
          delete serialized.data.relationships[relationshipId];
        }
      }

      if (serialized.data.relationships === {}) {
        delete serialized.data.relationships;
      }

      // // do not allow embedded relationships
      // delete serialized.data.relationships;

      return serialized.data;
    },
    serializeHasMany: function serializeHasMany() {
      this._super.apply(this, arguments);
      this.serializeRelationship.apply(this, arguments);
    },
    serializeBelongsTo: function serializeBelongsTo() {
      this._super.apply(this, arguments);
      this.serializeRelationship.apply(this, arguments);
    },
    updateRecord: function updateRecord(json, store) {
      if (json.attributes !== undefined && json.attributes.__id__ !== undefined) {
        json.type = (0, _emberInflector.singularize)(json.type);

        var record = store.peekAll(json.type).filterBy('currentState.stateName', "root.loaded.created.uncommitted").findBy('_internalModel.' + Ember.GUID_KEY, json.attributes.__id__);

        if (record) {
          // record.unloadRecord();
          record.set('id', json.id);
          record._internalModel.id = json.id;
          record._internalModel.flushChangedAttributes();
          record._internalModel.adapterWillCommit();
          store.didSaveRecord(record._internalModel);
          // store.push({ data: json });
        }
      }

      return json;
    },
    normalizeSaveResponse: function normalizeSaveResponse(store, modelName, obj) {
      var _this = this;

      var rels = obj.data.relationships || [];

      var included = {};
      if (obj.included) {
        included = obj.included.reduce(function (prev, current) {
          prev[current.id] = current;
          return prev;
        }, {});
      }

      Object.keys(rels).forEach(function (rel) {
        var relationshipData = rels[rel].data;
        if (relationshipData) {
          _this.normalizeRelationship(relationshipData, store, included);
        }
      });

      // now run through the included objects looking for client ids
      if (obj.included) {
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = obj.included[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var includedItem = _step.value;

            this.updateRecord(includedItem, store);
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }
      }

      return this._super(store, modelName, obj);
    },
    normalizeRelationship: function normalizeRelationship(relationshipData, store, included) {
      var _this2 = this;

      if (Array.isArray(relationshipData)) {
        // hasMany
        relationshipData = relationshipData.map(function (item) {
          return _this2.normalizeRelationshipItem(item, store, included);
        });
      } else if (relationshipData) {
        this.normalizeRelationshipItem(relationshipData, store, included);
      }
    },
    normalizeRelationshipItem: function normalizeRelationshipItem(item, store, included) {
      var _this3 = this;

      if (item.__normalized) {
        return;
      }
      item.__normalized = true;
      var includedData = included[item.id];
      if (includedData) {
        item = includedData;
      }
      var internalRelationships = item.relationships;
      if (internalRelationships !== undefined) {
        Object.keys(internalRelationships).forEach(function (rel) {
          _this3.normalizeRelationship(internalRelationships[rel].data, store, included);
        });
      }
      if (!includedData) {
        // if it's in the included block then it will be updated at the end of normalizeSaveResponse
        this.updateRecord(item, store);
      }
    }
  });
});