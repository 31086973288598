define('ui-library/components/p-button/component', ['exports', 'ui-library/components/p-button/template', 'ember-decorators/object', 'ember-prop-types'], function (exports, _template, _object, _emberPropTypes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _dec3, _dec4, _desc, _value, _obj;

  var SHAPES = ['regular', 'square'],
      TYPES = ['solid', 'outline', 'reveal', 'dashed', 'text'],
      COLORS = ['primary', 'secondary', 'tertiary', 'quaternary', 'danger', 'payment', 'collection', 'akbank', 'help', 'phoenix'],
      SIZES = ['bigger', 'regular', 'small', 'tiny'];

  exports.default = Ember.Component.extend(_emberPropTypes.default, (_dec = (0, _object.computed)('shape'), _dec2 = (0, _object.computed)('type'), _dec3 = (0, _object.computed)('color'), _dec4 = (0, _object.computed)('size'), (_obj = {
    propTypes: {
      type: _emberPropTypes.PropTypes.string,
      shape: _emberPropTypes.PropTypes.string,
      color: _emberPropTypes.PropTypes.any,
      size: _emberPropTypes.PropTypes.string,
      tabIndex: _emberPropTypes.PropTypes.any,
      onClick: _emberPropTypes.PropTypes.func
    },
    getDefaultProps: function getDefaultProps() {
      return {
        type: 'solid',
        shape: 'regular',
        color: 'primary',
        size: 'regular',
        tabIndex: null,
        onClick: null
      };
    },


    layout: _template.default,
    tagName: 'button',
    attributeBindings: ['disabled', 'tabIndex', 'href', 'data-tid', 'target', 'rel'],
    classNameBindings: ['shapeClass', 'typeClass', 'colorClass', 'sizeClass', 'busy'],

    busy: false,
    disabled: false,
    'data-test-button': true,

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      if (this.get('tagName') === 'a' && this.get('disabled')) {
        this.set('href', null);
      }
    },
    shapeClass: function shapeClass(shape) {
      (false && !(SHAPES.includes(shape)) && Ember.assert('invalid value for button shape', SHAPES.includes(shape)));

      return 'shape-' + shape;
    },
    typeClass: function typeClass(type) {
      (false && !(TYPES.includes(type)) && Ember.assert('invalid value for button type', TYPES.includes(type)));

      return 'type-' + type;
    },
    colorClass: function colorClass(color) {
      (false && !(COLORS.includes(color)) && Ember.assert('invalid value for button color', COLORS.includes(color)));

      return 'color-' + color;
    },
    sizeClass: function sizeClass(size) {
      (false && !(SIZES.includes(size)) && Ember.assert('invalid value for button size', SIZES.includes(size)));

      return 'size-' + size;
    },
    click: function click(e) {
      if (this.get('disabled')) {
        e.preventDefault();
        return false;
      }

      if (this.onClick) {
        // pass onclick to p-button in order to get rid of sendAction deprecations (after Ember 3.4)
        // https://deprecations.emberjs.com/v3.x/#toc_ember-component-send-action
        this.onClick(e);
      } else {
        this.sendAction('action', e);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, 'shapeClass', [_dec], Object.getOwnPropertyDescriptor(_obj, 'shapeClass'), _obj), _applyDecoratedDescriptor(_obj, 'typeClass', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'typeClass'), _obj), _applyDecoratedDescriptor(_obj, 'colorClass', [_dec3], Object.getOwnPropertyDescriptor(_obj, 'colorClass'), _obj), _applyDecoratedDescriptor(_obj, 'sizeClass', [_dec4], Object.getOwnPropertyDescriptor(_obj, 'sizeClass'), _obj)), _obj)));
});