define("ember-simple-auth-oidc/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.handleUnauthorized = _exports.default = void 0;

  var handleUnauthorized = function handleUnauthorized(session) {
    if (session.isAuthenticated) {
      Ember.set(session, "data.nextURL", location.href.replace(location.origin, ""));
      session.invalidate();
    }
  };

  _exports.handleUnauthorized = handleUnauthorized;
  var _default = {
    handleUnauthorized: handleUnauthorized
  };
  _exports.default = _default;
});