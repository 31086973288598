define('ui-library/components/p-date-range-picker-one-window/component', ['exports', 'ui-library/components/p-date-range-picker-one-window/template', 'ember-decorators/object', 'ember-prop-types'], function (exports, _template, _object, _emberPropTypes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _desc, _value, _obj;

  exports.default = Ember.Component.extend(_emberPropTypes.default, (_dec = (0, _object.computed)('placement'), _dec2 = (0, _object.computed)('from', 'displayedMonth'), (_obj = {
    layout: _template.default,
    'data-test-date-range-picker-one-window': true,

    propTypes: {
      displayedMonth: _emberPropTypes.PropTypes.object.isRequired,
      from: _emberPropTypes.PropTypes.oneOfType([_emberPropTypes.PropTypes.object, _emberPropTypes.PropTypes.null]).isRequired,
      to: _emberPropTypes.PropTypes.oneOfType([_emberPropTypes.PropTypes.object, _emberPropTypes.PropTypes.null]).isRequired,
      onChangeFrom: _emberPropTypes.PropTypes.func.isRequired,
      onChangeTo: _emberPropTypes.PropTypes.func.isRequired,
      minDate: _emberPropTypes.PropTypes.object,
      maxDate: _emberPropTypes.PropTypes.object,
      placement: _emberPropTypes.PropTypes.string
    },

    getDefaultProps: function getDefaultProps() {
      return {
        placement: 'bottom'
      };
    },
    calendarClass: function calendarClass(placement) {
      return placement === 'top' ? 'placement-top' : 'placement-bottom';
    },
    displayedDate: function displayedDate(from, displayedMonth) {
      if (from) {
        return from;
      } else {
        return displayedMonth;
      }
    },
    select: function select(date) {
      if (!this.get('from')) {
        this.get('onChangeFrom')(date);
      } else if (this.get('from') && this.get('to')) {
        this.get('onChangeFrom')(date);
        this.get('onChangeTo')(null);
      } else if (date > this.get('from')) {
        this.get('onChangeTo')(date);
      } else {
        this.get('onChangeTo')(this.get('from'));
        this.get('onChangeFrom')(date);
      }
      // we set displayedMonth because it is shown as selected and looks highlighted
      // when this problem solved we can delete this
      this.set('displayedMonth', date);
    }
  }, (_applyDecoratedDescriptor(_obj, 'calendarClass', [_dec], Object.getOwnPropertyDescriptor(_obj, 'calendarClass'), _obj), _applyDecoratedDescriptor(_obj, 'displayedDate', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'displayedDate'), _obj), _applyDecoratedDescriptor(_obj, 'select', [_object.action], Object.getOwnPropertyDescriptor(_obj, 'select'), _obj)), _obj)));
});