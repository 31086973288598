define('ui-library/components/p-card-body/component', ['exports', 'ui-library/components/p-card-body/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    attributeBindings: ['data-test-card-body'],
    'data-test-card-body': true
  });
});