define("ui-library/components/p-breadcrumb/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ANh8pXvZ",
    "block": "{\"symbols\":[\"component\",\"route\"],\"statements\":[[4,\"bread-crumbs\",null,[[\"tagName\",\"linkable\"],[\"h1\",true]],{\"statements\":[[4,\"if\",[[28,\"and\",[[23,2,[\"linkable\"]],[28,\"gt\",[[23,1,[\"routeHierarchy\",\"length\"]],1],null]],null]],null,{\"statements\":[[4,\"link-to\",null,[[\"route\"],[[23,2,[\"path\"]]]],{\"statements\":[[0,\"      \"],[1,[23,2,[\"title\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"span\",true],[8],[1,[23,2,[\"title\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-library/components/p-breadcrumb/template.hbs"
    }
  });

  _exports.default = _default;
});