define("ui-library/components/p-tabbed-button-content/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "B0q/YIBE",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"data-test-yielded-tabbed-content\",\"\"],[8],[0,\"\\n  \"],[1,[22,\"title\"],false],[0,\"\\n  \"],[14,1],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-library/components/p-tabbed-button-content/template.hbs"
    }
  });

  _exports.default = _default;
});