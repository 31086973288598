define("ui-library/components/p-array-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eid8KLoO",
    "block": "{\"symbols\":[\"item\",\"index\",\"&default\"],\"statements\":[[7,\"ul\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"items\"]]],null,{\"statements\":[[4,\"if\",[[28,\"p-includes\",[[24,[\"linesOfIndex\"]],[23,2,[]]],null]],null,{\"statements\":[[0,\"      \"],[7,\"hr\",true],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[7,\"li\",true],[10,\"data-test-item\",\"\"],[11,\"class\",[28,\"if\",[[28,\"eq\",[[23,2,[]],[24,[\"_navigator\",\"currentIndex\"]]],null],\"highlighted\"],null]],[8],[0,\"\\n      \"],[14,3,[[23,1,[]]]],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-library/components/p-array-select/template.hbs"
    }
  });

  _exports.default = _default;
});