define('ui-library/components/p-warning-bar/component', ['exports', 'ui-library/components/p-warning-bar/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    'data-test-warning-bar': true
  });
});