define("ui-library/components/p-list-footer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yetoWeLr",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"list-toggle\",\"pagination\",\"aggregates\"],[[28,\"component\",[\"p-list-toggle\"],null],[28,\"component\",[\"p-page-numbers\"],[[\"currentPage\",\"pageCount\"],[[24,[\"meta\",\"currentPage\"]],[24,[\"meta\",\"totalPages\"]]]]],[28,\"component\",[\"p-list-aggregates\"],[[\"recordCount\"],[[24,[\"meta\",\"totalCount\"]]]]]]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-library/components/p-list-footer/template.hbs"
    }
  });

  _exports.default = _default;
});