define("ui-library/components/p-filter-collection-with-date/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Q2NlZ5sW",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"picker\"]],\"month\"],null]],null,{\"statements\":[[4,\"if\",[[24,[\"title\"]]],null,{\"statements\":[[0,\"    \"],[7,\"h4\",true],[10,\"data-test-filter-collection-with-date-title\",\"\"],[8],[1,[22,\"title\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[1,[28,\"p-month-picker\",null,[[\"onMonthSelected\"],[[28,\"action\",[[23,0,[]],\"applyDate\"],null]]]],false],[0,\"\\n  \"],[7,\"ul\",true],[8],[0,\"\\n    \"],[7,\"li\",true],[8],[0,\"\\n      \"],[7,\"a\",false],[12,\"href\",\"#\"],[12,\"data-test-filter-collection-with-date-show-custom-date\",\"\"],[3,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"picker\"]]],null],\"range\"]],[8],[0,\"\\n        \"],[1,[28,\"t\",[\"pFilterCollectionWithDate.customDate\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"p-date-range-picker\",null,[[\"fromDate\",\"toDate\",\"onApply\",\"onCancel\"],[[24,[\"fromDate\"]],[24,[\"toDate\"]],[28,\"action\",[[23,0,[]],\"applyDate\"],null],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"picker\"]]],null],\"month\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-library/components/p-filter-collection-with-date/template.hbs"
    }
  });

  _exports.default = _default;
});