define("ui-library/components/p-search-box/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lZsilVET",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"or\",[[28,\"is-asist\",null,null],[28,\"is-phoenix\",null,null]],null]],null,{\"statements\":[[4,\"p-button\",null,[[\"color\",\"click\"],[\"quaternary\",[28,\"action\",[[23,0,[]],\"onButtonClick\"],null]]],{\"statements\":[[0,\"    \"],[7,\"i\",true],[11,\"class\",[29,[\"custom-icon \",[28,\"if\",[[24,[\"showClearSearchButton\"]],\"close-icon\",\" search\"],null],\" \"]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[1,[28,\"input\",null,[[\"type\",\"value\",\"placeholder\",\"enter\",\"key-up\",\"escape-press\"],[\"text\",[24,[\"inputValue\"]],[24,[\"placeholderKey\"]],[28,\"unless\",[[24,[\"instantSearchMode\"]],[28,\"action\",[[23,0,[]],\"trigger\"],null]],null],[28,\"if\",[[24,[\"instantSearchMode\"]],[28,\"action\",[[23,0,[]],\"trigger\"],null]],null],[28,\"action\",[[23,0,[]],\"onEscape\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"input\",null,[[\"type\",\"value\",\"placeholder\",\"enter\",\"key-up\",\"escape-press\"],[\"text\",[24,[\"inputValue\"]],[24,[\"placeholderKey\"]],[28,\"unless\",[[24,[\"instantSearchMode\"]],[28,\"action\",[[23,0,[]],\"trigger\"],null]],null],[28,\"if\",[[24,[\"instantSearchMode\"]],[28,\"action\",[[23,0,[]],\"trigger\"],null]],null],[28,\"action\",[[23,0,[]],\"onEscape\"],null]]]],false],[0,\"\\n\"],[4,\"p-button\",null,[[\"color\",\"click\"],[\"quaternary\",[28,\"action\",[[23,0,[]],\"onButtonClick\"],null]]],{\"statements\":[[0,\"    \"],[7,\"i\",true],[11,\"class\",[29,[\"fa \",[28,\"if\",[[24,[\"showClearSearchButton\"]],\"fa-times\",\"fa-search\"],null],\" fa-15x\"]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-library/components/p-search-box/template.hbs"
    }
  });

  _exports.default = _default;
});