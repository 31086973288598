define("ui-library/components/p-one-way-iban/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZKfn6L1P",
    "block": "{\"symbols\":[],\"statements\":[[4,\"p-input-decoration\",null,[[\"prepend\",\"icon\"],[[24,[\"prefix\"]],[24,[\"icon\"]]]],{\"statements\":[[0,\"  \"],[1,[28,\"ember-mask-input\",null,[[\"value\",\"mask\",\"onUpdate\",\"data-test-masked-input\"],[[24,[\"inputValue\"]],[24,[\"mask\"]],[28,\"action\",[[23,0,[]],\"changeValue\"],null],true]]],false],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"logoSrc\"]]],null,{\"statements\":[[0,\"    \"],[7,\"img\",true],[11,\"src\",[29,[[22,\"logoSrc\"]]]],[10,\"alt\",\"\"],[10,\"data-test-logo\",\"\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-library/components/p-one-way-iban/template.hbs"
    }
  });

  _exports.default = _default;
});