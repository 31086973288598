define('ui-library/components/p-multiple-select-with-checkboxes/component', ['exports', 'ui-library/components/p-multiple-select-with-checkboxes/template', 'ember-decorators/object', 'ember-prop-types'], function (exports, _template, _object, _emberPropTypes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _desc, _value, _obj;

  exports.default = Ember.Component.extend(_emberPropTypes.default, (_dec = (0, _object.computed)('componentCssClassName'), _dec2 = (0, _object.observes)('isOpen'), (_obj = {
    layout: _template.default,
    propTypes: {
      triggerText: _emberPropTypes.PropTypes.string.isRequired,
      isOpen: _emberPropTypes.PropTypes.bool,
      selectedValues: _emberPropTypes.PropTypes.array,
      onSelectedValuesChanged: _emberPropTypes.PropTypes.func
    },
    getDefaultProps: function getDefaultProps() {
      return {
        isOpen: false,
        selectedValues: Ember.A(),
        onSelectedValuesChanged: null
      };
    },


    dropdownRemoteController: null,

    get dropdownContentClass() {
      return this.get('componentCssClassName') + '--content';
    },

    openOrCloseDropdown: function openOrCloseDropdown() {
      if (this.get('isOpen')) {
        this.get('dropdownRemoteController').actions.open();
      } else {
        this.get('dropdownRemoteController').actions.close();
      }
    },
    toggleSelection: function toggleSelection(value) {
      var selectedValues = this.get('selectedValues');
      if (this.get('onSelectedValuesChanged') != null) {
        if (selectedValues.includes(value)) {
          selectedValues.removeObject(value);
          this.get('onSelectedValuesChanged')(selectedValues);
        } else {
          selectedValues.pushObject(value);
          this.get('onSelectedValuesChanged')(selectedValues);
        }
      }
    }
  }, (_applyDecoratedDescriptor(_obj, 'dropdownContentClass', [_dec], Object.getOwnPropertyDescriptor(_obj, 'dropdownContentClass'), _obj), _applyDecoratedDescriptor(_obj, 'openOrCloseDropdown', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'openOrCloseDropdown'), _obj), _applyDecoratedDescriptor(_obj, 'toggleSelection', [_object.action], Object.getOwnPropertyDescriptor(_obj, 'toggleSelection'), _obj)), _obj)));
});