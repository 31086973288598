define('ui-library/utils/date-range-util', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var DateRangeUtil = {
    fromPreset: function fromPreset(name) {
      return this[name]();
    },
    isFullMonth: function isFullMonth(from, to) {
      if (from == null || to == null) {
        return false;
      }

      if (!from.isSame(to, 'year') || !from.isSame(to, 'month')) {
        return false;
      }

      return from.date() === 1 && to.date() === to.daysInMonth();
    },
    thisMonth: function thisMonth() {
      return {
        from: (0, _moment.default)().startOf('month'),
        to: (0, _moment.default)()
      };
    },
    previousMonth: function previousMonth() {
      return {
        from: (0, _moment.default)().subtract(1, 'month').startOf('month'),
        to: (0, _moment.default)().subtract(1, 'month').endOf('month')
      };
    },
    thisQuarter: function thisQuarter() {
      var today = (0, _moment.default)();
      var month = today.get('month');
      return {
        from: today.clone().set({ date: '1', month: month - month % 3 }),
        to: today
      };
    },
    previousQuarter: function previousQuarter() {
      var today = (0, _moment.default)();
      var extraMonths = today.get('month') % 3;
      return {
        from: today.clone().subtract(extraMonths + 3, 'month').startOf('month'),
        to: today.subtract(extraMonths + 1, 'month').endOf('month')
      };
    },
    thisYear: function thisYear() {
      return {
        from: (0, _moment.default)().startOf('year'),
        to: (0, _moment.default)()
      };
    },
    previousYear: function previousYear() {
      return {
        from: (0, _moment.default)().subtract(1, 'year').startOf('year'),
        to: (0, _moment.default)().subtract(1, 'year').endOf('year')
      };
    }
  };

  exports.default = DateRangeUtil;
});