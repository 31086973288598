define("ui-library/components/p-exchange-rate-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "unZVi10V",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"displayRate\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"parenthesis\"]]],null,{\"statements\":[[0,\"(\"]],\"parameters\":[]},null],[0,\"1\"],[1,[28,\"currency-symbol\",[[24,[\"currency\"]]],null],true],[0,\" =\\n  \"],[1,[24,[\"formattedParts\",\"left\"]],false],[1,[24,[\"formattedParts\",\"decimalSeperator\"]],false],[1,[24,[\"formattedParts\",\"right\"]],false],[1,[22,\"currency-symbol\"],true],[4,\"if\",[[24,[\"parenthesis\"]]],null,{\"statements\":[[0,\")\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-library/components/p-exchange-rate-display/template.hbs"
    }
  });

  _exports.default = _default;
});