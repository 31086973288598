define('ui-library/components/p-tooltip/component', ['exports', 'ember-tooltips/components/ember-tooltip'], function (exports, _emberTooltip) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberTooltip.default.extend({
    'data-test-tooltip': true,
    tooltipClass: 'p-tooltip'
  });
});