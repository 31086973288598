define('ui-library/components/p-index-header/component', ['exports', 'ui-library/components/p-index-header/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    classNameBindings: ['sticky'],
    filterValueCollection: null,
    attributeBindings: ['data-test-index-header', 'data-tid'],
    'data-test-index-header': true
  });
});