define("ui-library/components/p-one-way-numeric-with-vat/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "j+aqsa5c",
    "block": "{\"symbols\":[\"vatOption\"],\"statements\":[[4,\"p-input-decoration\",null,[[\"append\",\"icon\",\"onClickIcon\"],[[24,[\"append\"]],[28,\"if\",[[24,[\"isOpen\"]],\"fa-caret-up\",\"fa-caret-down\"],null],[28,\"action\",[[23,0,[]],\"toggleDropdown\"],null]]],{\"statements\":[[0,\"  \"],[1,[28,\"p-one-way-numeric\",null,[[\"number\",\"numberupdate\",\"dataType\",\"data-test-number\"],[[24,[\"number\"]],[28,\"action\",[[23,0,[]],\"changeNumber\"],null],\"decimal2\",true]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"isOpen\"]]],null,{\"statements\":[[4,\"click-outside\",null,[[\"action\",\"data-test-menu\"],[[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"isOpen\"]]],null],false],null],true]],{\"statements\":[[0,\"    \"],[7,\"ul\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"vatOptions\"]]],null,{\"statements\":[[0,\"        \"],[7,\"li\",true],[8],[0,\"\\n          \"],[7,\"a\",false],[12,\"href\",\"#\"],[3,\"action\",[[23,0,[]],\"changeVat\",[23,1,[]]]],[8],[0,\"%\"],[1,[23,1,[]],false],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-library/components/p-one-way-numeric-with-vat/template.hbs"
    }
  });

  _exports.default = _default;
});