define('ui-library/utils/batch-actions/job', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  //
  // Extend this class and override run(), handleError(response) and rollback() to define a job.
  //
  // run() method should return a promise object
  // Default implemention for run() returns a promise that rejects right away
  //
  // Promise results are handled automatically depending on the type.
  // Sucess and server errors are caught automatically.
  // To handle other error types (such as 422) you should override handleError(response)
  // and add individual errors to this.errors array.
  //
  // In case of an error rollback() is called.
  // Default implementation for rollback() calls this.item.rollbackAttributes()
  // if such a function exists
  //

  var STATUS_NOT_RUNNING = exports.STATUS_NOT_RUNNING = 'not-running';
  var STATUS_RUNNING = exports.STATUS_RUNNING = 'running';
  var STATUS_SUCCESS = exports.STATUS_SUCCESS = 'success';
  var STATUS_SERVER_ERROR = exports.STATUS_SERVER_ERROR = 'server-error';
  var STATUS_OTHER_ERROR = exports.STATUS_OTHER_ERROR = 'other-error';

  var Job = function () {
    function Job(item) {
      _classCallCheck(this, Job);

      this.item = item;
      this.status = STATUS_NOT_RUNNING;
      this.result = null;
      this.errors = [];
    }

    _createClass(Job, [{
      key: 'hasFailed',
      value: function hasFailed() {
        return this.status == STATUS_SERVER_ERROR || this.status == STATUS_OTHER_ERROR;
      }
    }, {
      key: '_runInternal',
      value: function _runInternal() {
        this.beginRun();
        var promise = this.run();
        return this.endRunSafely(promise);
      }
    }, {
      key: 'beginRun',
      value: function beginRun() {
        this.status = STATUS_RUNNING;
      }
    }, {
      key: 'run',
      value: function run() {
        return new Ember.RSVP.Promise(function (_resolve, reject) {
          reject({
            status: 'default-run'
          });
        });
      }
    }, {
      key: 'endRunSafely',
      value: function endRunSafely(promise) {
        var _this = this;

        return new Ember.RSVP.Promise(function (resolve) {
          promise.then(function (result) {
            _this.result = result;
            _this.status = STATUS_SUCCESS;
          }, function (response) {
            _this.result = response;
            _this.rollback();
            if (response == null || response.status == null) {
              throw 'unknown batch job error';
            } else if (response.status === 500) {
              _this.status = STATUS_SERVER_ERROR;
            } else {
              _this.status = STATUS_OTHER_ERROR;
              _this.handleError(response);
            }
          }).finally(resolve);
        });
      }
    }, {
      key: 'handleError',
      value: function handleError() /* response */{}
    }, {
      key: 'rollback',
      value: function rollback() {
        if (this.item && typeof this.item.rollbackAttributes === 'function') {
          this.item.rollbackAttributes();
        }
      }
    }]);

    return Job;
  }();

  exports.default = Job;
});