define("ui-library/components/p-quantity-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZzUUn5eP",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\",true],[10,\"data-test-quantity\",\"\"],[10,\"class\",\"amount\"],[8],[0,\"\\n  \"],[1,[28,\"p-float-display\",null,[[\"size\",\"color\",\"value\"],[[24,[\"size\"]],[24,[\"color\"]],[24,[\"quantity\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"hasUnit\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"data-test-unit\",\"\"],[10,\"class\",\"unit\"],[8],[1,[22,\"unit\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-library/components/p-quantity-display/template.hbs"
    }
  });

  _exports.default = _default;
});