define('ui-library/components/p-filter-container/component', ['exports', 'ui-library/components/p-filter-container/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    filterValueCollection: null,
    selectedInactiveFilter: null,
    onFiltersChange: null,
    attributeBindings: ['data-test-filter-container'],
    'data-test-filter-container': true,

    actions: {
      showFilter: function showFilter(filterItem) {
        this.set('selectedInactiveFilter', filterItem);
      },
      closeSelectedInactiveFilter: function closeSelectedInactiveFilter() {
        this.set('selectedInactiveFilter', null);
      },
      removeAllFilters: function removeAllFilters() {
        if (this.get('onFiltersChange')) {
          var emptyFilters = this.get('filterValueCollection').buildEmptyFiltersHash();
          this.get('onFiltersChange')(emptyFilters);
        }
      },
      bubbleFilterChange: function bubbleFilterChange() {
        if (this.get('onFiltersChange')) {
          var newFilters = this.get('filterValueCollection').buildNewFiltersHash();
          this.get('onFiltersChange')(newFilters);
        }
        this.set('selectedInactiveFilter', null);
      }
    }
  });
});