define("ui-library/components/p-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TXLDrJ/x",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"header\",\"body\",\"footer\"],[[28,\"component\",[\"p-table-header\"],[[\"columnWidths\"],[[24,[\"safeColumnWidths\"]]]]],[28,\"component\",[\"p-table-body\"],[[\"rows\",\"columnWidths\"],[[24,[\"items\"]],[24,[\"safeColumnWidths\"]]]]],[28,\"component\",[\"p-table-footer\"],null]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-library/components/p-table/template.hbs"
    }
  });

  _exports.default = _default;
});