define("ui-library/components/p-mail-to/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aaJsl0KC",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"email\"]]],null,{\"statements\":[[0,\"  \"],[7,\"a\",true],[11,\"href\",[29,[\"mailto:\",[22,\"email\"]]]],[8],[0,\"\\n    \"],[1,[22,\"email\"],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"class\",\"c-textLight\"],[8],[0,\"—\"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-library/components/p-mail-to/template.hbs"
    }
  });

  _exports.default = _default;
});