define('ui-library/components/p-float-display/component', ['exports', 'ui-library/components/p-float-display/template', 'ember-decorators/object', 'ui-library/utils/number-util'], function (exports, _template, _object, _numberUtil) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _dec3, _dec4, _dec5, _desc, _value, _obj;

  var SIZES = ['regular', 'small'],
      COLORS = ['regular', 'light', 'danger'],
      WEIGHTS = ['regular', 'bold'];

  exports.default = Ember.Component.extend((_dec = (0, _object.computed)('size'), _dec2 = (0, _object.computed)('color'), _dec3 = (0, _object.computed)('weight'), _dec4 = (0, _object.computed)('value', 'variablePrecision'), _dec5 = (0, _object.computed)('value', 'precision'), (_obj = {
    layout: _template.default,

    tagName: 'span',
    value: null,
    variablePrecision: false,
    size: 'regular',
    color: 'regular',
    weight: 'bold',
    classNameBindings: ['sizeClass', 'colorClass', 'weightClass'],

    'data-test-float-display': true,

    displayValue: Ember.computed.notEmpty('value'),

    sizeClass: function sizeClass(size) {
      (false && !(SIZES.includes(size)) && Ember.assert('invalid value for amount display size', SIZES.includes(size)));

      return 'size-' + size;
    },
    colorClass: function colorClass(color) {
      (false && !(COLORS.includes(color)) && Ember.assert('invalid value for amount display color', COLORS.includes(color)));

      return 'color-' + color;
    },
    weightClass: function weightClass(weight) {
      (false && !(WEIGHTS.includes(weight)) && Ember.assert('invalid value for amount display weight', WEIGHTS.includes(weight)));

      return 'weight-' + weight;
    },
    precision: function precision(value, variablePrecision) {
      return variablePrecision ? _numberUtil.default.variablePrecisionForFloat(value) : 2;
    },
    formattedParts: function formattedParts(value, precision) {
      return _numberUtil.default.splitAndFormatFloat(value, precision);
    }
  }, (_applyDecoratedDescriptor(_obj, 'sizeClass', [_dec], Object.getOwnPropertyDescriptor(_obj, 'sizeClass'), _obj), _applyDecoratedDescriptor(_obj, 'colorClass', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'colorClass'), _obj), _applyDecoratedDescriptor(_obj, 'weightClass', [_dec3], Object.getOwnPropertyDescriptor(_obj, 'weightClass'), _obj), _applyDecoratedDescriptor(_obj, 'precision', [_dec4], Object.getOwnPropertyDescriptor(_obj, 'precision'), _obj), _applyDecoratedDescriptor(_obj, 'formattedParts', [_dec5], Object.getOwnPropertyDescriptor(_obj, 'formattedParts'), _obj)), _obj)));
});