define('ui-library/helpers/is-asist', ['exports', 'ember-get-config'], function (exports, _emberGetConfig) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isAsist = isAsist;
  function isAsist() {
    return _emberGetConfig.default.includeProject === 'companion';
  }

  exports.default = Ember.Helper.helper(isAsist);
});