define('ui-library/lib/constants', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var DefaultCurrency = 'TRL';

  var KeyConstants = {
    TAB: 9,
    ENTER: 13,
    ESC: 27,
    UP: 38,
    DOWN: 40,
    BACKSPACE: 8,
    COMMA: 44,
    DASH: 45,
    DOT: 46,
    SLASH: 47,
    ZERO: 48,
    NINE: 57,

    LEFT_ARROW: 37,
    UP_ARROW: 38,
    RIGHT_ARROW: 39,
    DOWN_ARROW: 40
  };

  exports.default = {
    Keys: KeyConstants,
    NumberFormat: {
      Decimal: ',',
      DecimalKey: KeyConstants.COMMA,
      Group: '.',
      GroupKey: KeyConstants.DOT,
      NegativeSign: KeyConstants.DASH
    },
    DefaultCurrency: DefaultCurrency
  };
});