define('ui-library/components/p-pagination/component', ['exports', 'ui-library/components/p-pagination/template', 'ember-decorators/object', 'ember-prop-types'], function (exports, _template, _object, _emberPropTypes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _desc, _value, _obj, _init, _init2;

  var ButtonCount = 7;
  var SliderWidth = ButtonCount - 4; // first/last page + slide left/right

  function setStart(component, from, pageCount) {
    // start is always the 3rd item from left. 1st item is for the 1st page,
    // 2nd is for either slider or page 2. (we'll add page 2 below if necessary)
    // end is vice versa
    var start = Math.max(3, from);
    var end = start + SliderWidth - 1;
    if (end > pageCount - 2) {
      end = pageCount - 2;
      if (end - start < SliderWidth) {
        // if the current page is towards the end,
        // make sure we display all buttons
        start = Math.max(3, end - SliderWidth + 1);
      }
    }
    component.setProperties({ start: start, end: end });
  }

  var TYPES = ['solid', 'outline'];

  exports.default = Ember.Component.extend(_emberPropTypes.default, (_dec = (0, _object.computed)('type'), _dec2 = (0, _object.computed)('_pageCount'), _dec3 = (0, _object.computed)('_currentPage'), _dec4 = (0, _object.computed)('start', 'end', 'canSlideLeft', 'canSlideRight'), _dec5 = (0, _object.computed)('start', 'needsSliders'), _dec6 = (0, _object.computed)('end', 'needsSliders', 'pageCount'), _dec7 = (0, _object.computed)('currentPage'), _dec8 = (0, _object.computed)('currentPage'), _dec9 = (0, _object.computed)('currentPage'), _dec10 = (0, _object.computed)('currentPage', 'pageCount'), (_obj = {
    // this component heavily copied from p-page-numbers
    layout: _template.default,
    tagName: 'ul',

    propTypes: {
      type: _emberPropTypes.PropTypes.string,
      pageCount: _emberPropTypes.PropTypes.number.isRequired,
      currentPage: _emberPropTypes.PropTypes.number.isRequired,
      onPageChanged: _emberPropTypes.PropTypes.func.isRequired
    },
    getDefaultProps: function getDefaultProps() {
      return {
        type: 'solid'
      };
    },


    'data-test-pagination': true,

    classNameBindings: ['typeClass'],

    _pageCount: null,
    _currentPage: null,
    isVisible: Ember.computed.gt('pageCount', 1),
    needsSliders: Ember.computed.gt('pageCount', ButtonCount),

    typeClass: function typeClass(type) {
      (false && !(TYPES.includes(type)) && Ember.assert('invalid value for type', TYPES.includes(type)));

      return 'type-' + type;
    },

    pageCount: {
      get: function get(_pageCount) {
        return _pageCount;
      },
      set: function set(value) {
        this.set('_pageCount', value);
        setStart(this, this.get('currentPage') - Math.floor(SliderWidth / 2), value);
        return value;
      }
    },

    currentPage: {
      get: function get(_currentPage) {
        return _currentPage;
      },
      set: function set(value) {
        this.set('_currentPage', value);
        setStart(this, value - Math.floor(SliderWidth / 2), this.get('pageCount'));
        return value;
      }
    },

    pages: function pages(start, end, canSlideLeft, canSlideRight) {
      var from = start;
      var to = end;
      // if we won't add a left slider then add page #2 to pages
      if (!canSlideLeft) {
        from -= 1;
      }

      // if we wont' add a right slider then add page #(count - 1) to pages
      if (!canSlideRight) {
        to += 1;
      }

      var pages = [];
      for (var page = from; page <= to; page++) {
        pages.push(page);
      }

      return pages;
    },
    canSlideLeft: function canSlideLeft(start, needsSliders) {
      return needsSliders && start > 3;
    },
    canSlideRight: function canSlideRight(end, needsSliders, pageCount) {
      return needsSliders && end < pageCount - 2;
    },
    previousPage: function previousPage(currentPage) {
      return currentPage - 1;
    },
    nextPage: function nextPage(currentPage) {
      return currentPage + 1;
    },
    prevPageEnabled: function prevPageEnabled(currentPage) {
      return currentPage !== 1;
    },
    nextPageEnabled: function nextPageEnabled(currentPage, pageCount) {
      return currentPage !== pageCount;
    },
    slideLeft: function slideLeft() {
      setStart(this, this.get('start') - SliderWidth, this.get('pageCount'));
    },
    slideRight: function slideRight() {
      setStart(this, this.get('start') + SliderWidth, this.get('pageCount'));
    },
    changePage: function changePage(page) {
      this.get('onPageChanged')(page);
    }
  }, (_applyDecoratedDescriptor(_obj, 'typeClass', [_dec], Object.getOwnPropertyDescriptor(_obj, 'typeClass'), _obj), _applyDecoratedDescriptor(_obj, 'pageCount', [_dec2], (_init = Object.getOwnPropertyDescriptor(_obj, 'pageCount'), _init = _init ? _init.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, 'currentPage', [_dec3], (_init2 = Object.getOwnPropertyDescriptor(_obj, 'currentPage'), _init2 = _init2 ? _init2.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init2;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, 'pages', [_dec4], Object.getOwnPropertyDescriptor(_obj, 'pages'), _obj), _applyDecoratedDescriptor(_obj, 'canSlideLeft', [_dec5], Object.getOwnPropertyDescriptor(_obj, 'canSlideLeft'), _obj), _applyDecoratedDescriptor(_obj, 'canSlideRight', [_dec6], Object.getOwnPropertyDescriptor(_obj, 'canSlideRight'), _obj), _applyDecoratedDescriptor(_obj, 'previousPage', [_dec7], Object.getOwnPropertyDescriptor(_obj, 'previousPage'), _obj), _applyDecoratedDescriptor(_obj, 'nextPage', [_dec8], Object.getOwnPropertyDescriptor(_obj, 'nextPage'), _obj), _applyDecoratedDescriptor(_obj, 'prevPageEnabled', [_dec9], Object.getOwnPropertyDescriptor(_obj, 'prevPageEnabled'), _obj), _applyDecoratedDescriptor(_obj, 'nextPageEnabled', [_dec10], Object.getOwnPropertyDescriptor(_obj, 'nextPageEnabled'), _obj), _applyDecoratedDescriptor(_obj, 'slideLeft', [_object.action], Object.getOwnPropertyDescriptor(_obj, 'slideLeft'), _obj), _applyDecoratedDescriptor(_obj, 'slideRight', [_object.action], Object.getOwnPropertyDescriptor(_obj, 'slideRight'), _obj), _applyDecoratedDescriptor(_obj, 'changePage', [_object.action], Object.getOwnPropertyDescriptor(_obj, 'changePage'), _obj)), _obj)));
});