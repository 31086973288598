define('ui-library/components/p-aside/component', ['exports', 'ui-library/components/p-aside/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    tagName: 'aside',
    attributeBindings: ['data-tns']
  });
});