define("ember-simple-auth-oidc/mixins/oidc-adapter-mixin", ["exports", "@babel/runtime/helpers/esm/defineProperty", "ember-simple-auth-oidc", "ember-simple-auth-oidc/config", "ember-simple-auth/mixins/data-adapter-mixin"], function (_exports, _defineProperty2, _emberSimpleAuthOidc, _config, _dataAdapterMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var authHeaderName = _config.default.authHeaderName,
      authPrefix = _config.default.authPrefix,
      tokenPropertyName = _config.default.tokenPropertyName;

  var _default = Ember.Mixin.create(_dataAdapterMixin.default, {
    session: Ember.inject.service(),

    /**
     * Watch the `data.authenticated.id_token` to recomputed the headers as
     * according to the openid-connect specification the `id_token` must always
     * be included.
     * https://openid.net/specs/openid-connect-core-1_0.html#TokenResponse
     */
    headers: Ember.computed("session.{data.authenticated.id_token,isAuthenticated}", function () {
      var headers = {};

      if (this.session.isAuthenticated) {
        var token = this.get("session.data.authenticated.".concat(tokenPropertyName));
        Object.assign(headers, (0, _defineProperty2.default)({}, authHeaderName, "".concat(authPrefix, " ").concat(token)));
      }

      return headers;
    }),
    ensureResponseAuthorized: function ensureResponseAuthorized(status) {
      if (status === 401) {
        (0, _emberSimpleAuthOidc.handleUnauthorized)(this.session);
      }
    }
  });

  _exports.default = _default;
});