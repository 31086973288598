define('ui-library/components/p-filtered-array-select/component', ['exports', 'ui-library/components/p-filtered-array-select/template', 'ember-decorators/object', 'ui-library/utils/string-util'], function (exports, _template, _object, _stringUtil) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.ArrayItem = undefined;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _desc, _value, _obj, _dec2, _dec3, _desc2, _value2, _obj2;

  // from https://stackoverflow.com/questions/6711971/regular-expressions-match-anything
  var MATCH_EVERYTHING = /[\s\S]*/g;

  var ArrayItem = exports.ArrayItem = Ember.Object.extend((_dec = (0, _object.computed)('searchTerm'), (_obj = {
    data: null,
    searchTerm: null,
    normalizedSearchTerm: function normalizedSearchTerm(searchTerm) {
      return normalizeString(searchTerm);
    }
  }, (_applyDecoratedDescriptor(_obj, 'normalizedSearchTerm', [_dec], Object.getOwnPropertyDescriptor(_obj, 'normalizedSearchTerm'), _obj)), _obj)));

  function normalizeString(value) {
    (false && !(typeof value === 'string') && Ember.assert('can not normalize non-string', typeof value === 'string'));

    return _stringUtil.default.escapeRegExpChars(_stringUtil.default.replaceTrChars(value)).toLowerCase();
  }

  exports.default = Ember.Component.extend((_dec2 = (0, _object.computed)('filter'), _dec3 = (0, _object.computed)('items.@each', 'filterRegex'), (_obj2 = {
    layout: _template.default,
    items: Ember.A(),
    linesOfIndex: [],
    filter: null,
    onNavigate: null,

    keyboardActivated: false,

    navigate: function navigate(item) {
      if (this.get('onNavigate') != null) {
        this.get('onNavigate')(item);
      }
    },
    filterRegex: function filterRegex(filter) {
      if (Ember.isEmpty(filter)) {
        return MATCH_EVERYTHING;
      }
      return new RegExp(normalizeString(filter), 'g');
    },
    filteredItems: function filteredItems(items, filterRegex) {
      if (items == null) {
        return Ember.A();
      }
      return items.filter(function (item) {
        return item.get('normalizedSearchTerm').match(filterRegex);
      });
    }
  }, (_applyDecoratedDescriptor(_obj2, 'navigate', [_object.action], Object.getOwnPropertyDescriptor(_obj2, 'navigate'), _obj2), _applyDecoratedDescriptor(_obj2, 'filterRegex', [_dec2], Object.getOwnPropertyDescriptor(_obj2, 'filterRegex'), _obj2), _applyDecoratedDescriptor(_obj2, 'filteredItems', [_dec3], Object.getOwnPropertyDescriptor(_obj2, 'filteredItems'), _obj2)), _obj2)));
});