define('ui-library/components/p-list-body/component', ['exports', 'ui-library/components/p-list-body/template', 'ui-library/helpers/is-phoenix'], function (exports, _template, _isPhoenix) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    rows: [],
    columnWidths: [],

    selectedAny: false,
    hasActiveFilters: false,
    onToggleSelectItem: null,
    usingPhoenix: (0, _isPhoenix.isPhoenix)(),

    actions: {
      resetFiltersClicked: function resetFiltersClicked() {
        this.get('onResetFilters')();
      },
      toggleItemSelection: function toggleItemSelection(row) {
        if (this.get('onToggleSelectItem')) {
          this.get('onToggleSelectItem')(row.item);
        }
      }
    }
  });
});