define('ui-library/components/p-add-area/component', ['exports', 'ui-library/components/p-add-area/template', 'ember-prop-types'], function (exports, _template, _emberPropTypes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_emberPropTypes.default, {
    propTypes: {
      onClick: _emberPropTypes.PropTypes.func
    },
    getDefaultProps: function getDefaultProps() {
      return {
        onClick: null
      };
    },


    layout: _template.default,
    tagName: 'div',
    'data-test-add-area': true,

    click: function click(e) {
      if (this.get('disabled')) {
        e.preventDefault();
        return false;
      }

      if (this.onClick) {
        // pass onclick to p-add-area in order to get rid of sendAction deprecations (after Ember 3.4)
        // https://deprecations.emberjs.com/v3.x/#toc_ember-component-send-action
        this.onClick(e);
      } else {
        this.sendAction('action', e);
      }
    }
  });
});