define('ui-library/components/p-chart-category-list/component', ['exports', 'ui-library/components/p-chart-category-list/template', 'ember-decorators/object', 'ui-library/utils/string-util', 'ui-library/helpers/is-phoenix'], function (exports, _template, _object, _stringUtil, _isPhoenix) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _desc, _value, _obj;

  exports.default = Ember.Component.extend((_dec = (0, _object.computed)('bgColor'), _dec2 = (0, _object.computed)('name'), _dec3 = (0, _object.computed)('hasParents', 'fullPath'), _dec4 = (0, _object.computed)('parentNames'), _dec5 = (0, _object.computed)('parentNames'), _dec6 = (0, _object.computed)('parentNames', 'name'), (_obj = {
    layout: _template.default,
    tagName: 'li',
    attributeBindings: ['title'],
    classNameBindings: ['hasParents:tag-nested', 'active:active'],
    isVisible: Ember.computed.notEmpty('name'),
    hasParents: Ember.computed.gt('fullPath.length', 0),
    'data-test-p-chart-category-list': true,

    tagStyle: function tagStyle(bgColor) {
      if (bgColor) {
        return Ember.String.htmlSafe('background-color:' + bgColor + ';');
      }
    },
    categoryName: function categoryName(name) {
      return (0, _isPhoenix.isPhoenix)() ? _stringUtil.default.toTitle(name) : _stringUtil.default.toUpper(name);
    },
    parentNames: function parentNames(hasParents, fullPath) {
      if (!hasParents) {
        return Ember.A();
      }

      return fullPath.map(function (parent) {
        return _stringUtil.default.toUpper(parent);
      });
    },
    grandParents: function grandParents(parentNames) {
      return parentNames.slice(0, parentNames.length - 1);
    },
    lastParentName: function lastParentName(parentNames) {
      if (parentNames && parentNames.length > 0) {
        return parentNames[parentNames.length - 1];
      }
    },
    title: function title(parentNames, categoryName) {
      var parents = parentNames.map(function (parent) {
        return parent + ' > ';
      }).join('');
      var name = _stringUtil.default.toUpper(categoryName);

      return '' + parents + name;
    },
    click: function click(e) {
      if (this.get('disabled')) {
        e.preventDefault();
        return false;
      }

      if (this.onClick) {
        // pass onclick to p-category in order to get rid of sendAction deprecations (after Ember 3.4)
        // https://deprecations.emberjs.com/v3.x/#toc_ember-component-send-action
        this.onClick(e);
      } else {
        this.sendAction('action', e);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, 'tagStyle', [_dec], Object.getOwnPropertyDescriptor(_obj, 'tagStyle'), _obj), _applyDecoratedDescriptor(_obj, 'categoryName', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'categoryName'), _obj), _applyDecoratedDescriptor(_obj, 'parentNames', [_dec3], Object.getOwnPropertyDescriptor(_obj, 'parentNames'), _obj), _applyDecoratedDescriptor(_obj, 'grandParents', [_dec4], Object.getOwnPropertyDescriptor(_obj, 'grandParents'), _obj), _applyDecoratedDescriptor(_obj, 'lastParentName', [_dec5], Object.getOwnPropertyDescriptor(_obj, 'lastParentName'), _obj), _applyDecoratedDescriptor(_obj, 'title', [_dec6], Object.getOwnPropertyDescriptor(_obj, 'title'), _obj)), _obj)));
});