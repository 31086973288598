define('ui-library/components/p-list-header/component', ['exports', 'ui-library/components/p-list-header/template', 'ember-decorators/object'], function (exports, _template, _object) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _desc, _value, _obj;

  exports.default = Ember.Component.extend((_dec = (0, _object.computed)('selectedAll', 'selectedAny'), (_obj = {
    layout: _template.default,
    attributeBindings: ['data-tid'],
    columnWidths: [],
    displaySelectAllToggle: false,
    selectedAll: false,
    selectedAny: false,
    onToggleSelectAll: null,

    selectToggleState: function selectToggleState(selectedAll, selectedAny) {
      if (selectedAll) {
        return 'checked';
      }
      if (selectedAny) {
        return 'indeterminate';
      }
      return 'unchecked';
    },


    actions: {
      selectToggleClicked: function selectToggleClicked() {
        if (this.get('onToggleSelectAll')) {
          this.get('onToggleSelectAll')();
        }
      }
    }
  }, (_applyDecoratedDescriptor(_obj, 'selectToggleState', [_dec], Object.getOwnPropertyDescriptor(_obj, 'selectToggleState'), _obj)), _obj)));
});