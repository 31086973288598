define('ember-plupload/system/flatten', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function flatten(array) {
    var flattened = [];
    for (var i = 0, len = array.length; i < len; i++) {
      var value = array[i];
      if (Ember.isArray(value)) {
        flattened.push.apply(flattened, _toConsumableArray(flatten(value)));
      } else {
        flattened.push(value);
      }
    }
    return flattened;
  }

  exports.default = flatten;
});