define("ui-library/components/p-widget-action-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ok5FHn39",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"span\",true],[8],[0,\"\\n  \"],[14,1],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"i\",true],[11,\"class\",[22,\"actionClass\"]],[8],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-library/components/p-widget-action-row/template.hbs"
    }
  });

  _exports.default = _default;
});