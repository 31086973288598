define('ui-library/components/p-list-select-toggle/component', ['exports', 'ui-library/components/p-list-select-toggle/template', 'ember-decorators/object'], function (exports, _template, _object) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _desc, _value, _obj;

  var STATES = ['checked', 'unchecked', 'indeterminate'];

  exports.default = Ember.Component.extend((_dec = (0, _object.computed)('row', 'row.isSelected', 'state'), (_obj = {
    layout: _template.default,
    tagName: 'span',
    state: 'unchecked',
    row: null,
    revealed: false,
    hideContent: Ember.computed.or('row.isToggleRevealed', 'revealed'),
    onClick: null,
    allowBatchSelection: Ember.computed.alias('row.list.allowBatchSelection'),
    classNameBindings: ['stateClass', 'hideContent', 'allowBatchSelection'],
    attributeBindings: ['data-tid'],
    'data-test-list-select-toggle': true,

    stateClass: function stateClass(row, isSelected, state) {
      (false && !(STATES.includes(state)) && Ember.assert('invalid value for p-list-select-toggle state', STATES.includes(state)));


      var stateName = state;
      if (row != null) {
        stateName = isSelected ? 'checked' : 'unchecked';
      }
      return 'state-' + stateName;
    },
    click: function click(e) {
      if (this.get('allowBatchSelection') || this.get('onClick')) {
        e.preventDefault();
        e.stopPropagation();
      }

      if (this.get('allowBatchSelection')) {
        this.toggleProperty('row.isSelected');
      }

      if (this.get('onClick')) {
        this.get('onClick')();
      }
    }
  }, (_applyDecoratedDescriptor(_obj, 'stateClass', [_dec], Object.getOwnPropertyDescriptor(_obj, 'stateClass'), _obj)), _obj)));
});