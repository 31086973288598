define("ui-library/components/p-radio-label/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "E3mTJcSR",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"button\"],[[28,\"component\",[\"p-one-way-radio\"],[[\"data-test-button\"],[true]]]]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-library/components/p-radio-label/template.hbs"
    }
  });

  _exports.default = _default;
});