define('ui-library/components/p-filter-collection-with-checkboxes/component', ['exports', 'ui-library/components/p-filter-collection-with-checkboxes/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    filter: null,
    onApplyFilters: null,
    onCancel: null,
    showApplyButton: false,
    attributeBindings: ['data-test-filter-collection-with-checkboxes'],
    'data-test-filter-collection-with-checkboxes': true,

    _sendApplyFilters: function _sendApplyFilters() {
      if (this.get('onApplyFilters')) {
        this.get('onApplyFilters')();
      }
    },


    actions: {
      toggleSelection: function toggleSelection(value) {
        if (this.get('filter')) {
          this.get('filter').toggleSelection(value);
        }
      },
      selectItem: function selectItem(value) {
        if (this.get('filter')) {
          this.get('filter').selectValue(value);
        }
        this._sendApplyFilters();
      },
      apply: function apply() {
        this._sendApplyFilters();
      },
      cancel: function cancel() {
        if (this.get('filter')) {
          this.get('filter').reset();
        }
        if (this.get('onCancel')) {
          this.get('onCancel')();
        }
      }
    }
  });
});