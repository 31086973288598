define("ui-library/components/p-flash-msg/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XcwXVGwP",
    "block": "{\"symbols\":[\"flash\",\"component\",\"flash\",\"close\"],\"statements\":[[4,\"each\",[[24,[\"flashMessages\",\"arrangedQueue\"]]],null,{\"statements\":[[4,\"flash-message\",null,[[\"flash\"],[[23,1,[]]]],{\"statements\":[[0,\"    \"],[7,\"span\",true],[8],[1,[23,3,[\"message\"]],false],[9],[0,\"\\n    \"],[7,\"a\",false],[12,\"href\",\"#\"],[12,\"class\",\"alert-close\"],[3,\"action\",[[23,0,[]],[23,4,[]]]],[8],[0,\"×\"],[9],[0,\"\\n\"]],\"parameters\":[2,3,4]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-library/components/p-flash-msg/template.hbs"
    }
  });

  _exports.default = _default;
});