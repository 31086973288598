define('ui-library/components/p-chart-card/component', ['exports', 'ui-library/components/p-chart-card/template', 'ember-prop-types'], function (exports, _template, _emberPropTypes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_emberPropTypes.default, {
    propTypes: {
      title: _emberPropTypes.PropTypes.string
    },
    layout: _template.default,
    tagName: 'div',
    'data-test-p-chart-card': true,

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
    }
  });
});