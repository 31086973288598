define('ui-library/utils/macros/filters-collection', ['exports', 'ui-library/utils/filter-value-collection'], function (exports, _filterValueCollection) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = filtersCollection;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function filtersCollection(filterDefinitions) {
    var _Ember;

    var keys = Ember.isEmpty(filterDefinitions) ? [] : Object.keys(filterDefinitions);
    var clone = Ember.copy(filterDefinitions, true);

    return (_Ember = Ember).computed.apply(_Ember, _toConsumableArray(keys).concat([function () {
      var _this = this;

      var i18n = this.get('i18n');
      keys.forEach(function (key) {
        var value = clone[key];
        Ember.set(value, 'displayName', i18n.t(value.displayNameKey));
      });
      var filtersArray = (0, _filterValueCollection.makeFiltersArray)(clone, function (key) {
        return _this.get(key);
      });

      return _filterValueCollection.default.create({ filters: filtersArray });
    }]));
  }
});