define('ui-library/components/p-tag-display/component', ['exports', 'ui-library/components/p-tag-display/template', 'ember-decorators/object', 'ui-library/utils/string-util'], function (exports, _template, _object, _stringUtil) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _desc, _value, _obj;

  exports.default = Ember.Component.extend((_dec = (0, _object.computed)('name'), (_obj = {
    layout: _template.default,
    tagName: 'span',
    attributeBindings: ['capitalizedName:title'],
    isVisible: Ember.computed.notEmpty('name'),

    capitalizedName: function capitalizedName(name) {
      return _stringUtil.default.toUpper(name);
    }
  }, (_applyDecoratedDescriptor(_obj, 'capitalizedName', [_dec], Object.getOwnPropertyDescriptor(_obj, 'capitalizedName'), _obj)), _obj)));
});