define("ui-library/components/p-filter-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RT1lLMDI",
    "block": "{\"symbols\":[\"dropdown\",\"&default\"],\"statements\":[[4,\"basic-dropdown\",null,[[\"onClose\",\"initiallyOpened\"],[[28,\"action\",[[23,0,[]],\"closeFilter\"],null],[24,[\"isOpen\"]]]],{\"statements\":[[0,\"  \"],[14,2,[[23,1,[]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-library/components/p-filter-item/template.hbs"
    }
  });

  _exports.default = _default;
});