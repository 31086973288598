define('ui-library/components/p-one-way-amount-or-percentage/component', ['exports', 'ui-library/components/p-one-way-amount-or-percentage/template', 'ember-decorators/object', 'ember-decorators/object/evented', 'ui-library/helpers/currency-symbol', 'ember-keyboard'], function (exports, _template, _object, _evented, _currencySymbol, _emberKeyboard) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _dec3, _desc, _value, _obj;

  exports.default = Ember.Component.extend(_emberKeyboard.EKMixin, (_dec = (0, _object.computed)('isPercentage', 'currency'), _dec2 = (0, _evented.on)((0, _emberKeyboard.keyDown)('ArrowDown')), _dec3 = (0, _evented.on)((0, _emberKeyboard.keyDown)('ArrowUp')), (_obj = {
    layout: _template.default,
    prepend: null,
    currency: null,
    type: 'percentage',
    number: 0,
    numberupdate: null,
    typeupdate: null,
    dataType: 'decimal2',
    'data-test-amount-or-percentage': true,

    keyboardActivated: false,
    keyboardFirstResponder: true,

    isPercentage: Ember.computed.equal('type', 'percentage'),
    isOpen: false,

    append: function append(isPercentage, currency) {
      return isPercentage ? '%' : Ember.String.htmlSafe((0, _currencySymbol.currencySymbol)([currency]));
    },
    toggleDropdown: function toggleDropdown() {
      this.toggleProperty('isOpen');
    },
    changeNumber: function changeNumber(number) {
      if (this.get('numberupdate') != null) {
        this.get('numberupdate')(number);
      }
    },
    changeType: function changeType(type) {
      this.set('isOpen', false);
      if (this.get('typeupdate') != null) {
        this.get('typeupdate')(type);
      }
    },
    changeToAmount: function changeToAmount(e) {
      e.preventDefault();
      if (this.get('isPercentage') && this.get('typeupdate') != null) {
        this.get('typeupdate')('amount');
      }
    },
    changeToPercentage: function changeToPercentage(e) {
      e.preventDefault();
      if (!this.get('isPercentage') && this.get('typeupdate') != null) {
        this.get('typeupdate')('percentage');
      }
    }
  }, (_applyDecoratedDescriptor(_obj, 'append', [_dec], Object.getOwnPropertyDescriptor(_obj, 'append'), _obj), _applyDecoratedDescriptor(_obj, 'toggleDropdown', [_object.action], Object.getOwnPropertyDescriptor(_obj, 'toggleDropdown'), _obj), _applyDecoratedDescriptor(_obj, 'changeNumber', [_object.action], Object.getOwnPropertyDescriptor(_obj, 'changeNumber'), _obj), _applyDecoratedDescriptor(_obj, 'changeType', [_object.action], Object.getOwnPropertyDescriptor(_obj, 'changeType'), _obj), _applyDecoratedDescriptor(_obj, 'changeToAmount', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'changeToAmount'), _obj), _applyDecoratedDescriptor(_obj, 'changeToPercentage', [_dec3], Object.getOwnPropertyDescriptor(_obj, 'changeToPercentage'), _obj)), _obj)));
});