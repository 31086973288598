define('ui-library/components/p-calendar/component', ['exports', 'ui-library/components/p-calendar/template', 'moment'], function (exports, _template, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    onSelection: null,
    value: null,
    _value: null,
    format: 'DD.MM.YYYY',
    yearRange: '10', // '2004,2008', '2004,currentYear'
    disabled: false,
    readonly: false,
    minDate: null,
    _minDate: null,
    maxDate: null,
    _maxDate: null,

    shownYear: null,
    shownMonth: null,
    highlightFrom: null,
    _highlightFrom: null,
    highlightTo: null,
    _highlightTo: null,
    'data-test-calendar': true,

    didReceiveAttrs: function didReceiveAttrs() {
      this.setInternalDates();
    },
    setInternalDates: function setInternalDates() {
      if (!Ember.isEmpty(this.get('value'))) {
        this.set('_value', this.get('value').clone().toDate());
      } else {
        this.set('_value', null);
      }

      if (!Ember.isEmpty(this.get('highlightFrom')) && !Ember.isEmpty(this.get('highlightTo'))) {
        this.setProperties({
          _highlightFrom: this.get('highlightFrom').clone(),
          _highlightTo: this.get('highlightTo').clone()
        });
      } else {
        this.setProperties({
          _highlightFrom: null,
          _highlightTo: null
        });
      }

      if (!Ember.isEmpty(this.get('minDate'))) {
        this.set('_minDate', this.get('minDate').clone().toDate());
      } else {
        this.set('_minDate', null);
      }

      if (!Ember.isEmpty(this.get('maxDate'))) {
        this.set('_maxDate', this.get('maxDate').clone().toDate());
      } else {
        this.set('_maxDate', null);
      }
    },
    _highlightDates: function _highlightDates() {
      if (Ember.isEmpty(this.get('_highlightFrom')) || Ember.isEmpty(this.get('_highlightTo'))) {
        return;
      }

      var fromDate = this.get('_highlightFrom');
      var toDate = this.get('_highlightTo');

      var shownYear = this.get('shownYear');
      var shownMonth = this.get('shownMonth');

      this.$('tbody td').removeClass('is-in-range is-range-end is-range-start').each(function () {
        var $td = Ember.$(this);

        // check if the cell is a date field
        if ($td.data('day') !== undefined) {
          var cursor = (0, _moment.default)([shownYear, shownMonth, $td.data('day')]);

          if (cursor.isBetween(fromDate, toDate, 'day')) {
            $td.addClass('is-in-range');
          } else if (cursor.isSame(fromDate, 'day')) {
            $td.addClass('is-range-start is-in-range');
          } else if (cursor.isSame(toDate, 'day')) {
            $td.addClass('is-range-end is-in-range');
          }
        }
      });
    },
    _setShownYearAndMonth: function _setShownYearAndMonth() {
      var year = this.$('select.pika-select-year option[selected="selected"]').text();
      var month = this.$('select.pika-select-month option[selected="selected"]').attr('value');

      this.set('shownYear', year);
      this.set('shownMonth', month);
    },


    actions: {
      select: function select(selectedDate) {
        this.sendAction('onSelection', (0, _moment.default)(selectedDate));
      },
      draw: function draw() {
        this._setShownYearAndMonth();
        this._highlightDates();
      }
    }
  });
});