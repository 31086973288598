define('ui-library/components/p-array-select/component', ['exports', 'ui-library/components/p-array-select/template', 'ember-decorators/object/evented', 'ui-library/utils/array-prev-next-navigator', 'ember-keyboard'], function (exports, _template, _evented, _arrayPrevNextNavigator, _emberKeyboard) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _desc, _value, _obj;

  exports.default = Ember.Component.extend(_emberKeyboard.EKMixin, (_dec = (0, _evented.on)((0, _emberKeyboard.keyDown)('ArrowUp')), _dec2 = (0, _evented.on)((0, _emberKeyboard.keyDown)('ArrowDown')), (_obj = {
    layout: _template.default,
    items: Ember.A(),
    linesOfIndex: [],
    onNavigate: null,

    keyboardActivated: false,
    keyboardFirstResponder: true,

    _navigator: null,
    _lastNavigatedItem: null,

    isVisible: Ember.computed.notEmpty('items'),

    didInsertElement: function didInsertElement() {
      var navigator = _arrayPrevNextNavigator.default.create();
      navigator.bind(this, 'items');
      this.set('_navigator', navigator);
      this.addObserver('_navigator.currentItem', this, this.onNavigatedItemChange);
    },
    willDestroyElement: function willDestroyElement() {
      this.removeObserver('_navigator.currentItem', this, this.onNavigatedItemChange);
      this.get('_navigator').unbind();
      this.set('_navigator', null);
    },
    highlightPrevious: function highlightPrevious(event) {
      this.get('_navigator').prev();
      event.preventDefault();
      event.stopPropagation();
    },
    highlightNext: function highlightNext(event) {
      this.get('_navigator').next();
      event.preventDefault();
      event.stopPropagation();
    },
    onNavigatedItemChange: function onNavigatedItemChange() {
      var _this = this;

      var currentItem = this.get('_navigator.currentItem');
      if (currentItem !== this.get('_lastNavigatedItem')) {
        Ember.run.once(function () {
          _this.set('_lastNavigatedItem', currentItem);
          if (_this.get('onNavigate') != null) {
            _this.get('onNavigate')(currentItem);
          }
        });
      }
    }
  }, (_applyDecoratedDescriptor(_obj, 'highlightPrevious', [_dec], Object.getOwnPropertyDescriptor(_obj, 'highlightPrevious'), _obj), _applyDecoratedDescriptor(_obj, 'highlightNext', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'highlightNext'), _obj)), _obj)));
});