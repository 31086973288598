define('ui-library/components/p-table-header/component', ['exports', 'ui-library/components/p-table-header/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    'data-test-table-header': true,
    columnWidths: []
  });
});