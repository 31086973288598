define("ui-library/components/p-list-empty-state/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QYf3T6WF",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"empty-state-content\"],[8],[0,\"\\n  \"],[7,\"h2\",true],[10,\"data-test-title\",\"true\"],[8],[1,[22,\"title\"],false],[9],[0,\"\\n  \"],[7,\"p\",true],[10,\"class\",\"c-textLight\"],[10,\"data-test-content\",\"true\"],[8],[14,1],[9],[0,\"\\n\"],[4,\"p-button\",null,[[\"tagName\",\"href\",\"size\",\"data-test-button\"],[\"a\",[24,[\"returnPath\"]],\"small\",true]],{\"statements\":[[0,\"    \"],[7,\"i\",true],[10,\"class\",\"fa fa-arrow-left\"],[8],[9],[0,\" \"],[1,[22,\"buttonText\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-library/components/p-list-empty-state/template.hbs"
    }
  });

  _exports.default = _default;
});