define("ui-library/components/p-progress-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BQYnCxfX",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"displayProgressValue\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"data-test-counter\",\"\"],[8],[1,[22,\"step\"],false],[0,\" / \"],[1,[22,\"count\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[10,\"data-test-bar\",\"\"],[11,\"style\",[22,\"barStyle\"]],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-library/components/p-progress-bar/template.hbs"
    }
  });

  _exports.default = _default;
});