define("ui-library/components/p-list-body-empty/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KqA2XGJ+",
    "block": "{\"symbols\":[],\"statements\":[[0,\"Default empty state list body component\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-library/components/p-list-body-empty/template.hbs"
    }
  });

  _exports.default = _default;
});