define('ui-library/helpers/currency-symbol', ['exports', 'ui-library/helpers/currency-icon'], function (exports, _currencyIcon) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.currencySymbol = currencySymbol;


  // the old code has a named argument called safeString
  // when set to true the return value is wrapped in a SafeString
  // this allows the output to be used in templates
  //
  // Do not do that!!!
  //
  // Instead use triple curlies in the template, which renders html without escaping

  function currencySymbol(params) {
    return '<i class="fa ' + (0, _currencyIcon.currencyIcon)(params) + '"></i>';
  }

  exports.default = Ember.Helper.helper(currencySymbol);
});