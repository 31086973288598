define("ui-library/components/p-filter-collection-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+cYpxHqP",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"a\",false],[12,\"class\",\"hidden-cb\"],[12,\"href\",\"#\"],[12,\"data-test-filter-collection-item-toggle-selected\",[29,[[22,\"isSelected\"]]]],[3,\"action\",[[23,0,[]],\"toggleSelection\"]],[8],[0,\"\\n  \"],[7,\"i\",true],[11,\"class\",[29,[\"checkbox fa \",[28,\"if\",[[24,[\"isSelected\"]],\"fa-check-square\",\"fa-square-o\"],null]]]],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"a\",false],[12,\"href\",\"#\"],[12,\"data-test-filter-collection-item-yielded\",\"\"],[3,\"action\",[[23,0,[]],\"selectItem\"]],[8],[0,\"\\n  \"],[14,1],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-library/components/p-filter-collection-item/template.hbs"
    }
  });

  _exports.default = _default;
});