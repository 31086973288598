define("ui-library/components/p-amount-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cS1QD9OZ",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"displayValue\"]]],null,{\"statements\":[[4,\"if\",[[28,\"or\",[[28,\"is-asist\",null,null],[28,\"is-phoenix\",null,null]],null]],null,{\"statements\":[[4,\"if\",[[24,[\"currencySymbol\"]]],null,{\"statements\":[[1,[28,\"currency-symbol\",[[24,[\"currency\"]]],null],true]],\"parameters\":[]},null],[1,[24,[\"formattedParts\",\"left\"]],false],[7,\"span\",true],[8],[4,\"if\",[[24,[\"decimals\"]]],null,{\"statements\":[[1,[24,[\"formattedParts\",\"decimalSeperator\"]],false],[7,\"span\",true],[10,\"class\",\"decimal-right\"],[8],[1,[24,[\"formattedParts\",\"right\"]],false],[9]],\"parameters\":[]},null],[9]],\"parameters\":[]},{\"statements\":[[1,[24,[\"formattedParts\",\"left\"]],false],[7,\"span\",true],[8],[4,\"if\",[[24,[\"decimals\"]]],null,{\"statements\":[[1,[24,[\"formattedParts\",\"decimalSeperator\"]],false],[1,[24,[\"formattedParts\",\"right\"]],false]],\"parameters\":[]},null],[4,\"if\",[[24,[\"currencySymbol\"]]],null,{\"statements\":[[1,[28,\"currency-symbol\",[[24,[\"currency\"]]],null],true]],\"parameters\":[]},null],[9]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-library/components/p-amount-display/template.hbs"
    }
  });

  _exports.default = _default;
});