define('ui-library/components/p-list/component', ['exports', 'ui-library/components/p-list/template', 'ember-decorators/object'], function (exports, _template, _object) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _dec3, _dec4, _desc, _value, _obj, _init;

  var SELECT_TOGGLE_WIDTH = Ember.String.htmlSafe('width: 70px;');

  var Row = Ember.Object.extend({
    list: null,
    data: null,
    isSelected: false,
    isToggleRevealed: Ember.computed.alias('list.selectedAny')
  });

  exports.default = Ember.Component.extend((_dec = (0, _object.computed)('_rows.@each.isSelected'), _dec2 = (0, _object.computed)('_items.length', 'selectedRows.length'), _dec3 = (0, _object.observes)('selectedRows.[]'), _dec4 = (0, _object.computed)('columnWidths.@each', 'allowBatchSelection'), (_obj = {
    layout: _template.default,
    classNameBindings: ['hasStickyHeader', 'hasStickyFooter'],
    attributeBindings: ['data-tns'],
    hasStickyHeader: false,
    hasStickyFooter: false,
    hasActiveFilters: false,
    columnWidths: Ember.A(),
    emptyStateComponentName: null,
    'data-test-list': true,

    allowBatchSelection: false,
    selectedRows: function selectedRows(rows) {
      if (rows == null) {
        return Ember.A();
      }
      return Ember.A(rows.filterBy('isSelected', true));
    },

    // selectedItems: Ember.computed.mapBy('selectedRows', 'data'),
    selectedAny: Ember.computed.gt('selectedRows.length', 0),
    selectedAll: function selectedAll(itemCount, selectedCount) {
      return itemCount > 0 && itemCount == selectedCount;
    },


    onSelectionChanged: null,
    onSelectedRowsChange: function onSelectedRowsChange() {
      var _this = this;

      Ember.run.once(function () {
        if (_this.get('onSelectionChanged')) {
          var selectedItems = _this.get('selectedRows').mapBy('data');
          _this.get('onSelectionChanged')(selectedItems);
        }
      });
    },


    _items: Ember.A(),
    _rows: Ember.A(),

    items: {
      get: function get() {
        return this.get('_items');
      },
      set: function set(value) {
        var _this2 = this;

        this.set('_items', value);
        if (value != null && typeof value.then === 'function') {
          value.then(function (resolvedValue) {
            return _this2._makeRows(resolvedValue);
          });
        } else {
          this._makeRows(value);
        }
        return value;
      }
    },

    _makeRows: function _makeRows(items) {
      var _this3 = this;

      if (items != null && typeof items.map === 'function') {
        var rows = items.map(function (item) {
          return Row.create({ list: _this3, data: item });
        });
        this.set('_rows', Ember.A(rows));
      } else {
        this.set('_rows', Ember.A());
      }
    },
    safeColumnWidths: function safeColumnWidths(columnWidths, allowBatchSelection) {
      var widths = columnWidths.map(function (width) {
        return Ember.String.htmlSafe('width: ' + width + ';');
      });
      if (allowBatchSelection) {
        widths.splice(0, 0, SELECT_TOGGLE_WIDTH);
      }
      return widths;
    },


    actions: {
      bubbleClearFilters: function bubbleClearFilters() {
        this.get('onResetFilters')();
      },
      toggleSelectAll: function toggleSelectAll() {
        var _this4 = this;

        (false && !(this.get('allowBatchSelection')) && Ember.assert('List does not allow batch selection', this.get('allowBatchSelection')));

        Ember.run(function () {
          var newValue = !_this4.get('selectedAny');
          _this4.get('_rows').forEach(function (row) {
            return row.set('isSelected', newValue);
          });
        });
      }
    }
  }, (_applyDecoratedDescriptor(_obj, 'selectedRows', [_dec], Object.getOwnPropertyDescriptor(_obj, 'selectedRows'), _obj), _applyDecoratedDescriptor(_obj, 'selectedAll', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'selectedAll'), _obj), _applyDecoratedDescriptor(_obj, 'onSelectedRowsChange', [_dec3], Object.getOwnPropertyDescriptor(_obj, 'onSelectedRowsChange'), _obj), _applyDecoratedDescriptor(_obj, 'items', [_object.computed], (_init = Object.getOwnPropertyDescriptor(_obj, 'items'), _init = _init ? _init.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, 'safeColumnWidths', [_dec4], Object.getOwnPropertyDescriptor(_obj, 'safeColumnWidths'), _obj)), _obj)));
});