define("ember-mask-input/ember-mask-input/masks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.MASK_MAPPING = void 0;
  var MASK_MAPPING = {
    'iban': 'AAAA AAAA AAAA AAAA AAAA AAAA AAAA AAAA AA',
    'phone-no': '(000) 000 00 00',
    'credit-card': '0000 0000 0000 0000'
  };
  _exports.MASK_MAPPING = MASK_MAPPING;
});