define('ui-library/components/p-date-picker/component', ['exports', 'ui-library/components/p-date-picker/template', 'moment', 'ember-decorators/object'], function (exports, _template, _moment, _object) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _dec3, _desc, _value, _obj, _init, _init2, _init3;

  exports.default = Ember.Component.extend((_dec = (0, _object.computed)('_value'), _dec2 = (0, _object.computed)('_minDate'), _dec3 = (0, _object.computed)('_maxDate'), (_obj = {
    'data-test-date-picker': true,
    layout: _template.default,
    i18n: Ember.inject.service(),

    update: null,
    onOpen: null,
    onClose: null,
    onDraw: null,
    disabled: false,
    placeholder: null,
    allowUnknown: false,
    displayShortcuts: false,
    isCalendarVisible: false,

    _inputValue: null,
    _value: null,
    _minDate: null,
    _maxDate: null,
    unknown: null,

    // WILL BE DEPRECATED
    onSelection: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('beginningOfNextMonth', (0, _moment.default)().add(1, 'M').startOf('month').toDate());
      this.set('oneWeekLater', (0, _moment.default)().add(7, 'd').toDate());
      this.set('twoWeeksLater', (0, _moment.default)().add(14, 'd').toDate());
      this.set('oneMonthLater', (0, _moment.default)().add(1, 'M').toDate());
      this.set('twoMonthsLater', (0, _moment.default)().add(2, 'M').toDate());
    },

    value: {
      get: function get(currentValue) {
        return currentValue;
      },
      set: function set(newValue) {
        if (_moment.default.isMoment(newValue)) {
          Ember.deprecate('use of moment objects for value will be deprecated. Use a native date', false, {
            id: 'p-date-picker-value',
            until: '0.3.0'
          });
          this.set('_value', newValue.clone().toDate());
          this.set('_inputValue', newValue.clone().format('DD.MM.YYYY'));
          return newValue;
        }
        this.set('_value', !newValue ? newValue : new Date(newValue.getTime()));
        this.set('_inputValue', !newValue ? this.get('i18n').t('pDatePicker.placeholder.unknown') : (0, _moment.default)(new Date(newValue.getTime())).format('DD.MM.YYYY'));
        return newValue;
      }
    },

    minDate: {
      get: function get(currentMinDate) {
        return currentMinDate;
      },
      set: function set(newMinDate) {
        if (_moment.default.isMoment(newMinDate)) {
          Ember.deprecate('use of moment objects for minDate will be deprecated. Use a native date', false, { id: 'p-date-picker-minDate', until: '0.3.0' });
          this.set('_minDate', newMinDate.clone().toDate());
          return newMinDate;
        }
        this.set('_minDate', newMinDate == null ? newMinDate : new Date(newMinDate.getTime()));
        return newMinDate;
      }
    },

    maxDate: {
      get: function get(currentMaxDate) {
        return currentMaxDate;
      },
      set: function set(newMaxDate) {
        if (_moment.default.isMoment(newMaxDate)) {
          Ember.deprecate('use of moment objects for maxDate will be deprecated. Use a native date', false, { id: 'p-date-picker-maxDate', until: '0.3.0' });
          this.set('_maxDate', newMaxDate.clone().toDate());
          return newMaxDate;
        }
        this.set('_maxDate', newMaxDate == null ? newMaxDate : new Date(newMaxDate.getTime()));
        return newMaxDate;
      }
    },

    _select: function _select(selectedDate) {
      if (this.get('update') != null) {
        this.get('update')(selectedDate);
      } else if (this.get('onSelection') != null) {
        Ember.deprecate('onSelection will be deprecated. Use update', false, {
          id: 'p-date-picker-onSelection',
          until: '0.3.0'
        });
        this.get('onSelection')((0, _moment.default)(selectedDate));
      }
      this.set('isCalendarVisible', false);
    },
    _open: function _open() {
      this.set('isCalendarVisible', true);
      this.sendAction('onOpen');
    },
    _close: function _close() {
      this.set('isCalendarVisible', false);
      this.sendAction('onClose');
    },
    _draw: function _draw() {
      this.sendAction('onDraw');
    },
    updateBoundValue: function updateBoundValue(newValue) {
      this.set('_inputValue', newValue);
    }
  }, (_applyDecoratedDescriptor(_obj, 'value', [_dec], (_init = Object.getOwnPropertyDescriptor(_obj, 'value'), _init = _init ? _init.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, 'minDate', [_dec2], (_init2 = Object.getOwnPropertyDescriptor(_obj, 'minDate'), _init2 = _init2 ? _init2.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init2;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, 'maxDate', [_dec3], (_init3 = Object.getOwnPropertyDescriptor(_obj, 'maxDate'), _init3 = _init3 ? _init3.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init3;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, '_select', [_object.action], Object.getOwnPropertyDescriptor(_obj, '_select'), _obj), _applyDecoratedDescriptor(_obj, '_open', [_object.action], Object.getOwnPropertyDescriptor(_obj, '_open'), _obj), _applyDecoratedDescriptor(_obj, '_close', [_object.action], Object.getOwnPropertyDescriptor(_obj, '_close'), _obj), _applyDecoratedDescriptor(_obj, '_draw', [_object.action], Object.getOwnPropertyDescriptor(_obj, '_draw'), _obj), _applyDecoratedDescriptor(_obj, 'updateBoundValue', [_object.action], Object.getOwnPropertyDescriptor(_obj, 'updateBoundValue'), _obj)), _obj)));
});