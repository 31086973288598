define('ui-library/helpers/is-phoenix', ['exports', 'ember-get-config'], function (exports, _emberGetConfig) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isPhoenix = isPhoenix;
  function isPhoenix() {
    return _emberGetConfig.default.includeProject === 'phoenix';
  }

  exports.default = Ember.Helper.helper(isPhoenix);
});